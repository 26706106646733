<template>
  <div>
    <v-card>
      <v-row class="mt-2 mr-2">
        <v-spacer />
        <v-btn @click="closeDialog()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
      <v-card-title class="mt-5 flex justify-center">
        <div style="margin-right: 10px"> <v-img :width="40" :src="require('@/assets/wasteCodes.svg')" class="step-icon" />
        </div>
        <span class="headline font-weight-bold">
          Pasul 3 din 3. Configureaza codurile de deseuri pe care le generezi
        </span>
        <div>
          Am adaugat noi in contul tau cele mai uzuale tipuri de deseuri generate, le poti modifica oricand.
        </div>
      </v-card-title>
      <v-card-text>
        <!-- <AddButton v-if="canAddCode" :onClick="() => { addWasteCode() }" caption="Adauga cod de deseu" />
        <v-data-table class="elevation-0 " :headers="wasteCodeHeaders" :items="wasteCodesPerWorkingPoint" dense
          v-if="!userDisabled" hide-default-header>
          <template v-slot:header="{ props: { headers } }">
            <thead>
              <tr>
                <th v-for="h in headers" :class="h.class">
                  <div class="flex">
                    {{ h.text }}
                    <v-tooltip top v-if="h.tooltipText">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ml-2" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                      </template>
                      <span v-html="h.tooltipText">
                      </span>
                    </v-tooltip>
                  </div>
                </th>
              </tr>
            </thead>
          </template>
         
          <template v-slot:[`item.actions`]="props">
            <p class="tableData">
            <div class="flex" v-if="props.item.canBeDeleted">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="deleteWCPWP(props.item)" icon color="red" v-bind="attrs" v-on="on">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <span>Sterge</span>
              </v-tooltip>
            </div>
            </p>
          </template>
          <template v-slot:[`item.codValorificare.code`]="props">
            <p class="tableData">
            <div class="flex">
              <v-select clearable v-model="props.item.codValorificare"
                :items="listsByCategory(ListCategories.CODURI_VALORIFICARE)" item-text="code" item-value="_id"
                return-object @input="updateWasteCode(props.item)">
                <template slot="item" slot-scope="{ item }">
                  <p>
                    <b> {{ item.code }}</b> {{ `(${item.name})` }}
                  </p>
                </template>
              </v-select>
            </div>
            </p>
          </template>
          <template v-slot:[`item.wasteCode.code`]="props">
            <p v-if="props.item.wasteCode" style="width: 100px">
              {{ props.item.wasteCode.code }}
            </p>
            <p v-else class="tableData flex" style="width: 150px">
              <v-autocomplete class="ml-0 mr-0" v-model="props.item.wasteCode" label="Cauta coduri de deseu" hide-selected
                text-color="white" :items="getAvailableWasteCodes()" item-text="code" item-value="_id" item
                @change="addNewWasteCode(props.item.wasteCode)" :search-input.sync="searchWasteCodes" no-filter
                return-object>
                <template slot="item" slot-scope="{ item }">
                  <p>
                    <b> {{ item.code }}</b> {{ `(${item.name})` }}
                  </p>
                </template>
              </v-autocomplete>
            </p>
          </template>
          <template v-slot:[`item.codEliminare.code`]="props">
            <p class="tableData">
            <div class="flex">
              <v-select clearable v-model="props.item.codEliminare"
                :items="listsByCategory(ListCategories.CODURI_ELIMINARE)" item-text="code" item-value="_id" return-object
                @change="updateWasteCode(props.item)">
                <template slot="item" slot-scope="{ item, tile }">
                  <p>
                    <b> {{ item.code }}</b> {{ `(${item.name})` }}
                  </p>
                </template>
              </v-select>
            </div>
            </p>
          </template>
          <template v-slot:[`item.initialStock`]="props">
            <td class="tableData">
              <div class="flex">
                <div class="stockWidth">
                  <v-text-field type="number" v-model="props.item.initialStock" :rules="[
                    v => !v || Number(v) >= 0 || `Stocul trebuie sa fie pozitiv`,
                    v => !v || /^[0-9]\d{0,9}(?:.\d{1,2})?$/.test(v) || `Numarul trebuie sa aiba cel mult 2 zecimale`
                  ]" />
                </div>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="buttonAligned" @click="updateWasteCode(props.item)" icon color="accent" v-bind="attrs"
                      v-on="on">
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                  <span>Salveaza</span>
                </v-tooltip>
              </div>
            </td>
          </template>
          <template v-slot:[`item.unitMeasure.code`]="props">
            <p class="tableData">
            <div class="flex">
              <v-select v-model="props.item.unitMeasure" :items="listsByCategory(ListCategories.UNITATE_MASURA)"
                item-text="code" item-value="_id" return-object @change="updateWasteCode(props.item)">
                <template slot="item" slot-scope="{ item }">
                  <p>
                    <b> {{ item.code }}</b> {{ `(${item.name})` }}
                  </p>
                </template>
              </v-select>
            </div>
            </p>
          </template>
          <template v-slot:[`item.evalType`]="props">
            <p class="tableData">
            <div class="flex">
              <v-select v-model="props.item.evalType" :items="evalTypes" item-text="code" item-value="_id"
                @change="updateWasteCode(props.item)">
                <template slot="item" slot-scope="{ item }">
                  <p>
                    <b> {{ item.code }}</b> {{ `(${item.name})` }}
                  </p>
                </template>
              </v-select>
            </div>
            </p>
          </template>
          <template v-slot:[`item.stareFizica.code`]="props">
            <p class="tableData">
            <div class="flex">
              <v-select v-model="props.item.stareFizica" :items="listsByCategory(ListCategories.STARE_FIZICA)"
                item-text="code" item-value="_id" return-object @change="updateWasteCode(props.item)">
                <template slot="item" slot-scope="{ item }">
                  <p>
                    <b> {{ item.code }}</b>
                  </p>
                </template>
              </v-select>
            </div>
            </p>
          </template>
          <template v-slot:[`item.stockingType.code`]="props">
            <p class="tableData">
            <div class="flex">
              <v-select v-model="props.item.stockingType" :items="listsByCategory(ListCategories.TIP_STOCARE)"
                item-text="code" item-value="_id" return-object @change="updateWasteCode(props.item)">
                <template slot="item" slot-scope="{ item }">
                  <p>
                    <b> {{ item.code }}</b> {{ `(${item.name})` }}
                  </p>
                </template>
              </v-select>
            </div>
            </p>
          </template>
          <template v-slot:[`item.destination.code`]="props">
            <p class="tableData">
            <div class="flex">
              <v-select v-model="props.item.destination" :items="listsByCategory(ListCategories.DESTINATIE)"
                item-text="code" item-value="_id" return-object @change="updateWasteCode(props.item)">
                <template slot="item" slot-scope="{ item }">
                  <p>
                    <b> {{ item.code }}</b> {{ `(${item.name})` }}
                  </p>
                </template>
              </v-select>
            </div>
            </p>
          </template>
          <template v-slot:[`item.carType.code`]="props">
            <p class="tableData">
            <div class="flex">
              <v-select v-model="props.item.carType" :items="listsByCategory(ListCategories.MIJLOC_TRANSPORT)"
                item-text="code" item-value="_id" return-object @change="updateWasteCode(props.item)">
                <template slot="item" slot-scope="{ item }">
                  <p>
                    <b> {{ item.code }}</b> {{ `(${item.name})` }}
                  </p>
                </template>
              </v-select>
            </div>
            </p>
          </template>
          <template v-slot:[`item.partnerDestination.name`]="props">
            <p class="tableData">
            <div class="flex">
              <v-select v-model="props.item.partnerDestination"
                :items="partnersByWorkingPoint(props.item.partnerDestination)" item-text="name" item-value="_id"
                @change="updateWasteCode(props.item, true)">
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title class="noDataButton">
                      Nu ai niciun partener adaugat pentru acest punct de lucru.
                      <AddButton :onClick="() => { openPartnerDialog(props.item, 'DESTINATAR') }"
                        caption="Adauga partener" />
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:append-item v-if="partnersByWorkingPoint(props.item.partnerDestination).length">
                  <AddButton :onClick="() => { openPartnerDialog(props.item, 'DESTINATAR') }"
                    caption="Adauga partener nou" />
                </template>
              </v-select>
            </div>
            </p>
          </template>
          <template v-slot:[`item.partnerTransportator.name`]="props">
            <p class="tableData">
            <div class="flex">
              <v-select v-model="props.item.partnerTransportator"
                :items="partnersByWorkingPoint(props.item.partnerTransportator, true)" item-text="name" item-value="_id"
                @change="updateWasteCode(props.item, true)">
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title class="noDataButton">
                      Nu ai niciun partener transportator adaugat pentru acest punct de lucru.
                      <AddButton :onClick="() => { openPartnerDialog(props.item, 'TRANSPORTATOR') }"
                        caption="Adauga partener transportator" />
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:append-item v-if="partnersByWorkingPoint(props.item.partnerTransportator, true).length">
                  <AddButton :onClick="() => { openPartnerDialog(props.item, 'TRANSPORTATOR') }"
                    caption="Adauga partener transportator nou" />
                </template>
              </v-select>
            </div>
            </p>
          </template>
          <template v-slot:[`item.partnerCar.plate`]="props">
            <p class="tableData">
            <div class="flex">
              <v-select :disabled="!props.item.partnerTransportator" v-model="props.item.partnerCar"
                :items="partnerById(props.item.partnerTransportator) ? partnerById(props.item.partnerTransportator).partnerCars : []"
                item-text="plate" item-value="_id" return-object @change="updateWasteCode(props.item)">
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title class="noDataButton">
                      Nu ai nicio masina adaugata pentru acest partener.
                      <AddButton :onClick="() => { openPartnerDialogForUpdate(props.item) }" caption="Adauga masina" />
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:append-item
                  v-if="partnerById(props.item.partnerTransportator) && partnerById(props.item.partnerTransportator).partnerCars.length">
                  <AddButton :onClick="() => { openPartnerDialogForUpdate(props.item) }" caption="Adauga masina noua" />
                </template>
              </v-select>
            </div>
            </p>
          </template>
          <template v-slot:[`item.partnerDriver.name`]="props">
            <p class="tableData">
            <div class="flex">
              <v-select :disabled="!props.item.partnerTransportator" v-model="props.item.partnerDriver"
                :items="partnerById(props.item.partnerTransportator) ? partnerById(props.item.partnerTransportator).partnerDrivers : []"
                item-text="name" item-value="_id" return-object @change="updateWasteCode(props.item)">
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title class="noDataButton">
                      Nu ai niciun sofer adaugat pentru acest partener.
                      <AddButton :onClick="() => { openPartnerDialogForUpdate(props.item) }" caption="Adauga sofer" />
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:append-item
                  v-if="partnerById(props.item.partnerTransportator) && partnerById(props.item.partnerTransportator).partnerDrivers.length">
                  <AddButton :onClick="() => { openPartnerDialogForUpdate(props.item) }" caption="Adauga sofer nou" />
                </template>
              </v-select>
            </div>
            </p>
          </template>
          <template v-slot:[`item.isActive`]="props">
            <p class="tableData">
            <div class="flex">
              <v-checkbox class="" style="align-items: center;" :input-value="props.item.isActive"
                @click="updateWasteCodeActive(props.item)"></v-checkbox>
            </div>
            </p>
          </template>
        </v-data-table> -->
        <WasteCodesPerWorkingPointTable v-if="!userDisabled" :workingPoint="workingPoint" :hasAddButton="canAddCode ? true : false"
        :showEditMode="true" :showFilters="false" />
      </v-card-text>
      <v-card-actions class="cardActions" style="width: 95%">
        <v-spacer />
        <v-btn type="button" class="mainButtonYes" style="width: 200px" text @click="checkStocksAndClose()">Am
          terminat</v-btn>
      </v-card-actions>
    </v-card>
    <CreatePartner v-if="partnerDialog" :forField="fieldToAddPartner" :closeDialog="closePartnerDialog"
      :workingPoint="workingPoint" :wasteCodePWP="currentWasteCodePWP" :createCb="refreshData" 
      :partnerToUpdate="partnerToUpdate" :updateCarsAndDrivers="updateCarsAndDrivers"/>
    <!-- <CreateCar v-if="carDialog" :closeDialog="closeCarDialog" :partner="currentPartner"
      :carTypes="listsByCategory(ListCategories.MIJLOC_TRANSPORT)" :wasteCodePWP="currentWasteCodePWP"
      :createCb="refreshData" />
    <CreateDriver v-if="driverDialog" :closeDialog="closeDriverDialog" :partner="currentPartner"
      :wasteCodePWP="currentWasteCodePWP" :createCb="refreshData" /> -->
    <InitialStockDialog v-if="initialStockDialog" :onClose="closeInitialStockDialog" :onConfirm="confirmInitialStock" />
  </div>
</template>
<script>
import {
ListCategories
} from '../../../common/ListCategories';
import AddButton from '../../Buttons/AddButton.vue';
import SectionHeadline from '../../Typography/SectionHeadline.vue';
import SubHeader from '../../Typography/SubHeader.vue';
// import CreateCar from '../Create/CreateCar.vue';
// import CreateDriver from '../Create/CreateDriver.vue';
import CreatePartner from '../Create/CreatePartner.vue';
import InitialStockDialog from '../InitialStockDialog.vue';
import UpdateWorkingPoint from '../UpdateWorkingPoint.vue';
import WasteCodesPerWorkingPointTable from '../../Form/WasteCodesPerWorkingPointTable.vue';
export default {
  components: {
    SectionHeadline,
    SubHeader,
    CreatePartner,
    // CreateCar,
    // CreateDriver,
    AddButton,
    UpdateWorkingPoint,
    InitialStockDialog,
    WasteCodesPerWorkingPointTable
  },
  props: [
    "closeDialog",
    "workingPoint"
  ],
  data() {
    return {
      dialog: true,
      initialStockDialog: false,
      wcToUpdate: [],
      deleteDialog: false,
      createDialog: false,
      partnerDialog: false,
      updateCarsAndDrivers: false,
      // carDialog: false,
      // driverDialog: false,
      // currentPartner: null,
      editDialog: false,
      currentWorkingPoint: null,
      search: '',
      options: {},
      selectedParent: [],
      selectedChild: [],
      expanded: [],
      // searchWasteCodes: '',
      currentWasteCodePWP: null,
      fieldToAddPartner: null,
      updateDialog: false,
      partnerToUpdate: null,
      wasteCodeHeaders: [{
        text: 'Actiuni',
        value: 'actions'
      }, {
        text: 'Cod deseu',
        value: 'wasteCode.code'
      },
      {
        text: 'Nume deseu',
        value: 'wasteCode.name'
      },
      {
        text: 'Cod valorificare',
        value: 'codValorificare.code',
        tooltipText: 'Daca deseurile merg spre valorificare, selecteaza codul de valorificare ce se regaseste pe documentul de transport (Anexa 3)'
      },
      {
        text: 'Cod eliminare',
        value: 'codEliminare.code',
        tooltipText: 'Daca deseurile merg spre eliminare, selecteaza codul de valorificare ce se regaseste pe documentul de transport (Anexa 3)'
      },
      {
        text: 'Stoc initial',
        value: 'initialStock',
        tooltipText: 'Completeaza cantitatea de deseuri ce exista in stoc la inceputul introducerii datelor'
      },
      {
        text: 'Unitate de masura',
        value: 'unitMeasure.code',
        tooltipText: 'Alege unitatea de masura in care partenerul iti cantareste/evalueaza deseurile'
      },
      {
        text: 'Evaluare cantitate',
        value: 'evalType',
        tooltipText: 'Alege daca cantitatea este cantarita (masurata) sau estimata'
      },
      {
        text: 'Stare fizica',
        value: 'stareFizica.code',
        tooltipText: '<div>Alege starea fizica a deseurilor in functie de cum se gasesc ele cand le generezi.</div><div>Exemplu, <i>deseuri de ambalaje de plastic</i> - starea este solida, <i>uleiuri</i> - este lichida, etc.</div>'
      },
      {
        text: 'Tip stocare',
        value: 'stockingType.code',
        tooltipText: 'Alege recipientul in care stochezi deseurile'
      },
      {
        text: 'Destinatie',
        value: 'destination.code',
        tooltipText: '<div>Alege destinatia deseurilor ca proces, in ce scop sunt predate partenerului.</div><div>De exemplu <i>Vr - Valorificare prin agenti economici autorizati</i> inseamna <i>Reciclare</i></div>'
      },
      {
        text: 'Tip transport',
        value: 'carType.code',
        tooltipText: '<div>Alege tipul transportului cu care deseurile sunt transportate catre partener.</div><div>In general Deseurile menajere si cele periculoase sunt transportate de firma de</div><div>salubritate cu <b>Autospeciale</b>, iar deseurile reciclabile sunt transportate cu </div><div><b>Autonespeciale</b><div>'
      },
      {
        text: 'Destinatar',
        value: 'partnerDestination.name',
        tooltipText: 'Alege pertenerul la care ajung deseurile, cel cu care ai contract pentru a iti prelua deseurile.'
      },
      {
        text: 'Transportator',
        value: 'partnerTransportator.name',
        tooltipText: 'Alege pertenerul care preia deseurile de la tine pentru a le transporta catre colector, reciclator sau eliminator.'
      },
      ],
      evalTypes: [
        {
          _id: 'A', code: 'A', name: 'Cantarita'
        },
        {
          _id: 'B', code: 'B', name: 'Estimata'
        }
      ]
    };
  },
  watch: {
    // searchWasteCodes(newVal) {
    //   this.timeout = setTimeout(() => {
    //     this.findWasteCodes()
    //   }, 500);
    // },
  },
  computed: {
    workingPointFormOpen() {
      return this.$route.params && this.$route.params['formOpened'] == 'addWorkingPoint'
    },
    ListCategories() {
      return ListCategories
    },
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
    user() {
      return this.$store.state.auth.user
    },
    userDisabled() {
      return this.$store.state.auth.userDisabled
    },
    userFree() {
      return this.$store.state.auth.userFree
    },
    userAdvanced() {
      return this.$store.state.auth.userAdvanced
    },
    canAddCode() {
      if (this.userAdvanced) {
        return true
      }
      if (this.userFree) {
        // return !this.wasteCodesPerWorkingPoint.length
        //TRIAL HAS ALL FEATURES ENABLED
        return true
      }
      return false
    },
    companies() {
      return this.workingPointsData
    },
    workingPointsData() {
      return this.$store.state.workingPoints.workingPointsData
    },
    lists() {
      return this.$store.state.wasteCodes.lists
    },
    wasteCodes() {
      return this.$store.state.wasteCodes.wasteCodes
    },
    partners() {
      return this.$store.state.partners.partnersForWorkingPoint
    },
    wasteCodesPerWorkingPoint() {
      return this.$store.state.wasteCodesPerWorkingPoint.wasteCodesPerWorkingPoint
    },
    initialStocks() {
      return this.$store.state.wasteCodesPerWorkingPoint.initialStocks
    },
  },
  methods: {
    checkStocksAndClose() {
      let wcToUpdate = []
      let stockErrors = false
      this.wasteCodesPerWorkingPoint.map(wcPWP => {
        if (!wcPWP._id) {
          return
        }
        let initialStock = this.initialStocks[wcPWP._id]
        if (!(Number(wcPWP.initialStock) >= 0 || !wcPWP.initialStock)) {
          stockErrors = true
        }
        if (initialStock != wcPWP.initialStock) {
          wcToUpdate.push(wcPWP)
        }
      })
      if (stockErrors) {
        return
      }
      if (!wcToUpdate.length) {
        this.close()
        return
      }
      this.initialStockDialog = true
      this.wcToUpdate = wcToUpdate
    },
    closeInitialStockDialog() {
      this.initialStockDialog = false
      this.close()
    },
    confirmInitialStock() {
      this.wcToUpdate.map(wc => {
        this.updateWasteCode(wc)
      })
      this.wcToUpdate = []
      this.closeInitialStockDialog()
    },
    partnerById(partner) {
      if (!partner) {
        return
      }
      if (partner._id) {
        partner = partner._id
      }
      return this.partners.find(p => {
        return p._id == partner
      })
    },
    // updateWorkingPointActive(workingPoint) {
    //   this.$store.dispatch("updateWorkingPointActive", {
    //     workingPointId: workingPoint._id,
    //   })
    //   workingPoint.isActive = !workingPoint.isActive
    // },
    // addNewWasteCode(wasteCode) {
    //   this.wasteCodesPerWorkingPoint = this.wasteCodesPerWorkingPoint.filter(wc => wc.wasteCode && wc.wasteCode.code != wasteCode.code)
    //   this.$store.dispatch("createWasteCodePerWorkingPoint", {
    //     workingPointId: this.workingPoint._id,
    //     wasteCode: wasteCode,
    //     cb: () => {
        
    //     }
    //   })
    // },
    // updateWasteCodeActive(wasteCodePWP) {
    //   this.$store.dispatch("updateWasteCodeActive", {
    //     id: wasteCodePWP._id,
    //   })
    //   wasteCodePWP.isActive = !wasteCodePWP.isActive
    // },
    // findWasteCodes() {
    //   this.$store.dispatch("fetchWasteCodes", {
    //     search: this.searchWasteCodes
    //   })
    // },
    // getAvailableWasteCodes() {
    //   let takenWasteCodes = this.wasteCodesPerWorkingPoint.map(w => w.wasteCode ? w.wasteCode._id : null).filter(a => a)
    //   return this.wasteCodes.filter(wc => !takenWasteCodes.includes(wc._id))
    // },
    // addWasteCode() {
    //   let alreadyAddedWasteCode = this.wasteCodesPerWorkingPoint.find(w => !w.wasteCode)
    //   if (!alreadyAddedWasteCode) {
    //     this.$store.dispatch("addEmptyWasteCode")
    //     // this.wasteCodesPerWorkingPoint.unshift({})
    //   }
    // },
    updateWasteCode(item, updatingPartner) {
      if (updatingPartner) {
        item.partnerCar = null
        item.partnerDriver = null
      }
      this.$store.dispatch("updateWasteCodePerWorkingPoint", {
        id: item._id,
        model: item,
        cb: () => {
          // this.$store.dispatch('fetchWorkingPointsData', {});
        }
      })
    },
    listsByCategory(category) {
      return this.lists[category]
    },
    partnersByWorkingPoint(addedPartner, onlyTransportator) {
      let partners = []
      if (!addedPartner) {
        partners = this.partners.filter(p => p.isActive)
      } else {
        partners = this.partners.filter(p => (p.isActive || p._id == addedPartner._id))
      }
      if (onlyTransportator) {
        partners = partners.filter(p => {
          return p.types.map(t => t.code).includes('Transportator')
        })
      } else {
        // sa aiba cel putin un tip care sa nu fie transp
        partners = partners.filter(p => {
          return p.types.map(t => t.code).find(c => c != 'Transportator')
        })
      }
      return partners
    },
  
    openPartnerDialog(wasteCodePWP, fieldToAddPartner) {
      // this.currentWorkingPoint = workingPoint
      this.currentWasteCodePWP = wasteCodePWP
      this.partnerDialog = true
      this.fieldToAddPartner = fieldToAddPartner
    },
    closePartnerDialog() {
      this.partnerDialog = false
      this.fieldToAddPartner = null
      this.partnerToUpdate = null
      this.updateCarsAndDrivers = false
    },
    openPartnerDialogForUpdate(wasteCodePWP) {
      this.currentWasteCodePWP = wasteCodePWP
      this.partnerToUpdate = this.partnerById(wasteCodePWP.partnerTransportator)
      this.fieldToAddPartner = 'TRANSPORTATOR'
      this.updateCarsAndDrivers = true
      this.partnerDialog = true
    },
    // openCarDialog(wasteCodePWP) {
    //   this.currentPartner = wasteCodePWP.partnerTransportator
    //   this.currentWasteCodePWP = wasteCodePWP
    //   this.carDialog = true
    // },
    // closeCarDialog() {
    //   this.carDialog = false
    // },
    // openDriverDialog(wasteCodePWP) {
    //   this.currentPartner = wasteCodePWP.partnerTransportator
    //   this.currentWasteCodePWP = wasteCodePWP
    //   this.driverDialog = true
    // },
    // closeDriverDialog() {
    //   this.driverDialog = false
    // },
    closeCreateDialog() {
      this.createDialog = false
      if (this.workingPointFormOpen) {
        this.$router.push('/')
      }
    },
    close() {
      // this.closeDialog()
      this.$store.dispatch("enterOutro")
    },
    refreshData() {
      this.$store.dispatch('fetchPartners', {
        workingPointId: this.workingPoint._id
      });
      this.$store.dispatch("fetchWasteCodesPerWorkingPoint", {
        workingPointId: this.workingPoint._id,
      })
    },
    // deleteWCPWP(wcPWP) {
    //   this.$store.dispatch('deleteWasteCodePerWorkingPoint', wcPWP._id);
    // },
  },
  created() {
    this.$store.dispatch('fetchLists', {})
    this.refreshData()
  }
}
</script>
<style scoped>
::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>tbody>tr>th,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>th,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  padding: 0 8px;
  font-size: 0.8rem
}
::v-deep .v-select__selection {
  font-size: 0.8rem;
}
/* table.v-table thead tr th {
  font-size: 24px;
}
table.v-table tbody tr td {
  font-size: 10px;
} */
@media only screen and (max-width: 600px) {
  .stockWidth {
    width: 100% !important
  }
  .noDataButton {
    display: grid;
    font-size: 12px;
  }
}
.stockWidth {
  width: 50px
}
.buttonAligned {
  margin-top: 20px;
}
/* .cardActions {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  justify-content: center
} */</style>
