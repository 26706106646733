<template>
<auth-layout>
  <WorkingPointsLayout />
</auth-layout>
</template>
<script>
import AuthLayout from '../Layouts/AuthLayout.vue';
import WorkingPointsLayout from '../Layouts/WorkingPointsLayout.vue';
export default {
  data() {
    return {};
  },
  components: {
    AuthLayout,
    WorkingPointsLayout
  },
  computed: {

  },
  watch: {
  },
  methods: {
  },
  created() {
  }
};
</script>
