<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Plati {{ user ? user.email : '' }} - allowed: {{ user.allowedWorkingPoints }}</span>
        </v-card-title>
        <v-card-text v-if="userData">
          <!-- {{ userData }} -->
          <v-row v-for="(workingPoint, index) in userData.workingPoints" :key="index">
            {{ workingPoint.name }} - {{ workingPoint.createdAt }}
          </v-row>
          <v-row v-for="(payment, index) in userData.payments" :key="index" class="ma-1 flex">
            <v-select :items="userData.workingPoints" item-text="name" item-value="_id" class="ma-1"
              v-model="payment.workingPoint" />
            <div class="flex" v-if="payment._id">
              <div class="ma-1">{{ parseDate(payment.startDate) }}</div>
              <div class="ma-1">{{ parseDate(payment.endDate) }}</div>
            </div>
            <div class="flex" v-else>
              <v-text-field v-model="payment.startDate" class="mr-5 ma-1" />
              <v-text-field v-model="payment.endDate" class="mr-5 ma-1" />
            </div>
            <v-text-field class="ma-1" style="width: 15%" v-model="payment.productId" />
            <v-text-field class="ma-1" style="width: 15%" type="number" v-model="payment.itemQuantity" />
            <v-btn class="mt-3" @click="addPayment(payment)">duplicate</v-btn>
            <v-btn class="mt-3" @click="removePayment(payment)">remove</v-btn>
          </v-row>
          <v-row v-for="(paymentMade, index) in userData.paymentsMade" :key="index">
            {{ paymentMade.item_quantity }},
            {{ paymentMade.product_id }},
            {{ paymentMade.charge_date || 'NO CH DATE' }},
            {{ paymentMade.charge.current_period_start || "NO CPS" }}
            _______
          </v-row>
        </v-card-text>
        <v-card-actions class="cardActions">
          <v-btn type="button" class="mainButtonYes" text @click="save()">Adauga</v-btn>
          <!-- <v-divider style="border-block: white; max-width: 40px"/> -->
          <v-btn type="button" class="mainButtonNo" color="red" @click="close()">Renunta</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import moment from "moment-timezone";
export default {
  props: [
    "closeDialog",
    "user",
  ],
  data: () => ({
    dialog: true,
    inputRules: [v => !!v || "Campul este obligatoriu"],
  }),
  watch: {
    user() {
      this.$store.dispatch('fetchUserPayments', this.user._id)
    },
  },
  methods: {
    removePayment(payment) {
      this.userData.payments = this.userData.payments.filter(p => p != payment)
    },
    addPayment(payment) {
      if (!this.userData) {
        return
      }
      this.userData.payments.unshift({
        startDate: payment.startDate,
        endDate: payment.endDate,
        productId: payment.productId,
        paidByOP: payment.paidByOP,
        itemQuantity: 1,
        workingPoint: null,
        user: payment.user
      })
    },
    close() {
      this.closeDialog()
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    save() {
      this.$store.dispatch('savePayments', { userId: this.user._id, payments: this.userData.payments })
    },
  },
  computed: {
    userData() {
      return this.$store.state.admin.userData
    },
  },
  created() {
    if (this.user) {
      this.$store.dispatch('fetchUserPayments', this.user._id)
    }
  }
};
</script>
