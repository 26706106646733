<template>
  <v-btn @click="onClick()" :class="btnClass ? btnClass : `secondary`">
    <v-icon left dark>mdi-plus</v-icon>
    <span>{{ caption }}</span>
  </v-btn>
</template>
<script>
export default {
  props: ['btnClass', 'onClick', 'caption']
};
</script>
