import toastr from '../../toastr';
import axiosInstance from '../axiosWrapper';
import { getUserMaxRole } from './helpers';

export default {
  //===========================
  // STATEI
  //===========================
  state: {
    user: {},
    loadingUser: true,
    isAuthenticated: false,
    access_token: '',
    error: {},
    userImp: null,
    impersonated: false,
    isAdmin: false,
    userStatus: 'DISABLED',
    userFree: false,
    userAdvanced: false,
    userDisabled: false,
    mailFromToken: null,
    canPerformBasicCRUD: false,
    canPerformOrganizationChanges: false,
    maxRolePerWorkingPoint: {},
    canSeeUsers: true,
    isManager: false

  },
  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_IS_ADMIN(state) {
      if (state.user.role == 'ADMIN' && !state.userImp) {
        state.isAdmin = true
      } else {
        state.isAdmin = false
      }

      if (state.user && state.user.role == 'ADMIN' && state.userImp) {
        if (state.userImp.superuser) {

        }
        state.userStatus = state.userImp.paymentStatus
      } else if (state.user) {
        state.userStatus = state.user.paymentStatus
      } else {
        state.userStatus = 'DISABLED'
      }

      state.userFree = state.userStatus == 'FREE'
      state.userAdvanced = state.userStatus == 'ADVANCED'
      state.userDisabled = state.userStatus == 'DISABLED'
    },

    SET_USER(state, user) {
      state.user = user;
      state.isAuthenticated = true;
      state.loadingUser = false;
      state.error = {};

      // let role = getUserMaxRole(user)
      let userNotGuest = user.role != 'GUEST'

      if (userNotGuest) {
        state.canPerformOrganizationChanges = true
        state.canPerformBasicCRUD = true
        state.isManager = true

      } else {
        const maxRole = getUserMaxRole(user)
        // state.maxRolePerWorkingPoint = getMaxRolePerWorkingPoint(user)
        if (maxRole == 'VIEWER') {
          state.canPerformBasicCRUD = false
        } else {
          state.canPerformBasicCRUD = true
        }

        if(maxRole != 'MANAGER') {
          state.canSeeUsers = false
        } else {
          state.isManager = true
        }
      }

    },
    SET_USER_IMP(state, userImp) {
      state.userImp = userImp
      state.impersonated = true
    },
    SET_MAIL_FROM_TOKEN(state, mail) {
      state.mailFromToken = mail
      // state.impersonated = true
    },
    UNSET_USER_IMP(state) {
      state.userImp = null
      state.impersonated = false
      localStorage.removeItem('userImpId');
    },
    SET_ACCESS_TOKEN(state, token = false) {
      if (token) {
        window.localStorage.setItem('access_token', token);
        return;
      }
      window.localStorage.removeItem('access_token');
      state.access_token = token;
    },
    SET_USER_ERROR_OR_LOGOUT(state, error) {
      state.error = error;
      state.user = {};
      state.userImp = null
      state.isAuthenticated = false;
      state.loadingUser = false;
      state.access_token = '';

    },
  },
  //===========================
  // ACTIONS
  //===========================
  actions: {
    async updateUser({ commit }, formData) {
      let model = formData.model
      let cb = formData.cb
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/auth`,
          model,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          toastr.success("Informatiile au fost actualizate!")
          cb()
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },
    async fetchUser({ commit }) {
      try {
        const res = await axiosInstance.get(
          `${process.env.VUE_APP_API_URL}/auth/user`,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {

          commit('SET_USER', res.data);
          commit('SET_IS_ADMIN')
          commit(
            'SET_ACCESS_TOKEN',
            window.localStorage.getItem('access_token'),
          );
          return true;
        }
      } catch (error) {
        if (error?.response?.data) {
          commit('SET_USER_ERROR_OR_LOGOUT', { ...error.response.data, area: 'fetchUser' });
          return false;
        }
        commit('SET_USER_ERROR_OR_LOGOUT', {
          error: 'Server error',
          message: 'Sorry, something went wrong',
          statusCode: 500,
          area: 'fetchUser',
        })
        commit('SET_IS_ADMIN')
      }
    },
    async fetchUserById({ commit }, id) {
      if (!id || !id.length) {
        commit('UNSET_USER_IMP');
        commit('SET_IS_ADMIN')
        return
      }
      try {
        const res = await axiosInstance.get(
          `${process.env.VUE_APP_API_URL}/auth/userById/${id}`,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_USER_IMP', res.data);
          commit('SET_IS_ADMIN')
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
    async register({ commit }, formData) {
      try {
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/auth/register`,
          // {
          //   name: formData.name,
          //   email: formData.email,
          //   password: formData.password,
          // },
          formData,
          {
            withCredentials: true,
          },
        );
        if (res.status === 201) {
          window.location = `${process.env.VUE_APP_FRONTEND_URL}/`;
        }
      } catch (error) {
        if (error.response.data) {
          commit('SET_USER_ERROR_OR_LOGOUT', {
            ...error.response.data,
            area: 'register',
          });
          commit('SET_IS_ADMIN')
          return this.error;
        }
        commit('SET_USER_ERROR_OR_LOGOUT', {
          error: 'Server error',
          message: 'Sorry, something went wrong',
          statusCode: 500,
          area: 'register',
        });
        commit('SET_IS_ADMIN')
        return this.error;
      }
    },
    async login({ commit }, { email, password }) {
      try {
        commit('UNSET_USER_IMP')
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/auth/login`,
          { email, password },
          {
            withCredentials: true,
          },
        );
        if (res.status === 201) {
          window.location = `${process.env.VUE_APP_FRONTEND_URL}/`;
        }
      } catch (error) {
        console.log(error.response)
        if (error.response && error.response.data) {
          commit('SET_USER_ERROR_OR_LOGOUT', {
            ...error.response.data,
            area: 'login',
          });
          commit('SET_IS_ADMIN')
          return this.error;
        }
        commit('SET_USER_ERROR_OR_LOGOUT', {
          error: 'Server error',
          message: 'Datele de autentificare nu sunt corecte.',
          statusCode: 500,
          area: 'login',
        });
        commit('SET_IS_ADMIN')
        return this.error;
      }
    },
    async logout({ commit }) {
      const res = await axiosInstance.get(`${process.env.VUE_APP_API_URL}/auth/logout`, {
        withCredentials: true,
      });
      commit('SET_USER_ERROR_OR_LOGOUT');
      commit('SET_IS_ADMIN')
    },
    async changePassword({ commit }, formData) {
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/auth/changePassword`,
          formData,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          toastr.success("Parola a fost schimbata cu succes!")
        }
      } catch (error) {
        toastr.error("Parola introdusa nu este corecta!")
        console.log(error)
        return this.error;
      }
    },
    async sendResetPasswordEmail({ commit }, formData) {
      try {
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/auth/password/forgotlink`,
          formData,
          {
            withCredentials: true,
          },
        );
        if (res.status === 201) {
          toastr.success("A fost trimis un e-mail cu link-ul de resetare parola")
        }
      } catch (error) {
        toastr.error("Email-ul nu a fost gasit")
        console.log(error)
        return this.error;
      }
    },
    async resetPassword({ commit }, formData) {
      try {
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/auth/password/reset`,
          formData,
          {
            withCredentials: true,
          },
        );
        if (res.status === 201) {
          toastr.success("Parola ta a fost schimbata cu succes!")
          window.location = `${process.env.VUE_APP_FRONTEND_URL}/login`;
        }
      } catch (error) {
        toastr.error("A aparut o problema. Te rugam sa incerci din nou.")
        console.log(error)
        return this.error;
      }
    },
    async acceptInvite({ commit }, formData) {
      try {
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/auth/user/create`,
          formData,
          {
            withCredentials: true,
          },
        );
        if (res.status === 201) {
          toastr.success("Contul tau a fost creat cu succes!")
          window.location = `${process.env.VUE_APP_FRONTEND_URL}/login`;
        }
      } catch (error) {
        toastr.error("A aparut o problema. Te rugam sa incerci din nou.")
        console.log(error)
        return this.error;
      }
    },

    async getMailFromToken({ commit }, token) {
      try {
        const res = await axiosInstance.get(
          `${process.env.VUE_APP_API_URL}/auth/email/get/token/${token}`,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_MAIL_FROM_TOKEN', res.data);
        }
      } catch (error) {
        toastr.error("A aparut o problema. Te rugam sa incerci din nou.")
        console.log(error)
        return this.error;
      }
    },
  },
};
