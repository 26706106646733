<template>
  <!-- Desktop Menu -->
  <nav class="bg-primary">
    <div class="max-w-8xl mx-auto px-2 sm:px-6 lg:px-8 hidden lg:block ">
      <div class="relative flex items-center justify-between h-16">
        <burger-button @click="toggleMobileMenu"></burger-button>
        <div
          class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start"
        >
          <div class="flex-shrink-0 flex items-center">
            <img
              class=" h-8 w-auto"
              src="../../assets/rd-Artboard 2xxxhdpi.png"
              alt="Registrul Deseurilor"
            />
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex space-x-4">
              <!-- Navbar items -->
              <navbar-item
                v-for="(item, i) in links"
                :key="i"
                :label="item.label"
                :destination="item.destination"
                :external="item.external"
                :active="item.active"
              ></navbar-item>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile Menu -->
  <div :class="`overflow-hidden ${!showMobileMenu ? 'h-0' : ''}`">
    <div class="px-2 pt-2 pb-3 space-y-1">
      <!-- Navbar items -->
      <mobile-navbar-item v-for="(item, i) in links" :key="i" :label="item.label" :destination="item.destination" :external="item.external" :active="item.active"></mobile-navbar-item>
    </div>
  </div>
</nav>
</template>
<script>
import MobileNavbarItem from './Navbar/MobileNavbarItem.vue';
import NavbarItem from './Navbar/NavbarItem.vue';
import BurgerButton from './../Buttons/BurgerButton.vue';
export default {
  // ======================
  // DATA
  // ======================
  data() {
    return {
      links: [{
          label: 'Creeaza cont',
          destination: 'https://registrul-deseurilor.ro/#buy',
          external: true
        },
        // {
        //   label: 'Community',
        //   destination: '/community',
        //   active: this.$route.name === 'Community',
        // },
      ],
      showMobileMenu: false,
    };
  },
  // ======================
  // Components
  // ======================
  components: {
    NavbarItem,
    MobileNavbarItem,
    BurgerButton,
  },
  // ======================
  // Methods
  // ======================
  methods: {
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
  },
};
</script>
