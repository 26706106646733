<template>
  <auth-layout>
    <UsersLayout />
  </auth-layout>
  </template>
  <script>
  import AuthLayout from '../Layouts/AuthLayout.vue';
import UsersLayout from '../Layouts/UsersLayout.vue';
  export default {
    data() {
      return {};
    },
    components: {
      AuthLayout,
      UsersLayout
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    created() {
    }
  };
  </script>
  