var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin":"10px"}},[_c('LayoutHeader',{attrs:{"title":"Evidenta deseuri","dontDistrurbFree":true}}),_c('div',{staticClass:"layoutBody"},[_c('v-select',{attrs:{"items":_vm.workingPoints,"item-text":"displayName","label":"Alege punctul de lucru","item-value":"_id","return-object":""},on:{"input":function($event){return _vm.changeContext()}},model:{value:(_vm.currentWorkingPoint),callback:function ($$v) {_vm.currentWorkingPoint=$$v},expression:"currentWorkingPoint"}}),(_vm.currentWorkingPoint && !_vm.userDisabled && _vm.canPerformBasicCRUD && _vm.canAddRecord(_vm.currentWorkingPoint))?_c('v-layout',[(_vm.activeWasteCodesPerWorkingPoint.length < 8)?_c('div',{staticClass:"grid"},[_c('p',[_vm._v(" Adauga evidenta pentru ")]),_c('v-row',{staticClass:"buttonRow alignedRow"},_vm._l((_vm.activeWasteCodesPerWorkingPoint),function(wcPWP,idx){return _c('v-tooltip',{key:idx,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-gray-500 primary marginedButton",on:{"click":function($event){return _vm.openWasteRecordDialog(wcPWP)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-plus")]),_c('span',[_vm._v(_vm._s(wcPWP.alias || wcPWP.wasteCode.code))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(wcPWP.wasteCode.name))])])}),1)],1):_c('v-select',{attrs:{"label":"Adauga evidenta pentru","items":_vm.activeWasteCodesPerWorkingPoint,"item-text":"displayName","return-object":""},on:{"input":function($event){return _vm.openWasteRecordDialog(_vm.wcToAdd)}},model:{value:(_vm.wcToAdd),callback:function ($$v) {_vm.wcToAdd=$$v},expression:"wcToAdd"}})],1):_vm._e(),(_vm.currentWorkingPoint)?_c('v-layout',{staticClass:"text-left"},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.wasteRecords)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.wasteCodeHeaders,"items":_vm.wasteRecords,"options":_vm.options,"footer-props":{
            itemsPerPageOptions: [25, 50, 100],
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"buttonRow",attrs:{"align":"baseline"}},[_c('div',{staticClass:"d-flex flex-wrap w_auto w_md_90pr"},[_c('v-combobox',{staticClass:"left mr-0 mr-md-2 w_full w_md_auto",attrs:{"clearable":"","label":"Filtreaza dupa cod deseu","density":"compact","items":_vm.activeWasteCodesPerWorkingPoint,"item-text":"displayName","underlined":"","item-value":"wasteCode.code","color":"primary"},model:{value:(_vm.wasteCodeFilter),callback:function ($$v) {_vm.wasteCodeFilter=$$v},expression:"wasteCodeFilter"}}),_c('v-combobox',{staticClass:"left mr-0 mr-md-2 w_full w_md_auto",attrs:{"clearable":"","label":"Filtreaza tip operatiune","density":"compact","items":['ELIMINARE', 'VALORIFICARE'],"underlined":"","color":"primary"},model:{value:(_vm.discardTypeFilter),callback:function ($$v) {_vm.discardTypeFilter=$$v},expression:"discardTypeFilter"}})],1),_c('div',{staticClass:"d-flex w_full w_md_10pr justify-start justify-md-center"},[_c('v-btn',{staticClass:"text-gray-500",attrs:{"color":"secondary","disabled":_vm.loadingExport},on:{"click":function($event){return _vm.exportRecords()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-cloud-download-outline ")]),_c('span',[_vm._v("Exporta")])],1),(_vm.loadingExport)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)]),_c('v-row',{staticClass:"buttonRow"},[_c('v-menu',{attrs:{"close-on-content-click":false,"return-value":_vm.pickerDates,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.pickerDates=$event},"update:return-value":function($event){_vm.pickerDates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"text-gray-500  marginedButton",attrs:{"color":"secondary"},on:{"click":function($event){_vm.dateRangeFilter.show = true}}},[(!_vm.dateRangeFilter.text)?_c('span',{staticStyle:{"min-width":"230px"}},[_vm._v(" Selecteaza perioada ")]):_c('span',{staticStyle:{"min-width":"230px"}},[_vm._v(_vm._s(_vm.dateRangeFilter.text))])])]}}],null,false,2996127263),model:{value:(_vm.dateRangeFilter.show),callback:function ($$v) {_vm.$set(_vm.dateRangeFilter, "show", $$v)},expression:"dateRangeFilter.show"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.pickerDates),callback:function ($$v) {_vm.pickerDates=$$v},expression:"pickerDates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.dateRangeFilter.show = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.savePickedDates()}}},[_vm._v(" OK ")])],1)],1),_vm._l((_vm.dateFilters),function(filter){return _c('v-btn',{key:filter.text,staticClass:"text-gray-500  marginedButton",attrs:{"color":"secondary","disabled":_vm.filterIsActive('registerDate', filter.value)},on:{"click":function($event){_vm.dateFilter = filter.value}}},[_vm._v(" "+_vm._s(filter.text)+" ")])})],2)]},proxy:true},{key:"item.registerDate",fn:function(props){return [_vm._v(" "+_vm._s(_vm.parseDate(props.item.registerDate))+" ")]}},{key:"item.operationCode",fn:function(props){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(props.item.codValorificare ? props.item.codValorificare.code : (props.item.codEliminare ? props.item.codEliminare.code : '-'))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(props.item.codValorificare ? props.item.codValorificare.code + ' (' + props.item.codValorificare.name + ')' : (props.item.codEliminare ? props.item.codEliminare.code + ' (' + props.item.codEliminare.name + ')' : '-'))+" ")])])]}},{key:"item.destination",fn:function(props){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(props.item.destination.code)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(props.item.destination.code + ' (' + props.item.destination.name + ')'))])])]}},{key:"item.comments",fn:function(props){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"twoRowSpan"},'span',attrs,false),on),[_vm._v(" "+_vm._s(props.item.comments)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(props.item.comments)+" ")])])]}},(_vm.canPerformBasicCRUD && _vm.canAddRecord(_vm.currentWorkingPoint))?{key:"item.actions",fn:function(props){return [_c('p',{staticClass:"tableData"}),_c('div',{staticClass:"flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.userDisabled},on:{"click":function($event){return _vm.openUpdateDialog(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pen")])],1)]}}],null,true)},[_c('span',[_vm._v("Rectifica")])]),(!props.item.annexUrl)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.userDisabled},on:{"click":function($event){return _vm.openAnnexDialog(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-upload")])],1)]}}],null,true)},[_c('span',[_vm._v("Ataseaza anexa 3")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.userDisabled},on:{"click":function($event){return _vm.downloadAnnex(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Descarca anexa 3")])]),(_vm.notFinalized(props.item))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.userDisabled},on:{"click":function($event){return _vm.finalizeWasteRecord(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check-bold")])],1)]}}],null,true)},[_c('span',[_vm._v("Finalizeaza")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.userDisabled},on:{"click":function($event){return _vm.openDeleteDialog(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v("Sterge")])])],1),_c('p')]}}:null],null,true)}):_vm._e()],1)],1):_c('v-row',[_c('sub-header',[_vm._v("Selecteaza un punct de lucru!")])],1)],1),_c('v-text-field',{staticStyle:{"display":"none"},attrs:{"hidden":"","id":"uploadFile","type":"file","accept":"application/pdf, image/png, image/jpeg","hide-details":""}}),(_vm.deleteDialog)?_c('DeleteDialog',{attrs:{"closeDialog":_vm.closeDeleteDialog,"deleteAction":_vm.deleteWasteRecord,"itemName":"evidenta","item":_vm.currentWasteRecord}}):_vm._e(),(_vm.wasteRecordDialog)?_c('CreateWasteRecord',{attrs:{"closeDialog":_vm.closeWasteRecordDialog,"carTypes":_vm.listsByCategory(_vm.ListCategories.MIJLOC_TRANSPORT),"wasteCodePWP":_vm.currentWasteCodePWP,"workingPoint":_vm.currentWorkingPoint,"partners":_vm.partnersByWorkingPoint(_vm.currentWorkingPoint),"updating":_vm.updating}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }