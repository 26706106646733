<template>
  <div style="margin: 10px">
    <section-headline>
      Coduri de deseu
    </section-headline>
    <v-data-table :items="wasteCodes" :headers="wasteCodeHeaders" :search="search" :options.sync="options"
      :server-items-length="wasteCodesTotal" :footer-props="{
      itemsPerPageOptions: [25, 50, 100],
    }">
      <template v-slot:top>
        <v-row>
          <v-text-field v-model="search" label="Search"></v-text-field>
          <v-divider />
          <v-btn @click="filterWasteCodes({ isDefault: true })">
            Adaugate automat
          </v-btn>
          <v-btn @click="filterWasteCodes({ isDangerous: true })">
            Periculoase
          </v-btn>
          <v-btn @click="filterWasteCodes({ isDangerous: false })">
            Nepericuloase
          </v-btn>
          <v-btn @click="filterWasteCodes(null)">
            Toate
          </v-btn>
        </v-row>
      </template>
      <template v-slot:[`item.actions`]="props">
        <p class="tableData">
        <div class="flex">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="openWasteCodeDialog(props.item)" icon color="turquoise" v-bind="attrs" v-on="on">
                <v-icon>mdi-pen</v-icon>
              </v-btn>
            </template>
            <span>Adauga campuri default</span>
          </v-tooltip>
        </div>
        </p>
      </template>
      <template v-slot:[`item.isDangerous`]="props">
        <v-icon v-if="props.item.isDangerous" color="red">
          mdi-skull-outline
        </v-icon>
        <v-icon v-else color="green">
          mdi-check-decagram-outline
        </v-icon>
      </template>
      <template v-slot:[`item.defaultFields`]="props">
        <div>
          <li v-for="field in getDefaultFields(props.item)" :key="field">
            {{ field }}
          </li>
        </div>
      </template>
      <template v-slot:[`item.isDefault`]="props">
        <v-icon v-if="!props.item.isDefault" color="red">
          mdi-close-circle-outline
        </v-icon>
        <v-icon v-else color="green">
          mdi-checkbox-marked-circle-outline
        </v-icon>
      </template>
    </v-data-table>
    <EditWasteCode v-if="wasteCodeDialog" :closeDialog="closeWasteCodeDialog" :wasteCode="currentWasteCode"
      :searchWasteCodes="searchWasteCodes" />
  </div>
</template>
<script>
import AddButton from '../components/Buttons/AddButton.vue';
import EditWasteCode from '../components/Modals/EditWasteCode.vue';
import SectionHeadline from '../components/Typography/SectionHeadline.vue';
export default {
  components: {
    SectionHeadline,
    AddButton,
    EditWasteCode
  },
  data() {
    return {
      search: '',
      options: {},
      filter: null,
      wasteCodeDialog: false,
      currentWasteCode: null,
      wasteCodeHeaders: [
        {
          text: "Actiuni",
          value: 'actions'
        },
        {
          text: "Cod",
          value: 'code'
        },
        {
          text: "Nume",
          value: 'name'
        },
        {
          text: 'Campuri default',
          value: 'defaultFields'
        },
        {
          text: "Periculos",
          value: 'isDangerous'
        },
        {
          text: "Se adauga automat",
          value: 'isDefault'
        },
      ]
    };
  },
  watch: {
    options() {
      this.searchWasteCodes()
    },
    search(newVal) {
      this.timeout = setTimeout(() => {
        this.searchWasteCodes()
      }, 500);
    },
  },
  computed: {
    wasteCodes() {
      return this.$store.state.wasteCodes.wasteCodes
    },
    wasteCodesTotal() {
      return this.$store.state.wasteCodes.wasteCodesTotal
    },
  },
  methods: {
    openWasteCodeDialog(item) {
      this.wasteCodeDialog = true
      this.currentWasteCode = item
    },
    closeWasteCodeDialog() {
      this.wasteCodeDialog = false
    },
    getDefaultFields(wasteCode) {
      let defaultFields = Object.keys(wasteCode).filter(k => k.toLocaleLowerCase().includes('default'))
      return defaultFields.filter(f => wasteCode[f]).map(field => {
        return {
          [field]: wasteCode[field].code || wasteCode[field]
        }
      })
    },
    filterWasteCodes(filter) {
      this.filter = filter
      this.searchWasteCodes()
    },
    searchWasteCodes() {
      this.$store.dispatch('fetchWasteCodes', {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        search: this.search,
        filter: this.filter,
        getEntries: true
      });
    }
  },
  created() {
    // this.$store.dispatch('fetchWasteCodes', {
    //   page: 1,
    //   perPage: 25,
    //   getEntries: true
    // })
  }
};
</script>
