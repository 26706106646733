import toastr from '../../toastr';
import axiosInstance from '../axiosWrapper';
export default {
  //===========================
  // STATE
  //===========================
  state: {
    partners: [],
    partnersData: [],
    partnersForWorkingPoint: []
  },
  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_PARTNERS_FOR_WORKING_POINT(state, partners) {
      state.partnersForWorkingPoint = partners
    },
    SET_PARTNERS_DATA(state, data) {
      state.partnersData = data
    },
    UPDATE_PARTNER(state, partner) {
      state.partners = state.partners.map(p => {
        if(p._id == partner._id) {
          p =  {...partner}
        }
        return p
      })
    },
    SET_PARTNERS(state, partners) {
      state.partners = partners
    },
    DELETE_PARTNER(state, id) {
      state.partners = state.partners.filter(r => {
        return (r._id != id)
      })
    },
    ADD_PARTNER(state, partner) {
      state.partners.push(partner)
    },
  },
  //===========================
  // ACTIONS
  //===========================
  actions: {
    async createPartner({ commit }, formData) {
      // let model = formData.model
      // let cars = formData.cars
      // let drivers = formData.drivers
      let data = {
        model: formData.model,
        cars: formData.cars ? formData.cars : [],
        drivers: formData.drivers? formData.drivers : []
      }
      let cb = formData.cb
      let addToWC = formData.addToWC
      let url = `${process.env.VUE_APP_API_URL}/partners`
      if (addToWC && addToWC.length) {
        url += `/?addToWC=${addToWC}`
      }
      try {
        const res = await axiosInstance.post(
          url,
          data,
          {
            withCredentials: true,
          },
        );
        if (res.status === 201) {
          toastr.success("Partenerul a fost adaugat!")
          cb()
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },
    async createPartnerCar({ commit }, formData) {
      let model = formData.model
      let cb = formData.cb
      try {
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/partnersCars`,
          model,
          {
            withCredentials: true,
          },
        );
        if (res.status === 201) {
          toastr.success("Campul a fost adaugat!")
          cb(res.data)
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },
    async createPartnerDriver({ commit }, formData) {
      let model = formData.model
      let cb = formData.cb
      try {
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/partnersDrivers`,
          model,
          {
            withCredentials: true,
          },
        );
        if (res.status === 201) {
          toastr.success("Campul a fost adaugat!")
          cb(res.data)
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
    },
    async fetchPartnersData({ commit }, options) {
      let url = `${process.env.VUE_APP_API_URL}/companies/partnersData`
      try {
        // let url = `${process.env.VUE_APP_API_URL}/companies/partnersData/${userId}`
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_PARTNERS_DATA', res.data);
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchPartners({ commit }, options) {
      let cb = options && options.cb
      let url = `${process.env.VUE_APP_API_URL}/partners/forUser`
      try {
        const res = await axiosInstance.get(
          url,
          // `${process.env.VUE_APP_API_URL}/partners/forUser/${userId}`,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_PARTNERS', res.data);
          if(cb) {
            cb(res.data)
          }
          return true;
        }
      } catch (error) {
        console.log(error)
        return
      }
    },
    async fetchPartnersForWorkingPoint({ commit }, options) {
      let workingPointId = options.workingPointId || ''
      let url = `${process.env.VUE_APP_API_URL}/partners/forWorkingPoint/${workingPointId}`
      try {
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_PARTNERS_FOR_WORKING_POINT', res.data);
          return true;
        }
      } catch (error) {
        console.log(error)
        return
      }
    },
    async updatePartner({ commit }, formData) {
      let id = formData.id
      // let model = formData.model
      let addToWC = formData.addToWC
      let data = {
        model: formData.model
      }
      if(formData.drivers) {
        data.drivers = formData.drivers
      }
      if(formData.cars) {
        data.cars = formData.cars
      }
      if (addToWC && addToWC.length) {
        data.addToWC = addToWC;
      }
      if(formData.wasteCodePerWorkingPoint) {
        data.wasteCodePerWorkingPoint = formData.wasteCodePerWorkingPoint
      }
      let cb = formData.cb
      let canEdit = formData.model.canEdit ? true : false;
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/partners/${id}`,
          data,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          if(canEdit) {
            res.data.canEdit = true;
          }
          commit('UPDATE_PARTNER', res.data);
          toastr.success("Campul a fost updatat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
    async updatePartnerActive({ commit }, formData) {
      let partnerId = formData.partnerId
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/partners/switchActive/${partnerId}`,
          {},
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          toastr.success("Campul a fost updatat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
    async deletePartnerById({ commit }, id) {
      let url = `${process.env.VUE_APP_API_URL}/partners/${id}`
      try {
        const res = await axiosInstance.delete(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('DELETE_PARTNER', id);
          toastr.success("Campul a fost sters!")
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
};
