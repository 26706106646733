<template>
<h2 class="
            text-2xl
            font-bold
            leading-7
            text-gray-900
            sm:text-3xl sm:truncate
            headlineText
        ">
  <slot></slot>
</h2>
</template>
<script>
export default {};
</script>
