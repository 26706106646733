<template>
<v-card class="dashboardCard" :style="`background: ${color}`">
  <v-card-title class="text-black dashboardTitle">
    {{title}}
  </v-card-title>
  <v-divider class="cardSeparator"/>
  <v-card-title class="text-black dashboardInfo">
    {{info}}
  </v-card-title>
</v-card>
</template>
<script>
export default {
  props: [
    'title', 
    'info',
    'color'
  ],
};
</script>
<style scoped>
  .dashboardCard {
    min-width: 150px;
    /* max-width:20%; */
    margin: 15px
  }
  .dashboardTitle {
    white-space: nowrap;
    justify-content: center;
    /* font-weight: bold; */
    text-transform: uppercase;
    font-size: 1em
  }
  .dashboardInfo {
    white-space: nowrap;
    justify-content: center;
    /* font-weight: bold; */
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: bold;
  }
  .cardSeparator {
    margin-left: 10px;
    margin-right: 10px;
  }
</style>
