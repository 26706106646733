<template>
  <v-card>
    <v-row class="mt-2 mr-2">
      <v-spacer />
      <v-btn @click="closeDialog()" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>
    <v-card-title class="mt-5 flex justify-center">
      <div style="margin-right: 10px"> 
        <v-img :width="40" :src="require('@/assets/partners.svg')" class="step-icon" />
      </div>
      <span class="headline font-weight-bold mb-5 mb-md-0">
        Pasul 2 din 3. Adauga un partener caruia ii predai deseurile
      </span>
      
    </v-card-title>
    <v-card-text>
      
      <v-form class="w-full p-0 p-md-5" ref="form">
        <!-- <v-row class="justify-space-between"> -->
        <v-row>
          <v-col cols="12">
            <span >
              Trebuie sa completati datele de identificare ale societatii care preia deseurile de la dumneavoastra, 
              firma de salubritate sau colector de deseuri.
            </span>
          </v-col>
          <!-- <div class="padded w-quarter m-2"> -->
          <v-col cols="12">
            <v-select v-if="workingPoints && !partnerToUpdate" v-model="workingPoint" :items="workingPoints" outlined
              item-text="displayName" label="Alege punctul de lucru" item-value="_id" return-object :rules="[
                v => !!v || `Alege punctul de lucru`]" />
          <!-- </div> -->
          </v-col>
          <v-col cols="12" md="4">
          <!-- <div class="padded w-quarter m-2"> -->
            <v-text-field type="text" placeholder="Cod fiscal partener *" v-model="model.vatId" required outlined
              label="Cod fiscal partener *" @blur="getPartnerDataFromAnaf" :disabled="waitForAnaf" :rules="[
                v => !!v || `Completeaza codul fiscal al partenerului`,
                v => v && v.length <= 100 || `Codul fiscal trebuie sa contina cel mult 50 de caractere`
              ]" >
              <template v-slot:append-outer>

                <v-tooltip
                  bottom max-width="250px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" medium>
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  Completati codul fiscal al partenerului care preia deseurile si sistemul va precompleta automat restul datelor.
                </v-tooltip>
              </template>

            </v-text-field>
          <!-- </div> -->
          </v-col>
          <v-col cols="12" md="4">
          <!-- <div class="padded  w-quarter m-2"> -->
            <v-text-field ref='focusMe' type="text" placeholder="Nume partener *" v-model="model.name" outlined required
              :disabled="waitForAnaf"
              label="Nume partener *" :rules="[
                v => !!v || `Completeaza numele partenerului`,
                v => v && v.length <= 50 || `Numele trebuie sa contina cel mult 50 de caractere`
              ]" >
              <template v-slot:append-outer>
                <v-tooltip
                  bottom max-width="250px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" medium>
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  Completati numele partenerului care preia deseurile de la punctul de lucru.
                </v-tooltip>
              </template>

            </v-text-field>
          <!-- </div> -->
          </v-col>
          <v-col cols="12" md="4">
          <!-- <div class="padded w-quarter m-2"> -->
            <v-text-field type="text" placeholder="Nr Registrul comertului partener" v-model="model.regCom" outlined
              label="Nr Registrul comertului partener" :disabled="waitForAnaf">
              <template v-slot:append-outer>
                <v-tooltip
                  bottom max-width="250px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" medium>
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  Completati numarul de inregistrare la registrul comertului al partenerului care preia deseurile de la punctul de lucru.
                </v-tooltip>
              </template>

            </v-text-field>
          <!-- </div> -->
          </v-col>
        <!-- </v-row>
        <v-row> -->
          <v-col cols="12" md="4">
          <!-- <div class="padded w-full m-2"> -->
            <v-text-field type="text" placeholder="Adresa partener *" v-model="model.address" required outlined label="Adresa partener *"
              :disabled="waitForAnaf"
              :rules="[
                v => !!v || `Completeaza adresa partenerului`,
                v => v && v.length <= 100 || `Adresa trebuie sa contina cel mult 50 de caractere`
              ]" >
              <template v-slot:append-outer>
                <v-tooltip
                  top max-width="250px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" medium>
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  Completati adresa partenerului care preia deseurile de la punctul de lucru.
                </v-tooltip>
              </template>

            </v-text-field>
          <!-- </div> -->
          </v-col>
        <!-- </v-row>
        <v-row> -->
          <v-col cols="12" md="4">
          <!-- <div class="padded  w-third  m-2"> -->
            <v-text-field type="text" placeholder="Oras partener *" v-model="model.city" required label="Oras partener *" outlined 
              :disabled="waitForAnaf" :rules="[
                v => !!v || `Completeaza oras partener`,
                v => v && v.length <= 100 || `Orasul trebuie sa contina cel mult 50 de caractere`
              ]" >
              <template v-slot:append-outer>
                <v-tooltip
                  bottom max-width="250px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" medium>
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  Completati orasul in care este adresa partenerului care preia deseurile de la punctul de lucru.
                </v-tooltip>
              </template>

            </v-text-field>
          </v-col>
          <!-- </div> -->
          <v-col cols="12" md="4">
          <!-- <div class="padded w-third m-2"> -->
            <v-text-field type="text" placeholder="Judet partener *" v-model="model.county" required outlined label="Judet partener *"
              :disabled="waitForAnaf"
              :rules="[
                v => !!v || `Completeaza judet partener`,
                v => v && v.length <= 100 || `Judetul trebuie sa contina cel mult 50 de caractere`
              ]" >
              <template v-slot:append-outer>
                <v-tooltip
                  bottom max-width="250px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" medium>
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  Completati judetul in care este adresa partenerului care preia deseurile de la punctul de lucru.
                </v-tooltip>
              </template>

            </v-text-field>
          <!-- </div> -->
          </v-col>
        <!-- </v-row> -->
          <!-- <div class="padded w-half ma-0 ml-2"> -->
            <!-- <v-row> -->
          <v-col cols="12" >
              <v-combobox clearable label="Tip partener" v-model="model.types" class="left" density="compact" outlined
                multiple underlined :items="partnerTypes" item-text="name" item-value="_id"
                color="primary" :rules="[
                  v => v && v.length ? true : false || 'Completeaza tipul partenerului',
                ]">
                <template v-slot:selection="data">
                  <v-chip :key="data.item._id" v-bind="data.attrs" :input-value="data.selected" :disabled="data.disabled"
                    @click:close="data.parent.selectItem(data.item)">
                    <v-avatar class="accent white--text" left v-text="data.item.name.slice(0, 1).toUpperCase()"></v-avatar>
                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template v-slot:append-outer>
                <v-tooltip
                  bottom max-width="250px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" medium>
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    <li>Colector daca partenerul vine si ia deseurile de la tine</li>
                    <li>Eliminator daca este firma de salubritate si duce deseurile de groapa de gunoi sau la incinerare</li>
                    <li>Valorificator daca duce deseurile spre reciclare sau recuparere energetica</li>
                    <li>Transportator - daca transporta deseurile de la tine la un eliminator/colector/valorificator inclusiv catre el.</li>
                    <li>Generator daca este societate care genereaza deseuri</li>
                  </span>
                </v-tooltip>
              </template>
              </v-combobox>
              <!-- <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="m-2 mt-4" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                  </template>
                  <span>
                    <li>Colector daca partenerul vine si ia deseurile de la tine</li>
                    <li>Eliminator daca este firma de salubritate si duce deseurile de groapa de gunoi sau la incinerare</li>
                    <li>Valorificator daca duce deseurile spre reciclare sau recuparere energetica</li>
                    <li>Transportator - daca transporta deseurile de la tine la un eliminator/colector/valorificator inclusiv catre el.</li>
                    <li>Generator daca este societate care genereaza deseuri</li>
                  </span>
              </v-tooltip> -->
          </v-col>
          
        </v-row>
          <!-- </div> -->
      </v-form>
    </v-card-text>
    <v-card-actions class="cardActions" style="width: 95%">
      <v-spacer />
      <v-btn type="button" class="mainButtonYes" style="width: 200px" text @click="save()">Continua</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import moment from "moment-timezone";
import axiosInstance from "../../../store/axiosWrapper";
import {
ListCategories
} from '../../../common/ListCategories';
export default {
  props: [
    "closeDialog",
    // "workingPoint",
    "wasteCodePWP",
    "forField",
    "createCb",
    "partnerToUpdate",
    "partnerField"
  ],
  data: () => ({
    dialog: true,
    workingPoint: null,
    model: {},
    modal1: false,
    modal2: false,
    modal3: false,
    modal4: false,
    waitForAnaf: false
  }),
  watch: {
    workingPoint() {
      this.model.workingPoint = this.workingPoint
    },
    workingPoints() {
      if(!this.model.workingPoint) {
        let defaultWP = this.workingPoints.find(wp => wp.isDefault)
        if(defaultWP) {
          // this.model.workingPoint = {...defaultWP}
          this.workingPoint = {...defaultWP}
        }
      }
    },
    wasteCodePWP() {
      this.model.wasteCodePerWorkingPoint = this.wasteCodePWP
    }
  },
  methods: {
    close() {
      this.closeDialog()
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('createPartner', {
          model: this.model,
          addToWC: this.forField,
          cb: () => {
            this.createCb(this.partnerField)
            this.$store.dispatch('enterWasteCodeStartGuide')
          }
        });
      }
    },
    parseDate(date) {
      if (!date) {
        return
      }
      return moment(date).format("YYYY-MM-DD");
    },
    async getPartnerDataFromAnaf() {
      if(this.model.vatId) {
        if(!this.model.name && !this.model.address && !this.model.regCom && !this.model.city && !this.model.county) {

          this.waitForAnaf = true;
          let vatId = this.model.vatId.replaceAll(' ', '').replace('RO', '').trim()
          const currentDate = moment().tz('Europe/Bucharest').format('YYYY-MM-DD')
          try {
            const res = await axiosInstance.get(
              `${process.env.VUE_APP_API_URL}/partners/dataFromAnaf/${vatId}/${currentDate}`,
              {
                withCredentials: true,
              },
            );
            if(res && res.data && res.data.name) {
              this.$set(this.model, 'name', res.data.name);
              if(res.data.regCom) {
                this.$set(this.model, 'regCom', res.data.regCom);
              }
              this.$set(this.model, 'address', res.data.address);
              this.$set(this.model, 'city', res.data.city);
              this.$set(this.model, 'county', res.data.county);
            }
            this.waitForAnaf = false;
          } catch(e) {
            this.waitForAnaf = false;
            return
          }
        }
      }
    }
  },
  computed: {
    ListCategories() {
      return ListCategories
    },
    lists() {
      return this.$store.state.wasteCodes.lists
    },
    partnerTypes() {
      return this.lists[ListCategories.TIP_PARTENER]
    },
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
    workingPoints() {
      return this.$store.state.workingPoints.workingPoints
    },
  },
  created() {
    if (this.workingPoints) {
      this.workingPoint = this.workingPoints.find(wp => wp.isDefault)
    }
    this.model.workingPoint = this.workingPoint
    this.model.types = this.partnerTypes.filter(p => 
          p.code != 'Valorificator')
  }
};
</script>
<style scoped>
.w-third {
  width: 31%
}
.w-quarter {
  width: 23%;
}
.w-half {
  width: 50%;
}
</style>
