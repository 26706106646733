var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin":"10px"}},[_c('LayoutHeader',{attrs:{"title":"Puncte de lucru","dontDistrurbFree":true}}),_c('div',{staticClass:"layoutBody"},[(_vm.canAddWorkingPoint && _vm.canPerformOrganizationChanges)?_c('AddButton',{attrs:{"onClick":function () { _vm.openWorkingPointDialog() },"caption":"Adauga"}}):_vm._e(),(_vm.userAdvanced && !_vm.canAddWorkingPoint && _vm.canPerformOrganizationChanges)?_c('AddButton',{attrs:{"onClick":function () { _vm.buyWorkingPoint() },"caption":"Achizitioneaza puncte de lucru"}}):_vm._e(),_c('v-data-table',{attrs:{"items":_vm.workingPoints,"headers":_vm.workingPointHeaders,"search":_vm.search,"footer-props":{
    itemsPerPageOptions: [25, 50, 100],
  }},scopedSlots:_vm._u([{key:"item.wasteCodesPerWorkingPointNo",fn:function(props){return [_c('v-btn',{attrs:{"color":"primary","to":"/wasteCodesPerWorkingPoint"}},[_vm._v(" "+_vm._s(props.item.wasteCodesPerWorkingPointNo)+" deseuri - "+_vm._s(_vm.canEdit(props.item) ? 'gestioneaza' : 'vezi')+" ")])]}},{key:"item.isActive",fn:function(props){return [_c('p',{staticClass:"tableData"}),_c('div',{staticClass:"flex"},[_c('v-checkbox',{staticStyle:{"align-items":"center"},attrs:{"input-value":props.item.isActive,"disabled":props.item.isDefault || _vm.userDisabled || !_vm.canPerformOrganizationChanges},on:{"click":function($event){return _vm.updateWorkingPointActive(props.item)}}})],1),_c('p')]}},{key:"item.isDefault",fn:function(props){return [_c('p',{staticClass:"tableData"}),_c('div',{staticClass:"flex"},[_c('v-checkbox',{staticStyle:{"align-items":"center"},attrs:{"input-value":props.item.isDefault,"disabled":props.item.isDefault || _vm.userDisabled || !_vm.canPerformOrganizationChanges},on:{"click":function($event){return _vm.makeDefault(props.item)}}})],1),_c('p')]}},{key:"item.createdAt",fn:function(props){return [_vm._v(" "+_vm._s(_vm.parseDate(props.item.createdAt))+" ")]}},{key:"item.actions",fn:function(props){return [_c('p',{staticClass:"tableData"}),(_vm.canEdit(props.item))?_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","disabled":_vm.userDisabled},on:{"click":function($event){return _vm.openUpdateDialog(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pen")])],1)]}}],null,true)},[_c('span',[_vm._v("Editeaza")])])],1),(props.item.canBeDeleted && !props.item.isDefault)?_c('div',{staticClass:"flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red","disabled":_vm.userDisabled},on:{"click":function($event){return _vm.openDeleteDialog(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v("Sterge")])])],1):_vm._e()]):_vm._e(),_c('p')]}}],null,true)})],1),(_vm.deleteDialog)?_c('DeleteDialog',{attrs:{"closeDialog":_vm.closeDeleteDialog,"deleteAction":_vm.deleteWorkingPoint,"itemName":"punctul de lucru","item":_vm.currentWorkingPoint}}):_vm._e(),(_vm.createDialog)?_c('CreateWorkingPoint',{attrs:{"closeDialog":_vm.closeCreateDialog,"companies":_vm.companies}}):_vm._e(),(_vm.updateDialog)?_c('UpdateWorkingPoint',{attrs:{"closeDialog":_vm.closeUpdateDialog,"model":_vm.currentWorkingPoint}}):_vm._e(),(_vm.wasteCodesDialog)?_c('WasteCodesPerWorkingPointDialog',{attrs:{"closeDialog":_vm.closeWasteCodesPerWorkingPoint,"workingPoint":_vm.currentWorkingPoint}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }