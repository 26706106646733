<template>
  <base-layout :headline="headline">
    <template #header>
      <authenticated-navbar :user="$store.state.auth.user"></authenticated-navbar>
    </template>
    <div v-if="$store.state.auth.loadingUser">
      <!-- Loading spinner -->
      <spinner size="large"></spinner>
    </div>
    <div v-else-if="!$store.state.auth.loadingUser && $store.state.auth.isAuthenticated
    ">
      <!-- Child components -->
      <slot></slot>
    </div>
    <div v-else>You are not authorized to access this page.
      <div class="w-full h-full flex items-center justify-center">
        <card class="w-6/12 h-6/12" center="true">
          <router-link to="/login">
            <span class="text-blue-700 underline">Login</span>
          </router-link>
          <br>
          <v-divider style="border-block: white;" />
          <span class="mb-6">Don't have an account yet?
            <router-link to="/register">
              <span class="text-blue-700 underline">Register</span>
            </router-link></span>
        </card>
      </div>
    </div>
  </base-layout>
</template>
<script>
import AuthenticatedNavbar from '../components/Navigation/AuthenticatedNavbar.vue';
import Spinner from '../components/Widgets/Spinner/Spinner.vue';
import BaseLayout from '../Layouts/BaseLayout.vue';
export default {
  data() {
    return {
      loading: true,
    };
  },
  props: ['headline'],
  components: {
    AuthenticatedNavbar,
    Spinner,
    BaseLayout
  },

  async beforeCreate() {
    await this.$store.dispatch('fetchUser');
    await this.$store.dispatch('fetchUserById', this.userImpId);
    if (
      !this.$store.state.auth.loadingUser &&
      !this.$store.state.auth.isAuthenticated
    ) {
      this.$router.push('/login');
    }
    this.loading = false;
  },
  watch: {
    user() {
      // if (this.userImpId) {
      this.$store.dispatch('fetchUserById', this.userImpId);
      // }
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
    userImp() {
      return this.$store.state.auth.userImp
    },
    userImpId() {
      return window.localStorage.getItem('userImpId')
    },
  },
  methods: {
   
  },
  created() { }
};
</script>
