<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1200px">
      <v-card>
        <v-card-title>
          <span class="headline">Adauga utilizatori</span>
        </v-card-title>
        <v-card-text>
          <v-form class="w-full padded p-5" ref="form">
            <v-row v-for="user in users">
              <v-col cols="4">
                <div class="flex">
                  <v-text-field ref='focusMe' type="text" placeholder="Email *" v-model="user.email" required
                    style="margin-top: 15px" label="Email *" :rules="[v => !!v || `Completeaza email-ul`]" />
                  <v-btn icon @click="addEmptyPermission(user)" style="margin-top: 15px">
                    <v-icon color="primary">mdi-plus</v-icon>
                  </v-btn>
                </div>
              </v-col>

              <v-col cols="8">
                <div class="align-self-center flex" v-for="(permission, index) in user.permissions"
                  :key="`perm${index}`">
                  <div class="w-25 align-self-center">
                    <v-select class="px-2 py-1  text-lighten-1" v-model="permission.role" hide-details :items="roles"
                      :label="'Rolul*'" :placeholder="'Alege rolul'" :rules="[v => !!v || `Alege rolul *`]"></v-select>
                  </div>
                  <div class="w-75 align-self-center">
                    <v-autocomplete class="px-2 py-1  text-lighten-1 " v-model="permission.workingPoints"
                      style="margin-top: 15px" :label="'Puncte de lucru la care va avea acces *'"
                      :placeholder="'Alege punctele de lucru'" :items="formattedWorkingPoints" item-text="title"
                      item-value="_id" multiple chips
                      :rules="[v => !!v || `Alege cel putin un punct de luceu *`]"></v-autocomplete>
                  </div>
                  <v-btn variant="plain" icon @click="removePermission(permission, user)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>

          <v-btn variant="text" class="text-capitalize px-2 py-1" color="accent" @click="addEmptyUser()">
            Adauga
          </v-btn>

        </v-card-text>
        <v-card-actions class="cardActions">
          <v-btn type="button" class="mainButtonYes" text @click="save()">Adauga</v-btn>
          <v-btn type="button" class="mainButtonNo" color="red" @click="close()">Renunta</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
export default {
  props: [
    "closeDialog",
    "companies",
    "companyOnly",
    "createCb",
    "workingPoints"
  ],
  data: () => ({
    dialog: true,
    inputRules: [v => !!v || "Campul este obligatoriu"],
    model: {},
    users: [],
    company: null,
    roles: [
      {
        text: "Administrator",
        value: "MANAGER"
      },
      {
        text: "Introducere date",
        value: "DATA_RECORDER"
      },
      {
        text: "Vizualizare",
        value: "VIEWER"
      }
    ]
  }),
  watch: {

  },
  methods: {
    close() {
      this.closeDialog()
    },
    addEmptyUser() {
      this.users.push({
        email: '', workingPoints: [], role: '', permissions: [
          { role: '', workingPoints: [] }
        ]
      })
    },
    addEmptyPermission(user) {
      user.permissions.push({ role: null, workingPoints: [] })
    },

    removePermission(permission, user) {
      user.permissions = user.permissions.filter(p => p != permission)
    },
    getManagersWorkingPoints(userInfo) {

      if (userInfo.permissions && userInfo.role == 'GUEST') {
        let managePermission = userInfo.permissions.find(p => p.role == 'MANAGER')
        if (!managePermission) {
          return []
        }
        return managePermission.workingPoints
      }

      return null
    },



    save() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('saveUsers', {
          models: this.users.map(m => {
            m.email = m.email.replace('\t', '').trim()
            m.workingPoints = m.permissions.map(p => p.workingPoints).reduce((acc, workingPoints) => acc.concat(workingPoints), []);
            return m
          }),
          cb: () => {
            if (this.createCb) {
              this.createCb()
            }
          }
        }

        )

        this.close()
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    userImp() {
      return this.$store.state.auth.userImp
    },
    formattedWorkingPoints() {
      const allowedWorkingPointIds = this.getManagersWorkingPoints(this.userImp ? this.userImp : this.user)

      const allowedWorkingPoints = !allowedWorkingPointIds ? this.workingPoints :
        this.workingPoints.filter(wp => allowedWorkingPointIds.includes(wp._id))

      return allowedWorkingPoints.map(wp => {
        wp.title = `${wp.company.companyName} \\ ${wp.name}`
        return wp
      })
    }

  },
  created() {
    this.addEmptyUser()

  }
};
</script>
