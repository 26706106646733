<template>
  <v-card>
    <v-row class="mt-2 mr-2">
      <v-spacer />
      <v-btn @click="closeDialog()" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>
    <v-card-title class="mt-3 flex justify-center">
      <div style="margin-right: 10px">
        <v-img :width="40" :src="require('@/assets/workingPoint.svg')" class="step-icon" />
      </div>
      <span class="headline font-weight-bold">
        Pasul 1 din 3. Adauga punctul de lucru
      </span>
    </v-card-title>
    <v-card-text>
      <v-form class="w-full pa-0 pa-md-5 mt-5 mt-md-0" ref="form">
        <v-row>
          <v-col cols="12">
            Trebuie sa adaugati detaliile punctului de lucru unde va desfasurati activitatea.
          </v-col>
          <v-col cols="12">
          <!-- <div class="padded w-half m-2"> -->
            <v-autocomplete label="Alege organizatia *" v-model="model.company" :items="companies"
              color="blue-grey-lighten-2" item-text="companyName" item-value="_id"
              :rules="[v => !!v || `Alege organizatia *`]" item outlined return-object>
            </v-autocomplete>
          <!-- </div> -->
          </v-col>
        <!-- </v-row> -->
        <!-- <v-row class="justify-space-between"> -->
          <v-col cols="12" md="4">
          <!-- <div class="padded w-third m-2"> -->
            <v-text-field ref='focusMe' type="text" placeholder="Nume punct de lucru *"
              v-model="model.name" required label="Nume punct de lucru *" :rules="[
                v => !!v || `Completeaza numele punctului de lucru`,
                v => v && v.length <= 50 || `Numele trebuie sa contina cel mult 50 de caractere`
              ]" outlined >
              <template v-slot:append-outer>
                <v-tooltip
                  bottom max-width="250px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" medium>
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  Trebuie sa completati numele punctului de lucru la care va desfasurati activitatea. <br />
                  Acesta nu este unul legal, poate fi Magazin, Birou, numele magazinului, numele restaurantului, 
                  numele companiei daca aveti un singur punct de lucru etc
                </v-tooltip>
              </template>
            </v-text-field>
          <!-- </div> -->
          </v-col>
          <v-col cols="12" md="4">
          <!-- <div class="padded w-third m-2"> -->
            <v-text-field type="text" placeholder="Oras punct de lucru *" v-model="model.city" required
              label="Oras punct de lucru *" :rules="[
                v => !!v || `Completeaza oras punct de lucru`,
                v => v && v.length <= 100 || `Orasul trebuie sa contina cel mult 50 de caractere`
              ]" outlined >
              <template v-slot:append-outer>
                <v-tooltip
                  bottom max-width="250px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" medium>
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  Trebuie sa completati orasul in care este situat punctul de lucru asa cum este inregistrat la Registrul Comertului
                </v-tooltip>
              </template>
            </v-text-field>
          <!-- </div> -->
          </v-col>
          <v-col cols="12" md="4">
          <!-- <div class="padded w-third m-2"> -->
            <v-text-field type="text" placeholder="Judet punct de lucru *" v-model="model.county" required
              label="Judet punct de lucru *" :rules="[
                v => !!v || `Completeaza judet punct de lucru`,
                v => v && v.length <= 100 || `Judetul trebuie sa contina cel mult 50 de caractere`
              ]" outlined >
              <template v-slot:append-outer>
                <v-tooltip
                  bottom max-width="250px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" medium>
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  Trebuie sa completati judetul in care este situat punctul de lucru asa cum este inregistrat la Registrul Comertului
                </v-tooltip>
              </template>
            </v-text-field>
          <!-- </div> -->
          </v-col>
        <!-- </v-row> -->
        <!-- <v-row> -->
          <v-col cols="12">
          <!-- <div class="padded w-75 m-2"> -->
            <v-text-field type="text" placeholder="Adresa punct de lucru *" v-model="model.address" required
              label="Adresa punct de lucru *" :rules="[
                v => !!v || `Completeaza adresa punct de lucru *`,
                v => v && v.length <= 100 || `Adresa trebuie sa contina cel mult 50 de caractere`
              ]" outlined >
              <template v-slot:append-outer>
                <v-tooltip
                  bottom max-width="250px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" medium>
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  Trebuie sa completati adresa la care este situat punctul de lucru asa cum este inregistrat la Registrul Comertului
                </v-tooltip>
              </template>
            </v-text-field>
          <!-- </div> -->
          </v-col>
          <v-col cols="12" md="4">
          <!-- <div class="padded w-quarter m-2"> -->
            <v-autocomplete label="Alege tipul de activitate" v-model="model.activityType" :items="activityTypes"
              color="blue-grey-lighten-2" outlined>
              <template v-slot:append-outer>
                <v-tooltip
                  bottom max-width="250px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" medium>
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  Alegeti tipul de activitate ce este desfasurata la punctul de lucru
                </v-tooltip>
              </template>
            </v-autocomplete>
          <!-- </div> -->
          </v-col>
        <!-- </v-row> -->
        <!-- <v-row> -->
          <v-col cols="12" md="4">
          <!-- <div class="padded w-third m-2"> -->
            <v-text-field type="text" placeholder="Cod Fiscal" v-model="model.vatId" required label="Cod Fiscal"
              outlined >
              <template v-slot:append-outer>
                <v-tooltip
                  bottom max-width="250px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" medium>
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  Se completeaza numai daca punctul de lucru are Cod fiscal diferit de cel al companiei.
                </v-tooltip>
              </template>
            </v-text-field>
          <!-- </div> -->
          </v-col>
          <v-col cols="12" md="4">
          <!-- <div class="padded w-third m-2"> -->
            <v-text-field type="text" placeholder="Nr Registrul comertului" v-model="model.regCom"
              label="Nr Registrul comertului" outlined >
              <template v-slot:append-outer>
                <v-tooltip
                  bottom max-width="250px"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" medium>
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  Se completeaza numai daca punctul de lucru are Numar Registrul Comertului diferit de cel al companiei.
                </v-tooltip>
              </template>
            </v-text-field>
          <!-- </div> -->
          </v-col>
          <v-col cols="12">
            <div><span class="font-weight-bold">Autorizatie de mediu</span> (doar pentru punctele de lucru unde se aplica):</div>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field type="text" placeholder="Numar autorizatie mediu" v-model="model.noAuth"
              :label="getAuthLabel()" :rules="getAuthRules()" clearable outlined />
          </v-col>
          <v-col cols="12" md="4">
            <v-dialog max-width="300px" persistent v-model="modal1">
              <template v-slot:activator="{ on: { click } }">
                <v-text-field v-on:click="click" v-model="model.startDateAuth" :label="getStartAuthLabel()"
                :rules="getStartAuthRules()" clearable outlined></v-text-field>
              </template>
              <v-date-picker v-model="model.startDateAuth" scrollable actions>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="modal1 = false">Cancel</v-btn>
                  <v-btn color="primary" @click="modal1 = false">OK</v-btn>
                </v-card-actions>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="4">
            <v-dialog max-width="300px" persistent v-model="modal2">
              <template v-slot:activator="{ on: { click } }">
                <v-text-field v-on:click="click" v-model="model.endDateAuth" :label="getEndAuthLabel()"
                :rules="getEndAuthRules()" clearable outlined></v-text-field>
              </template>
              <v-date-picker v-model="model.endDateAuth" scrollable actions>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="modal2 = false">Cancel</v-btn>
                  <v-btn color="primary" @click="modal2 = false">OK</v-btn>
                </v-card-actions>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="cardActions">
      <v-spacer style="max-width: 80%;" />
      <v-btn type="button" class="mainButtonYes" style="width: 200px" text @click="save()">Continua</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: [
    "companies",
    "createCb",
    "closeDialog"
  ],
  data: () => ({
    dialog: true,
    inputRules: [v => !!v || "Campul este obligatoriu"],
    model: {},
    company: null,
    workingPointType: "WORKING_POINT",
    activityTypes: [
      'HoReCa',
      'Beauty',
      'Birou',
      'Medical',
      'Magazin',
      'Depozit',
      'Productie',
      'Altele',
    ],
    modal1: false,
    modal2: false
  }),
  watch: {
    // company() {
    //   this.model.vatId = null
    //   this.model.company = this.company._id
    // }
  },
  methods: {
    close() {
      this.closeDialog()
    },
    getAuthRules() {
      if(this.model.endDateAuth || this.model.startDateAuth) {
        return [
          v => !!v || `Completeaza numar autorizatie de mediu`,
          v => v && v.length <= 10 || `Numarul autorizatiei de mediu trebuie sa contina cel mult 10 de caractere`
        ]
      } else {
        return [
          v => v && v.length <= 10 || !v || `Numarul autorizatiei de mediu trebuie sa contina cel mult 10 de caractere`
        ]
      }
    },
    getAuthLabel() {
      if (this.model.endDateAuth || this.model.startDateAuth) {
        return 'Numar autorizatie de mediu *'
      }
      return 'Numar autorizatie de mediu'
    },
    getStartAuthRules() {
      if(this.model.endDateAuth || this.model.noAuth) {
        return [
          v => !!v || `Completeaza data start autorizatie de mediu`
        ]
      }
      return []
    },
    getStartAuthLabel() {
      if (this.model.endDateAuth || this.model.noAuth) {
        return 'Data start autorizatie de mediu *'
      }
      return 'Data start autorizatie de mediu'
    },
    getEndAuthRules() {
      if(this.model.noAuth || this.model.startDateAuth) {
        return [
          v => !!v || `Completeaza data stop autorizatie de mediu`
        ]
      }
      return []
    },
    getEndAuthLabel() {
      if (this.model.noAuth || this.model.startDateAuth) {
        return 'Data stop autorizatie de mediu *'
      }
      return 'Data stop autorizatie de mediu'
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('createWorkingPoint', {
          data: this.model,
          cb: () => {
            this.$store.dispatch('fetchWorkingPoints');
            if (this.createCb) {
              this.createCb()
              this.$store.dispatch('enterPartnerStartGuide')
            }
          }
        });
      }
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
    companies() {
      return this.$store.state.companies.companies
    },
  },
  created() {
    // this.company = this.companies ? this.companies[0] : null
    this.model.company = this.companies ? this.companies[0] : null
  }
};
</script>
<style scoped>
.w-third {
  width: 31%
}
.w-half {
  width: 50%;
}
</style>
