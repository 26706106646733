<template>
  <div style="margin: 10px">
    <section-headline> Panou de bord admin </section-headline>
    <div class="flex">
      <v-btn @click="exportUsers()" class="text-gray-500 primary">
        <span>Exporta</span>
      </v-btn>
      <v-progress-circular
        indeterminate
        color="primary"
        v-if="loading"
      ></v-progress-circular>
    </div>
    <v-data-table
      class="adminUsersTable"
      :items="users"
      :headers="userHeaders"
      :search="search"
      :options.sync="options"
      :server-items-length="totalUsers"
      :footer-props="{
        itemsPerPageOptions: [25, 50, 100],
      }"
    >
      <template v-slot:top>
        <div class="flex topSection">
          <v-text-field
            class="searchWidth"
            v-model="search"
            label="Search"
            hide-details
          ></v-text-field>
          <v-combobox
            clearable
            label="Tip abonament"
            v-model="paymentFilter"
            class="left filterWidth paddedLeft"
            density="compact"
            hide-details
            underlined
            :items="paymentTypes"
            color="primary"
            return-object
          >
          </v-combobox>
          <v-combobox
            clearable
            label="Status abonament"
            v-model="paymentStatusFilter"
            class="left filterWidth paddedLeft"
            density="compact"
            underlined
            hide-details
            :items="paymentStatuses"
            color="primary"
            return-object
          >
          </v-combobox>
          <v-menu offset-y :close-on-content-click="false" v-model="createdFilter.show">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="createdFilter.filter"
                label="Data creare"
                clearable
                class="left paddedLeft"
                style="max-width: 560px"
                hide-details
                underlined
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click:clear="clearDateField(createdFilter)"
              ></v-text-field>
            </template>
            <v-card class="d-flex flex-column align-center bordered">
              <div class="d-flex justify-center">
                <v-text-field
                  v-model="createdFilter.date1"
                  class="mr-5"
                  type="date"
                  label="Alege data start"
                />
                <v-text-field
                  v-model="createdFilter.date2"
                  type="date"
                  label="Alege data stop"
                />
              </div>
              <v-btn class="text-secondary" @click="formatDateFilter(createdFilter)">
                OK
              </v-btn>
            </v-card>
          </v-menu>
          <v-menu offset-y :close-on-content-click="false" v-model="expiredFilter.show">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="left paddedLeft"
                style="max-width: 560px"
                underlined
                hide-details
                color="primary"
                v-model="expiredFilter.filter"
                label="Data expirare"
                clearable
                v-bind="attrs"
                v-on="on"
                @click:clear="clearDateField(expiredFilter)"
              ></v-text-field>
            </template>
            <v-card class="d-flex flex-column align-center bordered">
              <div class="d-flex justify-center">
                <v-text-field
                  v-model="expiredFilter.date1"
                  class="mr-5"
                  type="date"
                  label="Alege data start"
                />
                <v-text-field
                  v-model="expiredFilter.date2"
                  type="date"
                  label="Alege data stop"
                />
              </div>
              <v-btn class="text-secondary" @click="formatDateFilter(expiredFilter)">
                OK
              </v-btn>
            </v-card>
          </v-menu>
        </div>
      </template>
      <template v-slot:[`item.mainCompany`]="props">
        <v-btn @click="impersonateUser(props.item)" class="pl-1 pl-sm-0 btnImpersonate">
          <v-icon color="secondary pr-1" class="pr-1 pr-sm-0">mdi-drama-masks</v-icon>
          <span class="txtUserName">{{ props.item.mainCompany }}</span>
          <v-tooltip location="bottom" activator="parent">
            <span class="text-primary">IMPERSONARE</span>
          </v-tooltip>
        </v-btn>

        <!-- <router-link :to="`/${props.item._id}`"
          :class="`px-3 py-2 rounded-md text-sm font-medium sm:ml- grid forceMarginLeft`" aria-current="page"> <span>
            {{ props.item.mainCompany }}</span>
        </router-link> -->
      </template>
      <template v-slot:[`item.createdAt`]="props">
        {{ parseDate(props.item.createdAt) }}
      </template>

      <template v-slot:[`item.phoneNumber`]="props">
        <div class="flex align-center">
          {{ props.item.phoneNumber }}
          <v-btn text @click="openWhatsapp(props.item.phoneNumber)">
            <v-icon color="green"> mdi-whatsapp </v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:[`item.endDate`]="props">
        {{ parseDate(props.item.lastPayment.endDate) }}
      </template>
      <template v-slot:[`item.paymentStatus`]="props">
        {{ props.item.paymentStatus == "DISABLED" ? "INACTIV" : "ACTIV" }}
      </template>
      <template v-slot:[`item.actions`]="props">
        <v-btn @click="showPayments(props.item)" class="text-gray-500 primary">
          <span>Plati</span>
        </v-btn>
      </template>
    </v-data-table>
    <PaymentsDialog
      v-if="paymentDialog"
      :user="editedUser"
      :closeDialog="closePayments"
    />
  </div>
</template>
<script>
import moment from "moment-timezone";
import AddButton from "../components/Buttons/AddButton.vue";
import PaymentsDialog from "../components/Modals/PaymentsDialog.vue";
import SectionHeadline from "../components/Typography/SectionHeadline.vue";
export default {
  components: {
    SectionHeadline,
    AddButton,
    PaymentsDialog,
  },
  props: ["impersonateUser"],
  data() {
    return {
      search: "",
      timeout: null, // Add this line
      loading: false,
      options: {},
      paymentFilter: null,
      paymentStatusFilter: null,
      createdFilter: {
        show: false,
        date1: null,
        date2: null,
        filter: null,
      },
      expiredFilter: {
        show: false,
        date1: null,
        date2: null,
        filter: null,
      },
      paymentTypes: ["ADVANCED", "ADVANCED YEARLY", "FREE"],
      paymentStatuses: ["ACTIV", "INACTIV"],
      paymentDialog: false,
      userHeaders: [
        // {
        //   text: '',
        //   value: 'actions'
        // },
        {
          text: "Nume",
          value: "mainCompany",
        },
        {
          text: "Nume client",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Telefon",
          value: "phoneNumber",
        },
        {
          text: "CUI companie platitoare",
          value: "mainVatId",
        },
        {
          text: "Numar puncte lucru",
          value: "workingPointsNo",
        },
        {
          text: "Puncte lucru achizitionate",
          value: "allowedWorkingPoints",
        },
        /**
         * nr de transporturi adaugate
  Nr de pct de lucru achizitionate
         */
        {
          text: "Transporturi adaugate",
          value: "transports",
        },
        {
          text: "Tip Abonament",
          value: "lastPayment.productId",
        },
        {
          text: "Status Abonament",
          value: "paymentStatus",
        },
        {
          text: "Data creare",
          value: "createdAt",
        },
        {
          text: "Data expirare",
          value: "endDate",
        },

        {
          text: "Rol",
          value: "role",
        },
      ],
    };
  },
  watch: {
    options() {
      this.getData();
    },
    paymentFilter() {
      this.getData();
    },
    paymentStatusFilter() {
      this.getData();
    },
    search(newVal) {
      clearTimeout(this.timeout); // Clear the previous timeout
      this.timeout = setTimeout(() => {
        this.getData();
      }, 500);
    },
  },
  computed: {
    users() {
      return this.$store.state.admin.users;
    },
    totalUsers() {
      return this.$store.state.admin.totalUsers;
    },
  },
  methods: {
    openWhatsapp(phone) {
      let formattedPhone = phone.replace(/\D/g, "");

      // Check if the phone number starts with '40' (Romanian country code)
      if (!formattedPhone.startsWith("40")) {
        // If it doesn't, prepend '40' to the phone number
        formattedPhone = "40" + formattedPhone;
      }

      // Construct the WhatsApp URL
      const whatsappUrl = `https://wa.me/${formattedPhone}`;

      // Open WhatsApp in a new tab with the correct number
      window.open(whatsappUrl, "_blank");
    },
    showPayments(item) {
      this.paymentDialog = true;
      this.editedUser = item;
    },
    closePayments() {
      (this.paymentDialog = false), (this.editedUser = null);
    },
    clearDateField(filterItem) {
      filterItem.date1 = null;
      filterItem.date2 = null;
      this.getData();
    },
    formatDateFilter(filterItem) {
      let filterValue = null;
      if (!filterItem.date1 || !filterItem.date2) {
        return;
      }
      filterValue = `${filterItem.date1} - ${filterItem.date2}`;
      filterItem.filter = filterValue;
      filterItem.show = false;
      this.getData();
    },
    getFilter() {
      let filter = {};
      if (this.createdFilter && this.createdFilter.date1 && this.createdFilter.date2) {
        filter.createdAt = {
          date1: this.createdFilter.date1,
          date2: this.createdFilter.date2,
        };
      }
      if (this.expiredFilter && this.expiredFilter.date1 && this.expiredFilter.date2) {
        filter.endDate = {
          date1: this.expiredFilter.date1,
          date2: this.expiredFilter.date2,
        };
      }
      return filter;
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    exportUsers() {
      let filter = this.getFilter();
      this.loading = true;
      this.$store.dispatch("exportUsers", {
        search: this.search,
        paymentFilter: this.paymentFilter,
        paymentStatusFilter: this.paymentStatusFilter,
        filter: filter,
        cb: (response) => {
          let fileName = "Utilizatori RD.xlsx";
          this.loading = false;
          let fileUrl = window.URL.createObjectURL(response.data);
          let fileLink = document.createElement("a");
          fileLink.href = fileUrl;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        },
      });
    },
    getData() {
      let filter = this.getFilter();
      this.loading = true;
      this.$store.dispatch("fetchUsers", {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        search: this.search,
        paymentFilter: this.paymentFilter,
        paymentStatusFilter: this.paymentStatusFilter,
        filter: filter,
        cb: () => {
          this.loading = false;
        },
      });
    },
  },
  created() {
    // this.getData()
  },
};
</script>
<style scoped>
.filterWidth {
  width: 20%;
}
.searchWidth {
  width: 25%;
}
.paddedLeft {
  padding-left: 10px;
}
</style>
