var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-row',{staticClass:"mt-2 mr-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-title',{staticClass:"mt-5 flex justify-center"},[_c('div',{staticStyle:{"margin-right":"10px"}},[_c('v-img',{staticClass:"step-icon",attrs:{"width":40,"src":require('@/assets/partners.svg')}})],1),_c('span',{staticClass:"headline font-weight-bold mb-5 mb-md-0"},[_vm._v(" Pasul 2 din 3. Adauga un partener caruia ii predai deseurile ")])]),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"w-full p-0 p-md-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v(" Trebuie sa completati datele de identificare ale societatii care preia deseurile de la dumneavoastra, firma de salubritate sau colector de deseuri. ")])]),_c('v-col',{attrs:{"cols":"12"}},[(_vm.workingPoints && !_vm.partnerToUpdate)?_c('v-select',{attrs:{"items":_vm.workingPoints,"outlined":"","item-text":"displayName","label":"Alege punctul de lucru","item-value":"_id","return-object":"","rules":[
              function (v) { return !!v || "Alege punctul de lucru"; }]},model:{value:(_vm.workingPoint),callback:function ($$v) {_vm.workingPoint=$$v},expression:"workingPoint"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Cod fiscal partener *","required":"","outlined":"","label":"Cod fiscal partener *","disabled":_vm.waitForAnaf,"rules":[
              function (v) { return !!v || "Completeaza codul fiscal al partenerului"; },
              function (v) { return v && v.length <= 100 || "Codul fiscal trebuie sa contina cel mult 50 de caractere"; }
            ]},on:{"blur":_vm.getPartnerDataFromAnaf},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_vm._v(" Completati codul fiscal al partenerului care preia deseurile si sistemul va precompleta automat restul datelor. ")])]},proxy:true}]),model:{value:(_vm.model.vatId),callback:function ($$v) {_vm.$set(_vm.model, "vatId", $$v)},expression:"model.vatId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{ref:"focusMe",attrs:{"type":"text","placeholder":"Nume partener *","outlined":"","required":"","disabled":_vm.waitForAnaf,"label":"Nume partener *","rules":[
              function (v) { return !!v || "Completeaza numele partenerului"; },
              function (v) { return v && v.length <= 50 || "Numele trebuie sa contina cel mult 50 de caractere"; }
            ]},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_vm._v(" Completati numele partenerului care preia deseurile de la punctul de lucru. ")])]},proxy:true}]),model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Nr Registrul comertului partener","outlined":"","label":"Nr Registrul comertului partener","disabled":_vm.waitForAnaf},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_vm._v(" Completati numarul de inregistrare la registrul comertului al partenerului care preia deseurile de la punctul de lucru. ")])]},proxy:true}]),model:{value:(_vm.model.regCom),callback:function ($$v) {_vm.$set(_vm.model, "regCom", $$v)},expression:"model.regCom"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Adresa partener *","required":"","outlined":"","label":"Adresa partener *","disabled":_vm.waitForAnaf,"rules":[
              function (v) { return !!v || "Completeaza adresa partenerului"; },
              function (v) { return v && v.length <= 100 || "Adresa trebuie sa contina cel mult 50 de caractere"; }
            ]},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_vm._v(" Completati adresa partenerului care preia deseurile de la punctul de lucru. ")])]},proxy:true}]),model:{value:(_vm.model.address),callback:function ($$v) {_vm.$set(_vm.model, "address", $$v)},expression:"model.address"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Oras partener *","required":"","label":"Oras partener *","outlined":"","disabled":_vm.waitForAnaf,"rules":[
              function (v) { return !!v || "Completeaza oras partener"; },
              function (v) { return v && v.length <= 100 || "Orasul trebuie sa contina cel mult 50 de caractere"; }
            ]},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_vm._v(" Completati orasul in care este adresa partenerului care preia deseurile de la punctul de lucru. ")])]},proxy:true}]),model:{value:(_vm.model.city),callback:function ($$v) {_vm.$set(_vm.model, "city", $$v)},expression:"model.city"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"type":"text","placeholder":"Judet partener *","required":"","outlined":"","label":"Judet partener *","disabled":_vm.waitForAnaf,"rules":[
              function (v) { return !!v || "Completeaza judet partener"; },
              function (v) { return v && v.length <= 100 || "Judetul trebuie sa contina cel mult 50 de caractere"; }
            ]},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_vm._v(" Completati judetul in care este adresa partenerului care preia deseurile de la punctul de lucru. ")])]},proxy:true}]),model:{value:(_vm.model.county),callback:function ($$v) {_vm.$set(_vm.model, "county", $$v)},expression:"model.county"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{staticClass:"left",attrs:{"clearable":"","label":"Tip partener","density":"compact","outlined":"","multiple":"","underlined":"","items":_vm.partnerTypes,"item-text":"name","item-value":"_id","color":"primary","rules":[
                function (v) { return v && v.length ? true : false || 'Completeaza tipul partenerului'; } ]},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({key:data.item._id,attrs:{"input-value":data.selected,"disabled":data.disabled},on:{"click:close":function($event){return data.parent.selectItem(data.item)}}},'v-chip',data.attrs,false),[_c('v-avatar',{staticClass:"accent white--text",attrs:{"left":""},domProps:{"textContent":_vm._s(data.item.name.slice(0, 1).toUpperCase())}}),_vm._v(" "+_vm._s(data.item.name)+" ")],1)]}},{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"250px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"medium":""}},on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_c('li',[_vm._v("Colector daca partenerul vine si ia deseurile de la tine")]),_c('li',[_vm._v("Eliminator daca este firma de salubritate si duce deseurile de groapa de gunoi sau la incinerare")]),_c('li',[_vm._v("Valorificator daca duce deseurile spre reciclare sau recuparere energetica")]),_c('li',[_vm._v("Transportator - daca transporta deseurile de la tine la un eliminator/colector/valorificator inclusiv catre el.")]),_c('li',[_vm._v("Generator daca este societate care genereaza deseuri")])])])]},proxy:true}]),model:{value:(_vm.model.types),callback:function ($$v) {_vm.$set(_vm.model, "types", $$v)},expression:"model.types"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"cardActions",staticStyle:{"width":"95%"}},[_c('v-spacer'),_c('v-btn',{staticClass:"mainButtonYes",staticStyle:{"width":"200px"},attrs:{"type":"button","text":""},on:{"click":function($event){return _vm.save()}}},[_vm._v("Continua")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }