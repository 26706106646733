<template>
<auth-layout>
  <CompaniesLayout/>
</auth-layout>
</template>
<script>
import AuthLayout from '../Layouts/AuthLayout.vue';
import CompaniesLayout from '../Layouts/CompaniesLayout.vue';
export default {
  data() {
    return {};
  },
  components: {
    AuthLayout,
    CompaniesLayout
  },
  computed: {
  },
  watch: {
  },
  methods: {},
  created() {
  }
};
</script>
