<template>
<div style="margin: 10px">
  <LayoutHeader title="Companii" :dontDistrurbFree="true"/>
  <div class="layoutBody">
    <AddButton v-if="canAddCompany && canPerformOrganizationChanges" :onClick="() => {openCompanyDialog()}" caption="Adauga" />
    <v-data-table :items="companies" :headers="companyHeaders" :search="search" :options.sync="options" :footer-props="{
          itemsPerPageOptions: [25, 50, 100],
        }">
      <template v-slot:[`item.workingPoints`]="props">
        {{props.item.workingPoints.filter(wp => !wp.isDeleted).length}}
      </template>
      <template v-slot:[`item.isActive`]="props">
          <p class="tableData">
          <div class="flex">
              <v-checkbox class="" style="align-items: center;" :input-value="props.item.isActive" :disabled="userDisabled || !canPerformOrganizationChanges"
              @click="updateCompanyActive(props.item)"></v-checkbox>
          </div>
          </p>
      </template>
      <template v-slot:[`item.createdAt`]="props">
        {{parseDate(props.item.createdAt)}}
      </template>
    </v-data-table>
  </div>
  <CreateWorkingPoint v-if="createDialog" :closeDialog="closeCreateDialog" :companies="companies" :companyOnly="true"/>
</div>
</template>
<script>
import moment from "moment-timezone";
import AddButton from '../components/Buttons/AddButton.vue';
import CreateWorkingPoint from '../components/Modals/Create/CreateWorkingPoint.vue';
import UpdateWorkingPoint from '../components/Modals/UpdateWorkingPoint.vue';
import SectionHeadline from '../components/Typography/SectionHeadline.vue';
import LayoutHeader from '../components/Widgets/LayoutHeader.vue';
export default {
  components: {
    LayoutHeader,
    SectionHeadline,
    AddButton,
    CreateWorkingPoint,
    UpdateWorkingPoint
  },
  data() {
    return {
      search: '',
      createDialog: false,
      companyHeaders: [
        // {
        //   text: "Actiuni",
        //   value: 'actions'
        // },
        {
          text: "Denumire",
          value: 'companyName'
        },
        {
          text: "CUI",
          value: 'vatId'
        },
        {
          text: "Reg Com",
          value: 'regCom'
        },
        {
          text: "Judet",
          value: 'county'
        },
        {
          text: "Localitate",
          value: 'city'
        },
        {
          text: "Adresa",
          value: 'companyAddress'
        },
        {
          text: "Puncte de lucru",
          value: 'workingPoints'
        },
        {
          text: "Activ",
          value: 'isActive'
        },
        {
          text: "Data creare",
          value: 'createdAt'
        }
      ]
    };
  },
  watch: {
  },
  computed: {
    companies() {
      return this.$store.state.companies.companies
    },
    // workingPoints() {
    //   return this.$store.state.workingPoints.workingPoints
    // },
    totalUsers() {
      return this.$store.state.companies.totalCompanies
    },
    user() {
      return this.$store.state.auth.user
    },
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
    userImp() {
      return this.$store.state.auth.userImp
    },
    canPerformOrganizationChanges() {
      return this.$store.state.auth.canPerformOrganizationChanges
    },

    userDisabled() {
      return this.$store.state.auth.userDisabled
    },
    userFree() {
      return this.$store.state.auth.userFree
    },
    userAdvanced() {
      return this.$store.state.auth.userAdvanced
    },
    totalWorkingPoints() {
      let total = 0
      this.companies.map(c => {
        total += c.workingPoints.filter(wp => !wp.isDeleted).length
      })
      return total
      // return this.workingPoints.length
    },
    
    allowedWorkingPoints() {
      if (this.userImp) {
        return this.userImp.allowedWorkingPoints
      }
      return this.user ? this.user.allowedWorkingPoints : 0
    },
    canAddCompany() {
      if(this.userAdvanced) {
        return this.totalWorkingPoints < this.allowedWorkingPoints
      }
      if(this.userFree) {
        // return this.totalWorkingPoints < 1
        //TRIAL HAS ALL FEATURES ENABLED
        return this.totalWorkingPoints < this.allowedWorkingPoints
      }
      return false
    },
    userName() {
      return this.userImp && this.userImp.name ? this.userImp.name : this.user.name
    }
  },
  methods: {
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    updateCompanyActive(company) {
      this.$store.dispatch("updateCompanyActive", {
        companyId: company._id,
      })
      company.isActive = !company.isActive
    },
    openCompanyDialog() {
      this.createDialog = true
    },
    closeCreateDialog() {
      this.createDialog = false
    },
  },
  created() {
    this.$store.dispatch('fetchCompanies');
  }
};
</script>
