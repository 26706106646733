<template>
    <v-layout row justify="center">
      <v-dialog v-model="dialog" persistent transition="dialog-top-transition" max-width="600">
        <v-card>
          <v-toolbar
            color="accent"
            dark
            style="height: 20px !important"
          />
          <v-card-title>
            <span class="dialogText">
                {{ infoText }}
            </span>
          </v-card-title>
          <v-card-actions class="justify-end">
            <!-- <v-btn
              class="mainButtonNo"
              @click="close()"
            >INCHIDE</v-btn> -->
            <v-btn
              class="mainButtonYes"
              @click="close()"
            >INCHIDE</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </template>
  <script>
  export default {
    props: [
     'infoText',
     'closeDialog'
    ],
    data: () => ({
      dialog: true,
    }),
    methods: {
      close() {
        this.closeDialog()
      }
    },
    computed: {
    },
    created() {
    }
  };
  </script>