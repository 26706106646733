const ListCategories = {
  'CODURI_VALORIFICARE': "Coduri de valorificare",
  'CODURI_ELIMINARE': "Coduri de eliminare",
  'MIJLOC_TRANSPORT': "Mijloc de transport",
  'DESTINATIE': "Destinatie",
  'TIP_PARTENER': "Tip partener",
  'SURSA_DESEU': "Sursa Deseu",
  'MOD_TRATARE': "Mod tratare",
  'SCOP_TRATARE': "Scop tratare",
  'UNITATE_MASURA': "Unitate de masura",
  'TIP_STOCARE': "Tip stocare",
  'STARE_FIZICA': "Stare fizica"
}
export { ListCategories }
