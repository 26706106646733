<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Editeaza utilizatorul {{ editedUser ? editedUser.email : '' }}</span>
        </v-card-title>
        <div v-if="!editedUser._id" class="align-self-center justify-center flex">
          <v-progress-circular :size="70" :width="7" indeterminate color="primary"></v-progress-circular>
        </div>
        <v-card-text v-else>
          <v-card-title>Permisiuni</v-card-title>
          <v-form class="w-full padded p-5" ref="form" v-if="editedUser">
            <v-col class="">
              <div class="align-self-center flex">
                <v-text-field ref='focusMe' type="text" placeholder="Email" v-model="editedUser.email" required
                  label="Email" :rules="[v => !!v || `Completeaza email-ul`]" disabled />
                <v-text-field class="ml-1" ref='focusMe' type="text" placeholder="Nume" v-model="editedUser.name"
                  label="Nume" disabled />
                <v-btn icon @click="addEmptyPermission(editedUser)">
                  <v-icon color="primarystrong">mdi-plus</v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col class=" ">
              <div class="align-self-center flex" v-for="(permission, index) in editedUser.permissions"
                :key="`perm${index}`">
                <div class="w-25 align-self-center">
                  <v-select class="px-2 py-1  text-lighten-1" v-model="permission.role" hide-details :items="roles"
                    :label="'Rolul*'" :placeholder="'Alege rolul'" :rules="[v => !!v || `Alege rolul *`]"></v-select>
                </div>
                <div class="w-75 align-self-center">
                  <v-autocomplete class="px-2 py-1  text-lighten-1 " style="margin-top: 15px"
                    v-model="permission.workingPoints" :label="'Puncte de lucru la care va avea acces *'"
                    :placeholder="'Alege punctele de lucru'" :items="formattedWorkingPoints" item-text="title"
                    item-value="_id" multiple chips
                    :rules="[v => !!v || `Alege cel putin un punct de luceu *`]"></v-autocomplete>
                </div>
                <v-btn variant="plain" icon @click="removePermission(permission, editedUser)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>

              </div>
            </v-col>
            <div class="ml-2" style="color: red">{{ permissionErrorMsg }}</div>
          </v-form>
          <v-card-actions class="cardActions">
            <v-btn type="button" class="mainButtonYes" text @click="save()">Salveaza</v-btn>
          </v-card-actions>

          <v-divider></v-divider>
          <v-card-title>Actiuni</v-card-title>
          <v-card-actions class="flex justify-space-around">
            <div>
              <v-btn type="button" color="red" @click="deleteUser">Sterge utilizator</v-btn>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <span>Aceasta actiune este ireversibila.</span>
              </v-tooltip>
            </div>
            <div v-if="editedUser.isActive && editedUser.createdBy">
              <v-btn type="button" color="primary" @click="switchActive()">Dezactiveaza utilizator</v-btn>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> mdi-help-circle-outline</v-icon>
                </template>
                <span>Utilizatorul nu se va mai putea loga in aplicatie. Aceasta actiune este reversibila.</span>
              </v-tooltip>
            </div>
            <div v-else-if=" editedUser.createdBy">
              <v-btn type="button" color="primary"  @click="switchActive()">Activeaza utilizator</v-btn>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> mdi-help-circle-outline</v-icon>
                </template>
                <span>Utilizatorul se va putea loga din nou in aplicatie. Aceasta actiune este reversibila.</span>
              </v-tooltip>
            </div>
          </v-card-actions>

        </v-card-text>
        <v-divider />
        <v-card-actions class="cardActions">
          <v-btn type="button" class="mainButtonNo" color="red" @click="close()">Inchide</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </v-layout>
</template>
<script>

import axiosInstance from "../../../store/axiosWrapper";
export default {
  props: [
    "closeDialog",
    "createCb",
    "workingPoints",
    "userId"

  ],
  data: () => ({
    dialog: true,
    permissionErrorMsg: '',
    editedUser: {},
    inputRules: [v => !!v || "Campul este obligatoriu"],
    roles: [{
      text: "Administrator",
      value: "MANAGER"
    },
    {
      text: "Introducere date",
      value: "DATA_RECORDER"
    },
    {
      text: "Vizualizare",
      value: "VIEWER"
    }
    ],

  }),
  watch: {
    userId() {
      this.getUser()
    }

  },
  methods: {
    deleteUser() {
      this.$store.dispatch('deleteUser', {
        id: this.editedUser._id, cb: () => {
          this.createCb()
        }
      })
      this.close()
    },
    close() {
      this.closeDialog()
    },

    addEmptyPermission(user) {
      this.permissionErrorMsg = ''
      user.permissions.push({ role: null, workingPoints: [] })
    },

    removePermission(permission, user) {
      user.permissions = user.permissions.filter(p => p != permission)
    },
    getManagersWorkingPoints(userInfo) {

      if (userInfo.permissions && userInfo.role == 'GUEST') {
        let managePermission = userInfo.permissions.find(p => p.role == 'MANAGER')
        if (!managePermission) {
          return []
        }
        return managePermission.workingPoints
      }

      return null
    },

    async getUser() {
      if (this.userId) {
        const res = await axiosInstance.get(
          `${process.env.VUE_APP_API_URL}/pendingUsers/byId/${this.userId}`,
          {
            withCredentials: true,
          },
        );
        console.log(res.data)
        if (res && res.data) {
          this.editedUser = { ...res.data }
        }
      }
    },
    switchActive() {
      this.$store.dispatch('editUser', {
          model: {
            _id: this.editedUser._id,
            isActive: !this.editedUser.isActive
          },

          cb: () => {
            if (this.createCb) {
              this.createCb()
            }
          }
        })
        this.close()
    },

    save() {
      if (!this.editedUser.permissions.length || !this.editedUser.permissions.map(p => p.role).filter(r => r).length) {
        this.permissionErrorMsg = "Utilizatorul trebuie sa aiba cel putin o permisiune. Pentru dezactivarea acestuia, apasa butonul \"Dezactiveaza\""
        return
      }

      this.editedUser.workingPoints = this.editedUser.permissions.map(p => p.workingPoints).reduce((acc, workingPoints) => acc.concat(workingPoints), []);
      if (this.$refs.form.validate()) {
        this.$store.dispatch('editUser', {
          model: {
            _id: this.editedUser._id,
            permissions: this.editedUser.permissions,
            workingPoints: this.editedUser.workingPoints
          },

          cb: () => {
            if (this.createCb) {
              this.createCb()
            }
          }
        }

        )

        this.close()
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    userImp() {
      return this.$store.state.auth.userImp
    },
    formattedWorkingPoints() {
      const allowedWorkingPointIds = this.getManagersWorkingPoints(this.userImp ? this.userImp : this.user)

      const allowedWorkingPoints = !allowedWorkingPointIds ? this.workingPoints :
        this.workingPoints.filter(wp => allowedWorkingPointIds.includes(wp._id))

      return allowedWorkingPoints.map(wp => {
        wp.title = `${wp.company.companyName} \\ ${wp.name}`
        return wp
      })
    }

  },
  async created() {
    // this.addEmptyUser()
    await this.getUser()


  }
};
</script>
