<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline" v-if="!partnerToUpdate">Adauga partener pentru {{ workingPoint ? workingPoint.name : '' }}</span>
          <span class="headline" v-if="partnerToUpdate && !updateCarsAndDrivers">Modifica partener {{ model && model.name ? model.name : '' }}</span>
          <span class="headline" v-if="partnerToUpdate && updateCarsAndDrivers">
            Modifica masini/soferi pentru {{ model && model.name ? model.name : '' }}</span>
        </v-card-title>
        <v-card-text>
          
          <v-form class="w-full padded pt-4" ref="form">
            <!-- <v-container class="mb-3"> -->
              <v-row dense>
                <v-col cols="12">
                  <span >
                    Trebuie sa completati datele de identificare ale societatii care preia deseurile de la dumneavoastra, 
                    firma de salubritate sau colector de deseuri.
                  </span>
                </v-col>
                <v-col cols="12">
                  <v-select v-if="workingPoints && !partnerToUpdate" v-model="model.workingPoint" :items="workingPoints"
                  item-text="displayName" label="Alege punctul de lucru" item-value="_id" return-object :rules="[
          v => !!v || `Alege punctul de lucru`]" />

                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="4">
                  <!-- <div class="padded w-third m-2"> -->
                  <v-text-field type="text" placeholder="Cod fiscal partener *" v-model="model.vatId" required label="Cod fiscal partener *"
                    :disabled="updateCarsAndDrivers || waitForAnaf" @blur="getPartnerDataFromAnaf"
                    :rules="[
                      v => !!v || `Completeaza codul fiscal al partenerului`,
                      v => v && v.length <= 100 || `Codul fiscal trebuie sa contina cel mult 50 de caractere`
                    ]" >
                      <template v-slot:append-outer>

                        <v-tooltip
                          bottom max-width="250px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small>
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          Completati codul fiscal al partenerului care preia deseurile si sistemul va precompleta automat restul datelor.
                        </v-tooltip>
                      </template>

                    </v-text-field>
                <!-- </div> -->
                </v-col>
                <v-col cols="12" md="4">
                  <!-- <div class="padded  w-third m-2"> -->
                  <v-text-field ref='focusMe' type="text" placeholder="Nume partener *" v-model="model.name" required
                    label="Nume partener *" :disabled="updateCarsAndDrivers || waitForAnaf" :rules="[
                      v => !!v || `Completeaza numele partenerului`,
                      v => v && v.length <= 50 || `Numele trebuie sa contina cel mult 50 de caractere`
                    ]" >
                      <template v-slot:append-outer>
                        <v-tooltip
                          bottom max-width="250px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small>
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          Completati numele partenerului care preia deseurile de la punctul de lucru.
                        </v-tooltip>
                      </template>

                    </v-text-field>
                <!-- </div> -->
                </v-col>
                <v-col cols="12" md="4">
                  <!-- <div class="padded w-66 m-2"> -->
                  <v-text-field type="text" placeholder="Adresa partener *" v-model="model.address" required label="Adresa partener *"
                  :disabled="updateCarsAndDrivers || waitForAnaf"
                    :rules="[
                      v => !!v || `Completeaza adresa partenerului`,
                      v => v && v.length <= 100 || `Adresa trebuie sa contina cel mult 50 de caractere`
                    ]" >
                      <template v-slot:append-outer>
                        <v-tooltip
                          bottom max-width="250px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small>
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          Completati adresa partenerului care preia deseurile de la punctul de lucru.
                        </v-tooltip>
                      </template>

                    </v-text-field>
                <!-- </div> -->
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="4">
                  <!-- <div class="padded  w-third  m-2"> -->
                  <v-text-field type="text" placeholder="Oras partener *" v-model="model.city" required label="Oras partener *" 
                    :disabled="updateCarsAndDrivers || waitForAnaf"
                    :rules="[
                      v => !!v || `Completeaza orasul partenerului`,
                      v => v && v.length <= 100 || `Orasul trebuie sa contina cel mult 50 de caractere`
                    ]" >
                      <template v-slot:append-outer>
                        <v-tooltip
                          bottom max-width="250px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small>
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          Completati orasul in care este adresa partenerului care preia deseurile de la punctul de lucru.
                        </v-tooltip>
                      </template>

                    </v-text-field>
                <!-- </div> -->
                </v-col>
                <v-col cols="12" md="4">
                  <!-- <div class="padded w-third m-2"> -->
                  <v-text-field type="text" placeholder="Judet partener *" v-model="model.county" required label="Judet partener *" 
                    :disabled="updateCarsAndDrivers || waitForAnaf"
                    :rules="[
                      v => !!v || `Completeaza judet partener`,
                      v => v && v.length <= 100 || `Judetul trebuie sa contina cel mult 50 de caractere`
                    ]" >
                      <template v-slot:append-outer>
                        <v-tooltip
                          bottom max-width="250px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small>
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          Completati judetul in care este adresa partenerului care preia deseurile de la punctul de lucru.
                        </v-tooltip>
                      </template>

                    </v-text-field>
                <!-- </div> -->
                </v-col>
                <v-col cols="12" md="4">
                  <!-- <div class="padded w-half ma-0 ml-2"> -->
                  <v-text-field type="text" placeholder="Nr Registrul comertului partener" v-model="model.regCom"
                    label="Nr Registrul comertului partener" :disabled="updateCarsAndDrivers || waitForAnaf">
                    <template v-slot:append-outer>
                      <v-tooltip
                        bottom max-width="250px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small>
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        Completati numarul de inregistrare la registrul comertului al partenerului care preia deseurile de la punctul de lucru.
                      </v-tooltip>
                    </template>

                  </v-text-field>
                <!-- </div> -->
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                <!-- <div class="padded w-half ma-0 ml-2"> -->
                  <v-combobox clearable label="Tip partener" v-model="model.types" class="left" density="compact" multiple
                    underlined :items="partnerTypes" item-text="name" item-value="_id"
                    :disabled="updateCarsAndDrivers"
                    color="primary" :rules="[
                      v => (v && v.length ? true : false) || 'Completeaza tipul partenerului',
                    ]">
                    <template v-slot:selection="data">
                      <v-chip :key="data.item._id" v-bind="data.attrs" :input-value="data.selected"
                        :disabled="data.disabled" @click:close="data.parent.selectItem(data.item)">
                        <v-avatar class="accent white--text" left
                          v-text="data.item.name.slice(0, 1).toUpperCase()"></v-avatar>
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:append-outer>
                      <v-tooltip
                        bottom max-width="250px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small>
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <span>
                          <li>Colector daca partenerul vine si ia deseurile de la tine</li>
                          <li>Eliminator daca este firma de salubritate si duce deseurile de groapa de gunoi sau la incinerare</li>
                          <li>Valorificator daca duce deseurile spre reciclare sau recuparere energetica</li>
                          <li>Transportator - daca transporta deseurile de la tine la un eliminator/colector/valorificator inclusiv catre el.</li>
                          <li>Generator daca este societate care genereaza deseuri</li>
                        </span>
                      </v-tooltip>
                    </template>
                  </v-combobox>
                <!-- </div> -->
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="4">
                <!-- <div class="padded w-third m-2"> -->
                  <v-text-field type="text" placeholder="Numar contract" v-model="model.noContract"
                    label="Numar contract" :disabled="updateCarsAndDrivers"/>
                <!-- </div> -->
                </v-col>
                <v-col cols="12" md="4">
                <!-- <div class="padded w-third m-2"> -->
                  <!-- <v-date-picker v-model="model.registerDate"></v-date-picker> -->
                  <v-dialog max-width="300px" persistent v-model="modal1">
                    <template v-slot:activator="{ on: { click } }">
                      <v-text-field v-on:click="click" v-model="model.startDateContract" label="Data start contract"
                        required :disabled="updateCarsAndDrivers"></v-text-field>
                    </template>
                    <v-date-picker v-model="model.startDateContract" scrollable actions>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="modal1 = false">Cancel</v-btn>
                        <v-btn color="primary" @click="modal1 = false">OK</v-btn>
                      </v-card-actions>
                    </v-date-picker>
                  </v-dialog>
                <!-- </div> -->
                </v-col>
                <v-col cols="12" md="4">
                <!-- <div class="padded w-third m-2"> -->
                  <!-- <v-date-picker v-model="model.registerDate"></v-date-picker> -->
                  <v-dialog max-width="300px" persistent v-model="modal2">
                    <template v-slot:activator="{ on: { click } }">
                      <v-text-field v-on:click="click" v-model="model.endDateContract" label="Data stop contract"
                        required :disabled="updateCarsAndDrivers"></v-text-field>
                    </template>
                    <v-date-picker v-model="model.endDateContract" scrollable actions>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="modal2 = false">Cancel</v-btn>
                        <v-btn color="primary" @click="modal2 = false">OK</v-btn>
                      </v-card-actions>
                    </v-date-picker>
                  </v-dialog>
                <!-- </div> -->
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="4">
                <!-- <div class="padded w-third m-2"> -->
                  <v-text-field type="text" placeholder="Numar autorizatie mediu" v-model="model.noAuth"
                    label="Numar autorizatie mediu" :disabled="updateCarsAndDrivers"/>
                <!-- </div> -->
                </v-col>
                <v-col cols="12" md="4">
                <!-- <div class="padded w-third m-2"> -->
                  <!-- <v-date-picker v-model="model.registerDate"></v-date-picker> -->
                  <v-dialog max-width="300px" persistent v-model="modal3">
                    <template v-slot:activator="{ on: { click } }">
                      <v-text-field v-on:click="click" v-model="model.startDateAuth" label="Data start autorizatie"
                        required :disabled="updateCarsAndDrivers"></v-text-field>
                    </template>
                    <v-date-picker v-model="model.startDateAuth" scrollable actions>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="modal3 = false">Cancel</v-btn>
                        <v-btn color="primary" @click="modal3 = false">OK</v-btn>
                      </v-card-actions>
                    </v-date-picker>
                  </v-dialog>
                <!-- </div> -->
                </v-col>
                <v-col cols="12" md="4">
                <!-- <div class="padded w-third m-2"> -->
                  <!-- <v-date-picker v-model="model.registerDate"></v-date-picker> -->
                  <v-dialog max-width="300px" persistent v-model="modal4">
                    <template v-slot:activator="{ on: { click } }">
                      <v-text-field v-on:click="click" v-model="model.endDateAuth" label="Data stop autorizatie"
                        required :disabled="updateCarsAndDrivers"></v-text-field>
                    </template>
                    <v-date-picker v-model="model.endDateAuth" scrollable actions>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="modal4 = false">Cancel</v-btn>
                        <v-btn color="primary" @click="modal4 = false">OK</v-btn>
                      </v-card-actions>
                    </v-date-picker>
                  </v-dialog>
                <!-- </div> -->
                </v-col>
              </v-row>
            <!-- </v-container> -->
            <v-container style="background-color: #EBEBEB;" class="rounded-lg p-3 mb-6 mt-3">
              <v-row no-gutters  >
                <v-col cols="12" class="mb-1">
                  <span class="font-weight-bold">Masini</span>
                </v-col>
                <v-col cols="12" class="mb-4">
                  <v-btn type="button" small class="mainButtonYes" text @click="addCar()">Adauga masina</v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="!partnerCarsList.length">
                <v-col cols="12">
                  <span style="font-size: 12px;color: #686868;" class="pl-3">
                    Nu ai masini adaugate. Apasa Adauga masina pentru a adauga prima masina.
                  </span>
                </v-col>
              </v-row>
              <div v-else class="pa-3 rounded-lg white">
                <v-row dense v-for="(car, index) in partnerCarsList" :key="index" align="baseline" 
                  v-if="index < carsCurrentPage * 3 && index >= (carsCurrentPage * 3) - 3">
                  <v-col cols="12" md="11">
                    <v-row dense>
                      <v-col cols="12" md="4">
                        <v-text-field type="text" placeholder="Numar inmatriculare *" v-model="car.plate" 
                         label="Numar inmatriculare *" :rules="[
                          v => !!v || `Completeaza numarul de inmatriculare`,
                          v => v && v.length <= 50 || `Numele trebuie sa contina cel mult 50 de caractere`
                        ]" />
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field type="text" placeholder="Numar autorizatie transport" v-model="car.transportAuthNo"
                        label="Numar autorizatie transport" />
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field v-on:click="dateIdx = index; modal5 = true" 
                          v-model="car.transportAuthExpDate" label="Data expirare autorizatie transport"
                          ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="1">
                    <v-btn class="mr-1" icon color="primary" @click="openDeleteDialog('CAR', car)">
                      <v-icon color="error">mdi-trash-can</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row dense justify="end" align="center" v-if="carsTotalPages > 1">
                  <span class="pr-2" style="font-size: 12px;">{{ carsCurrentPage + ' / ' + carsTotalPages}}</span>

                  <v-btn class="mr-1" icon @click="carsCurrentPage--" :disabled="carsCurrentPage <= 1">
                    <v-icon color="grey">mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn color="primary" icon @click="carsCurrentPage++" 
                    :disabled="carsCurrentPage >= carsTotalPages">
                    <v-icon color="grey">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </div>
            </v-container>
            <v-container style="background-color: #EBEBEB;" class="rounded-lg p-3">
              <v-row no-gutters  >
                <v-col cols="12" class="mb-1">
                  <span class="font-weight-bold">Soferi</span>
                </v-col>
                <v-col cols="12" class="mb-4">
                  <v-btn type="button" small class="mainButtonYes" text @click="addDriver()">Adauga sofer</v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="!partnerDriversList.length">
                <v-col cols="12" >
                  <span style="font-size: 12px;color: #686868;">
                    Nu ai soferi adaugati. Apasa Adauga sofer pentru a adauga primul sofer.
                  </span>
                </v-col>
              </v-row>
              <div v-else class="pa-3 rounded-lg white">
                <v-row dense align="baseline" v-for="(driver, idx) in partnerDriversList" :key="idx" 
                  v-if="idx < driversCurrentPage * 3 && idx >= (driversCurrentPage * 3) - 3">
                  <v-col cols="12" md="11">
                    <v-row dense align="baseline">
                      <v-col cols="12" md="4">
                        <v-text-field type="text" placeholder="Nume sofer *" v-model="driver.name"
                          label="Nume sofer *" :rules="[
                          v => !!v || `Completeaza numele soferului`,
                          v => v && v.length <= 50 || `Numele trebuie sa contina cel mult 50 de caractere`
                          ]" />
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field type="text" placeholder="Serie si numar CI" v-model="driver.driverCI"
                          label="Serie si numar CI" />
                      </v-col>
                      
                      <v-col cols="12" md="4">
                        <v-btn class="mr-1" icon color="primary" @click="openDeleteDialog('DRIVER', driver)">
                          <v-icon color="error">mdi-trash-can</v-icon>
                        </v-btn>
                        
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>
                <v-row dense justify="end" align="center" v-if="driversTotalPages > 1">
                  <span class="pr-2" style="font-size: 12px;">{{ driversCurrentPage + ' / ' + driversTotalPages}}</span>
                  <v-btn class="mr-1" icon @click="driversCurrentPage--"
                    :disabled="driversCurrentPage <= 1" >
                    <v-icon color="grey">mdi-chevron-left</v-icon>
                  </v-btn>
                  
                  <v-btn icon @click="driversCurrentPage++"
                    :disabled="driversCurrentPage >= driversTotalPages" >
                    <v-icon color="grey">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-row>
              </div>
              
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="cardActions">
          <v-btn type="button" class="mainButtonYes" text @click="save()">Salveaza</v-btn>
          <v-btn type="button" class="mainButtonNo" color="red" @click="close()">Renunta</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DeleteDialog 
      v-if="deleteDialog" 
      :closeDialog="closeDeleteDialog" 
      :deleteAction="deleteItem" 
      :itemName="currentTypeToDelete === 'CAR' ? 'masina' : 'soferul'" 
      :item="currentItemToDelete"
    />
    <v-dialog max-width="300px" persistent v-model="modal5">
      <v-date-picker v-model="date" scrollable actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="modal5 = false; dateIdx = null; date = ''">Cancel</v-btn>
          <v-btn color="primary" 
            @click="partnerCarsList[dateIdx].transportAuthExpDate = date; modal5 = false; dateIdx = null; date = ''">OK</v-btn>
        </v-card-actions>
      </v-date-picker>
    </v-dialog>
  </v-layout>
</template>
<script>
import moment from "moment-timezone";
import axiosInstance from "../../../store/axiosWrapper";
import {
ListCategories
} from '../../../common/ListCategories';
import DeleteDialog from '../DeleteDialog.vue';
export default {
  components: {
    DeleteDialog
  },
  props: [
    "closeDialog",
    "workingPoint",
    "wasteCodePWP",
    "forField",
    "createCb",
    "workingPoints",
    "partnerToUpdate",
    "partnerField",
    "updateCarsAndDrivers"
  ],
  data: () => ({
    dialog: true,
    model: {},
    modal1: false,
    modal2: false,
    modal3: false,
    modal4: false,
    modal5: false,
    menu: false,
    waitForAnaf: false,
    date: '',
    dateIdx: null,
    deleteDialog: false,
    partnerDriversList: [],
    deletedDrivers: [],
    partnerCarsList: [],
    deletedCars: [],
    driversCurrentPage: 1,
    carsCurrentPage: 1,
    currentItemToDelete: null,
    currentTypeToDelete: '',
    carFeId: 0,
    driverFeId: 0
  }),
  watch: {
    workingPoint() {
      if (this.workingPoint) {
        this.model.workingPoint = this.workingPoint
      }
    },
    wasteCodePWP() {
      if (this.wasteCodePWP) {
        this.model.wasteCodePerWorkingPoint = this.wasteCodePWP
      }
    }
  },
  methods: {
    close() {
      this.closeDialog()
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.model._id) {
          let cars = [...this.partnerCarsList, ...this.deletedCars].reverse();
          let drivers = [...this.partnerDriversList, ...this.deletedDrivers].reverse();
          this.$store.dispatch("updatePartner", {
            id: this.model._id,
            model: this.model,
            cars: cars,
            drivers: drivers,
            addToWC: this.forField ? this.forField : '',
            wasteCodePerWorkingPoint: this.wasteCodePWP ? this.wasteCodePWP : null,
            cb: this.updateCarsAndDrivers 
              ? () => { this.createCb(this.partnerField) }
              : () => { }
          })
        } else {
          let cars = [...this.partnerCarsList].reverse();
          let drivers = [...this.partnerDriversList].reverse();
          this.$store.dispatch('createPartner', {
            model: this.model,
            cars: cars,
            drivers: drivers,
            addToWC: this.forField,
            cb: () => {
              this.createCb(this.partnerField)
            }
          });
        }
        this.close()
      }
    },
    parseDate(date) {
      if (!date) {
        return
      }
      return moment(date).format("YYYY-MM-DD");
    },
    addDriver() {
      if(this.partnerDriversList[0] && this.partnerDriversList[0].name == '') {
        return
      }
      this.driverFeId++
      this.partnerDriversList.splice(0, 0, {
        feId: this.driverFeId,
        name: '',
      })
    },
    addCar() {
      if(this.partnerCarsList[0] && this.partnerCarsList[0].plate === '') {
        return
      }
      this.carFeId++
      this.partnerCarsList.splice(0, 0, {
        feId: this.carFeId,
        plate: '',
      })
    },
    deleteItem() {
      if(this.currentTypeToDelete === 'CAR') {

        let idx = this.partnerCarsList.findIndex((e) => {
          return !this.currentItemToDelete._id 
            ? e.feId === this.currentItemToDelete.feId
            : e._id === this.currentItemToDelete._id
        })
        if(idx > -1) {
          if(this.currentItemToDelete._id) {
            this.partnerCarsList[idx].isDeleted = true;
            this.deletedCars.push(this.partnerCarsList[idx])
          }
          this.partnerCarsList.splice(idx, 1)
        }

      } else if(this.currentTypeToDelete === 'DRIVER') {
        let idx = this.partnerDriversList.findIndex((e) => {
          return !this.currentItemToDelete._id 
            ? e.feId === this.currentItemToDelete.feId
            : e._id === this.currentItemToDelete._id
        })
        if(idx > -1) {
          if(this.currentItemToDelete._id) {
            this.partnerDriversList[idx].isDeleted = true;
            this.deletedDrivers.push(this.partnerDriversList[idx])
          }
          this.partnerDriversList.splice(idx, 1)
        }
      }
      this.currentItemToDelete = null
      this.currentTypeToDelete = ''
      this.deleteDialog = false
    },
    closeDeleteDialog() {
      this.currentItemToDelete = null
      this.currentTypeToDelete = ''
      this.deleteDialog = false
    },
    openDeleteDialog(type, item) {
      this.currentItemToDelete = item
      this.currentTypeToDelete = type
      this.deleteDialog = true
    },
    async getPartnerDataFromAnaf() {
      if(this.model.vatId && !this.partnerToUpdate) {
        if(!this.model.name && !this.model.address && !this.model.regCom && !this.model.city && !this.model.county) {

          this.waitForAnaf = true;
          let vatId = this.model.vatId.replaceAll(' ', '').replace('RO', '').trim()
          const currentDate = moment().tz('Europe/Bucharest').format('YYYY-MM-DD')
          try {
            const res = await axiosInstance.get(
              `${process.env.VUE_APP_API_URL}/partners/dataFromAnaf/${vatId}/${currentDate}`,
              {
                withCredentials: true,
              },
            );
            if(res && res.data && res.data.name) {
              this.$set(this.model, 'name', res.data.name);
              if(res.data.regCom) {
                this.$set(this.model, 'regCom', res.data.regCom);
              }
              this.$set(this.model, 'address', res.data.address);
              this.$set(this.model, 'city', res.data.city);
              this.$set(this.model, 'county', res.data.county);
            }
            this.waitForAnaf = false;
          } catch(e) {
            this.waitForAnaf = false;
            return
          }
        }
      }
    }
  },
  computed: {
    ListCategories() {
      return ListCategories
    },
    lists() {
      return this.$store.state.wasteCodes.lists
    },
    partnerTypes() {
      return this.lists[ListCategories.TIP_PARTENER]
    },
    driversTotalPages() {
      if(this.partnerDriversList.length) {
        return Math.ceil(this.partnerDriversList.length / 3);
      }

      return 0
    },
    carsTotalPages() {
      if(this.partnerCarsList.length) {
        return Math.ceil(this.partnerCarsList.length / 3);
      }
      return 0
    }
  },
  created() {
    if (this.workingPoints && !this.partnerToUpdate && !this.workingPoint) {
      this.workingPoint = this.workingPoints.find(wp => wp.isDefault)
    }
    if(!this.updateCarsAndDrivers) {
      this.model.workingPoint = this.workingPoint
    }
    this.model.wasteCodePerWorkingPoint = this.wasteCodePWP
    if (this.partnerToUpdate) {
      this.model = {
        ...this.partnerToUpdate
      }
      this.partnerDriversList = [...this.model.partnerDrivers];
      this.partnerDriversList.reverse();
      this.partnerCarsList = [...this.model.partnerCars];
      this.partnerCarsList.reverse();
    }
    if(!this.partnerToUpdate) {
      if (this.forField == 'TRANSPORTATOR' || this.partnerField == 'partnerTransportator') {
        this.model.types = this.partnerTypes.filter(p => p.code == 'Transportator')
      } else {
        this.model.types = this.partnerTypes.filter(p => 
          p.code != 'Valorificator')
      }
    }
    this.model.startDateContract = this.parseDate(this.model.startDateContract)
    this.model.endDateContract = this.parseDate(this.model.endDateContract)
    this.model.startDateAuth = this.parseDate(this.model.startDateAuth)
    this.model.endDateAuth = this.parseDate(this.model.endDateAuth)

    this.partnerCarsList.forEach(e => {
      if(e.transportAuthExpDate) {
        e.transportAuthExpDate = this.parseDate(e.transportAuthExpDate);
      }
    })
  }
};
</script>
<style scoped>
@media only screen and (max-width: 600px) {
  .w-half,
  .w-third,
  .w-quarter,
  .w-75 {
    width: 100% !important
  }
  .noDataButton {
    display: grid;
    font-size: 12px;
  }
}
.w-third {
  width: 31%
}
.w-quarter {
  width: 23%;
}
.w-half {
  width: 50%;
}
.w-75 {
  width: 73%;
}
.w-66 {
  width: 65%;
}
/* .v-form .v-input__append-outer .v-icon {
  font-size: medium;
}
.v-form .v-input__prepend-inner .v-icon {
  font-size: medium;
} */
</style>
