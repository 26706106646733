<template>
  <auth-layout>
    <WasteCodesPerWorkingPointLayout />
  </auth-layout>
</template>
<script>
import AuthLayout from '../Layouts/AuthLayout.vue';
import WasteCodesPerWorkingPointLayout from '../Layouts/WasteCodesPerWorkingPointLayout.vue';
export default {
  data() {
    return {};
  },
  components: {
    AuthLayout,
    WasteCodesPerWorkingPointLayout
  },
  computed: {
    
  },
  watch: {
  },
  methods: {},
  created() {
  }
};
</script>