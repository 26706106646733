<template>
<v-layout row justify="center">
  <v-dialog v-model="dialog" fullscreen @keydown.esc="checkStocksAndClose()">
    <v-card>
      <v-card-title>
        <span class="headline">Coduri deseu {{workingPoint.company.companyName}} / {{workingPoint.name}}</span>
      </v-card-title>
      <v-card-text class="buttonRow">
        <AddButton v-if="canAddCode && canEdit" :onClick="() => {addWasteCode()}" caption="Adauga cod de deseu" />
        <v-data-table class="elevation-0 " :headers="wasteCodeHeaders" :items="wasteCodesPerWorkingPoint" v-if="!userDisabled && canEdit">
          <template v-slot:[`item.actions`]="props">
            <p class="tableData">
              <div class="flex" v-if="props.item.canBeDeleted">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="deleteWCPWP(props.item)" icon color="red" v-bind="attrs" v-on="on">
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  <span>Sterge</span>
                </v-tooltip>
              </div>
            </p>
          </template>
          <template v-slot:[`item.codValorificare.code`]="props">
            <p class="tableData">
              <div class="flex">
                <v-select clearable v-model="props.item.codValorificare" :items="listsByCategory(ListCategories.CODURI_VALORIFICARE)" item-text="code" item-value="_id" return-object @input="updateWasteCode(props.item)">
                  <template slot="item" slot-scope="{ item }">
                    <p>
                      <b> {{ item.code }}</b> {{ `(${item.name})` }}
                    </p>
                  </template>
                </v-select>
              </div>
            </p>
          </template>
          <template v-slot:[`item.wasteCode.code`]="props">
            <p v-if="props.item.wasteCode" style="width: 100px">
              {{props.item.wasteCode.code}}
            </p>
            <p v-else class="tableData flex" style="width: 150px">
              <v-autocomplete class="ml-0 mr-0" v-model="props.item.wasteCode" label="Cauta coduri de deseu" hide-selected text-color="white" :items="getAvailableWasteCodes()" item-text="code" item-value="_id" item @change="addNewWasteCode(props.item.wasteCode)" :search-input.sync="searchWasteCodes" no-filter return-object>
                <template slot="item" slot-scope="{ item }">
                  <p>
                    <b> {{ item.code }}</b> {{ `(${item.name})` }}
                  </p>
                </template>
              </v-autocomplete>
            </p>
          </template>
          <template v-slot:[`item.codEliminare.code`]="props">
            <p class="tableData">
              <div class="flex">
                <v-select clearable v-model="props.item.codEliminare" :items="listsByCategory(ListCategories.CODURI_ELIMINARE)" item-text="code" item-value="_id" return-object @change="updateWasteCode(props.item)">
                  <template slot="item" slot-scope="{ item, tile }">
                    <p>
                      <b> {{ item.code }}</b> {{ `(${item.name})` }}
                    </p>
                  </template>
                </v-select>
              </div>
            </p>
          </template>
          <template v-slot:[`item.initialStock`]="props">
            <td class="tableData">
              <div class="flex">
                <div class="stockWidth">
                  <v-text-field type="number"  v-model="props.item.initialStock" :rules="[
                        v => !v || Number(v) >= 0 || `Stocul trebuie sa fie pozitiv`,
                        v => !v || /^[0-9]\d{0,9}(?:.\d{1,2})?$/.test(v) || `Numarul trebuie sa aiba cel mult 2 zecimale`
                        ]" />
                </div>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="buttonAligned" @click="updateWasteCode(props.item)" icon color="accent" v-bind="attrs" v-on="on">
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                  <span>Salveaza</span>
                </v-tooltip>
              </div>
            </td>
          </template>
          <template v-slot:[`item.alias`]="props">
            <td class="tableData">
              <div class="flex">
                <div class="aliasWidth">
                  <v-text-field type="text"  v-model="props.item.alias"  :rules="[
                      // v => !!v || `Completeaza Judetul`,
                      v =>!v || ( v && v.length <= 15) || `Aliasul trebuie sa contina cel mult 15 de caractere`
                    ]"  />
                </div>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="buttonAligned" @click="updateWasteCode(props.item)" icon color="accent" v-bind="attrs" v-on="on">
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                  <span>Salveaza</span>
                </v-tooltip>
              </div>
            </td>
          </template>
          <template v-slot:[`item.unitMeasure.code`]="props">
            <p class="tableData">
              <div class="flex">
                <v-select v-model="props.item.unitMeasure" :items="listsByCategory(ListCategories.UNITATE_MASURA)" item-text="code" item-value="_id" return-object @change="updateWasteCode(props.item)">
                  <template slot="item" slot-scope="{ item }">
                    <p>
                      <b> {{ item.code }}</b> {{ `(${item.name})` }}
                    </p>
                  </template>
                </v-select>
              </div>
            </p>
          </template>
          <template v-slot:[`item.evalType`]="props">
            <p class="tableData">
              <div class="flex">
                <v-select v-model="props.item.evalType" :items="evalTypes" item-text="code" item-value="_id" @change="updateWasteCode(props.item)">
                  <template slot="item" slot-scope="{ item }">
                    <p>
                      <b> {{ item.code }}</b> {{ `(${item.name})` }}
                    </p>
                  </template>
                </v-select>
              </div>
            </p>
          </template>
          <template v-slot:[`item.stareFizica.code`]="props">
            <p class="tableData">
              <div class="flex">
                <v-select v-model="props.item.stareFizica" :items="listsByCategory(ListCategories.STARE_FIZICA)" item-text="code" item-value="_id" return-object @change="updateWasteCode(props.item)">
                  <template slot="item" slot-scope="{ item }">
                    <p>
                      <b> {{ item.code }}</b>
                    </p>
                  </template>
                </v-select>
              </div>
            </p>
          </template>
          <template v-slot:[`item.stockingType.code`]="props">
            <p class="tableData">
              <div class="flex">
                <v-select v-model="props.item.stockingType" :items="listsByCategory(ListCategories.TIP_STOCARE)" item-text="code" item-value="_id" return-object @change="updateWasteCode(props.item)">
                  <template slot="item" slot-scope="{ item }">
                    <p>
                      <b> {{ item.code }}</b> {{ `(${item.name})` }}
                    </p>
                  </template>
                </v-select>
              </div>
            </p>
          </template>
          <template v-slot:[`item.destination.code`]="props">
            <p class="tableData">
              <div class="flex">
                <v-select v-model="props.item.destination" :items="listsByCategory(ListCategories.DESTINATIE)" item-text="code" item-value="_id" return-object @change="updateWasteCode(props.item)">
                  <template slot="item" slot-scope="{ item }">
                    <p>
                      <b> {{ item.code }}</b> {{ `(${item.name})` }}
                    </p>
                  </template>
                </v-select>
              </div>
            </p>
          </template>
          <template v-slot:[`item.carType.code`]="props">
            <p class="tableData">
              <div class="flex">
                <v-select v-model="props.item.carType" :items="listsByCategory(ListCategories.MIJLOC_TRANSPORT)" item-text="code" item-value="_id" return-object @change="updateWasteCode(props.item)">
                  <template slot="item" slot-scope="{ item }">
                    <p>
                      <b> {{ item.code }}</b> {{ `(${item.name})` }}
                    </p>
                  </template>
                </v-select>
              </div>
            </p>
          </template>
          <template v-slot:[`item.partnerDestination.name`]="props">
            <p class="tableData">
              <div class="flex">
                <v-select v-model="props.item.partnerDestination" :items="partnersByWorkingPoint(props.item.partnerDestination)" item-text="name" item-value="_id" @change="updateWasteCode(props.item, true)">
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title class="noDataButton">
                        Nu ai niciun partener adaugat pentru acest punct de lucru.
                        <AddButton :onClick="() => {openPartnerDialog(props.item, 'DESTINATAR')}" caption="Adauga partener" />
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:append-item v-if="partnersByWorkingPoint(props.item.partnerDestination).length">
                    <AddButton :onClick="() => {openPartnerDialog(props.item, 'DESTINATAR')}" caption="Adauga partener nou" />
                  </template>
                </v-select>
              </div>
            </p>
          </template>
          <template v-slot:[`item.partnerTransportator.name`]="props">
            <p class="tableData">
              <div class="flex">
                <v-select v-model="props.item.partnerTransportator" :items="partnersByWorkingPoint(props.item.partnerTransportator, true)" item-text="name" item-value="_id" @change="updateWasteCode(props.item, true)">
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title class="noDataButton">
                        Nu ai niciun partener transportator adaugat pentru acest punct de lucru.
                        <AddButton :onClick="() => {openPartnerDialog(props.item, 'TRANSPORTATOR')}" caption="Adauga partener transportator" />
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:append-item v-if="partnersByWorkingPoint(props.item.partnerTransportator, true).length">
                    <AddButton :onClick="() => {openPartnerDialog(props.item, 'TRANSPORTATOR')}" caption="Adauga partener transportator nou" />
                  </template>
                </v-select>
              </div>
            </p>
          </template>
          <template v-slot:[`item.partnerCar.plate`]="props">
            <p class="tableData">
              <div class="flex">
                <v-select :disabled="!props.item.partnerTransportator" v-model="props.item.partnerCar" :items="partnerById(props.item.partnerTransportator) ? partnerById(props.item.partnerTransportator).partnerCars : []" item-text="plate" item-value="_id" return-object @change="updateWasteCode(props.item)">
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title class="noDataButton">
                        Nu ai nicio masina adaugata pentru acest partener.
                        <AddButton :onClick="() => {openPartnerDialogForUpdate(props.item)}" caption="Adauga masina" />
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:append-item v-if="partnerById(props.item.partnerTransportator) && partnerById(props.item.partnerTransportator).partnerCars.length">
                    <AddButton :onClick="() => {openPartnerDialogForUpdate(props.item)}" caption="Adauga masina noua" />
                  </template>
                </v-select>
              </div>
            </p>
          </template>
          <template v-slot:[`item.partnerDriver.name`]="props">
            <p class="tableData">
              <div class="flex">
                <v-select :disabled="!props.item.partnerTransportator" v-model="props.item.partnerDriver" :items="partnerById(props.item.partnerTransportator) ? partnerById(props.item.partnerTransportator).partnerDrivers : []" item-text="name" item-value="_id" return-object @change="updateWasteCode(props.item)">
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title class="noDataButton">
                        Nu ai niciun sofer adaugat pentru acest partener.
                        <AddButton :onClick="() => {openPartnerDialogForUpdate(props.item)}" caption="Adauga sofer" />
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:append-item v-if="partnerById(props.item.partnerTransportator) && partnerById(props.item.partnerTransportator).partnerDrivers.length">
                    <AddButton :onClick="() => {openPartnerDialogForUpdate(props.item)}" caption="Adauga sofer nou" />
                  </template>
                </v-select>
              </div>
            </p>
          </template>
          <template v-slot:[`item.isActive`]="props">
            <p class="tableData">
              <div class="flex">
                <v-checkbox class="" style="align-items: center;" :input-value="props.item.isActive" @click="updateWasteCodeActive(props.item)"></v-checkbox>
              </div>
            </p>
          </template>
        </v-data-table>
        <v-data-table class="elevation-0" :headers="wasteCodeHeaders" :items="wasteCodesPerWorkingPoint" v-else>
          <template v-slot:[`item.isActive`]="props">
            <p class="tableData">
              <div class="flex">
                <v-checkbox class="" style="align-items: center;" :input-value="props.item.isActive" disabled></v-checkbox>
              </div>
            </p>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="cardActions">
        <v-btn type="button" class="mainButtonNo" color="red" @click="checkStocksAndClose()">Inchide</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <CreatePartner v-if="partnerDialog" :forField="fieldToAddPartner" :closeDialog="closePartnerDialog" 
    :workingPoint="workingPoint" :wasteCodePWP="currentWasteCodePWP" :createCb="refreshData" :partnerToUpdate="partnerToUpdate"
    :updateCarsAndDrivers="updateCarsAndDrivers"/>
  <!-- <CreateCar v-if="carDialog" :closeDialog="closeCarDialog" :partner="currentPartner" :carTypes="listsByCategory(ListCategories.MIJLOC_TRANSPORT)" :wasteCodePWP="currentWasteCodePWP" :createCb="refreshData" />
  <CreateDriver v-if="driverDialog" :closeDialog="closeDriverDialog" :partner="currentPartner" :wasteCodePWP="currentWasteCodePWP" :createCb="refreshData" /> -->
  <InitialStockDialog v-if="initialStockDialog" :onClose="closeInitialStockDialog" :onConfirm="confirmInitialStock" :itemsChanged="itemsChanged"/>
</v-layout>
</template>
<script>
import moment from "moment-timezone";
import {
  ListCategories
} from '../../common/ListCategories';
import AddButton from '../Buttons/AddButton.vue';
import SectionHeadline from '../Typography/SectionHeadline.vue';
import SubHeader from '../Typography/SubHeader.vue';
// import CreateCar from './Create/CreateCar.vue';
// import CreateDriver from './Create/CreateDriver.vue';
import CreatePartner from './Create/CreatePartner.vue';
import CreateWorkingPoint from './Create/CreateWorkingPoint.vue';
import InitialStockDialog from './InitialStockDialog.vue';
import UpdateWorkingPoint from './UpdateWorkingPoint.vue';
export default {
  components: {
    CreateWorkingPoint,
    SectionHeadline,
    SubHeader,
    CreatePartner,
    // CreateCar,
    // CreateDriver,
    AddButton,
    UpdateWorkingPoint,
    InitialStockDialog
  },
  props: [
    "closeDialog",
    "workingPoint"
  ],
  data() {
    return {
      dialog: true,
      itemsChanged: '',
      initialStockDialog: false,
      wcToUpdate: [],
      deleteDialog: false,
      createDialog: false,
      partnerDialog: false,
      partnerToUpdate: null,
      updateCarsAndDrivers: false,
      // carDialog: false,
      // driverDialog: false,
      // currentPartner: null,
      // editDialog: false,
      currentWorkingPoint: null,
      search: '',
      options: {},
      expanded: [],
      searchWasteCodes: '',
      currentWasteCodePWP: null,
      fieldToAddPartner: null,
      updateDialog: false,
      wasteCodeHeaders: [{
          text: 'Actiuni',
          value: 'actions'
        }, {
          text: 'Cod deseu',
          value: 'wasteCode.code'
        },
        {
          text: 'Nume deseu',
          value: 'wasteCode.name'
        },
        {
          text: 'Alias',
          value: 'alias'
        },
        {
          text: 'Cod valorificare',
          value: 'codValorificare.code'
        },
        {
          text: 'Cod eliminare',
          value: 'codEliminare.code'
        },
        {
          text: 'Stoc initial',
          value: 'initialStock'
        },
        {
          text: 'Unitate de masura',
          value: 'unitMeasure.code'
        },
        {
          text: 'Evaluare cantitate',
          value: 'evalType'
        },
        {
          text: 'Stare fizica',
          value: 'stareFizica.code'
        },
        {
          text: 'Tip stocare',
          value: 'stockingType.code'
        },
        {
          text: 'Destinatie',
          value: 'destination.code'
        },
        {
          text: 'Tip transport',
          value: 'carType.code'
        },
        {
          text: 'Destinatar',
          value: 'partnerDestination.name'
        },
        {
          text: 'Transportator',
          value: 'partnerTransportator.name'
        },
        {
          text: 'Mijloc de transport',
          value: 'partnerCar.plate'
        },
        {
          text: 'Sofer',
          value: 'partnerDriver.name'
        },
        {
          text: 'Activ',
          value: 'isActive'
        },
      ],
      evalTypes: [
        {
          _id: 'A', code: 'A', name: 'Cantarita'
        },
        {
          _id: 'B', code: 'B', name: 'Estimata'
        }
      ]
    };
  },
  watch: {
    searchWasteCodes(newVal) {
      this.timeout = setTimeout(() => {
        this.findWasteCodes()
      }, 500);
    },
  },
  computed: {
    workingPointFormOpen() {
      return this.$route.params && this.$route.params['formOpened'] == 'addWorkingPoint'
    },
    maxRolePerWorkingPoint() {
      return this.$store.state.auth.maxRolePerWorkingPoint
    },
    canEdit() {
      if(!this.user.permissions || !this.user.permissions.length) {
        return true
      }
      return this.user.permissions.some(permission => {
        return permission.workingPoints.includes(this.workingPoint._id) &&
          permission.role == 'MANAGER'
      })
    },
    ListCategories() {
      return ListCategories
    },
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
    user() {
      return this.$store.state.auth.user
    },
    userDisabled() {
      return this.$store.state.auth.userDisabled
    },
    userFree() {
      return this.$store.state.auth.userFree
    },
    userAdvanced() {
      return this.$store.state.auth.userAdvanced
    },
    canAddCode() {
      if (this.userAdvanced) {
        return true
      }
      if (this.userFree) {
        // return !this.wasteCodesPerWorkingPoint.length
        //TRIAL HAS ALL FEATURES ENABLED
        return true
      }
      return false
    },
    companies() {
      return this.workingPointsData
    },
    workingPointsData() {
      return this.$store.state.workingPoints.workingPointsData
    },
    lists() {
      return this.$store.state.wasteCodes.lists
    },
    wasteCodes() {
      return this.$store.state.wasteCodes.wasteCodes
    },
    partners() {
      return this.$store.state.partners.partnersForWorkingPoint
    },
    wasteCodesPerWorkingPoint() {
      return this.$store.state.wasteCodesPerWorkingPoint.wasteCodesPerWorkingPoint
    },
    initialStocks() {
      return this.$store.state.wasteCodesPerWorkingPoint.initialStocks
    },
    initialAliases() {
      return this.$store.state.wasteCodesPerWorkingPoint.initialAliases
    },
  },
  methods: {
    checkStocksAndClose() {
      let wcToUpdate = []
      let stockErrors = false
      let aliasErrors = false
      let stocksChanged = false
      let aliasesChanged = false
      this.wasteCodesPerWorkingPoint.map(wcPWP => {
        if(!wcPWP._id) {
          return
        }
        let initialStock = this.initialStocks[wcPWP._id]
        let initialAlias = this.initialAliases[wcPWP._id]
        if(!(Number(wcPWP.initialStock) >= 0 || !wcPWP.initialStock)) {
          stockErrors = true
        }
        if(wcPWP.alias && wcPWP.alias.length > 15) {
          aliasErrors = true
        }

        if((initialStock != wcPWP.initialStock )|| (initialAlias != wcPWP.alias)) {
          wcToUpdate.push(wcPWP)
        }
        if(initialStock != wcPWP.initialStock) {
          stocksChanged = true
        } 
        if(initialAlias != wcPWP.alias) {
          aliasesChanged = true
        }
      })

      if(stockErrors || aliasErrors) {
        return
      }
      if(!wcToUpdate.length) {
        this.closeDialog()
        return
      }
      this.initialStockDialog = true
      this.itemsChanged = aliasesChanged && stocksChanged ? "stocurile initiale si aliasurile" :
        (aliasesChanged ? "aliasurile" : 'stocurile initiale')
      this.wcToUpdate = wcToUpdate
    },
    closeInitialStockDialog() {
      this.initialStockDialog = false
      this.closeDialog()
    },
    confirmInitialStock() {
      this.wcToUpdate.map(wc => {
        this.updateWasteCode(wc)
      })
      this.wcToUpdate = []
      this.closeInitialStockDialog()
    },
    partnerById(partner) {
      if (!partner) {
        return
      }
      if (partner._id) {
        partner = partner._id
      }
      return this.partners.find(p => {
        return p._id == partner
      })
    },
    updateWorkingPointActive(workingPoint) {
      this.$store.dispatch("updateWorkingPointActive", {
        workingPointId: workingPoint._id,
      })
      workingPoint.isActive = !workingPoint.isActive
    },
    addNewWasteCode(wasteCode) {
      this.wasteCodesPerWorkingPoint = this.wasteCodesPerWorkingPoint.filter(wc => wc.wasteCode && wc.wasteCode.code != wasteCode.code)
      this.$store.dispatch("createWasteCodePerWorkingPoint", {
        workingPointId: this.workingPoint._id,
        wasteCode: wasteCode,
        cb: () => {
        
        }
      })
    },
    updateWasteCodeActive(wasteCodePWP) {
      this.$store.dispatch("updateWasteCodeActive", {
        id: wasteCodePWP._id,
      })
      wasteCodePWP.isActive = !wasteCodePWP.isActive
    },
    findWasteCodes() {
      this.$store.dispatch("fetchWasteCodes", {
        search: this.searchWasteCodes
      })
    },
    getAvailableWasteCodes() {
      let takenWasteCodes = this.wasteCodesPerWorkingPoint.map(w => w.wasteCode ? w.wasteCode._id : null).filter(a => a)
      return this.wasteCodes.filter(wc => !takenWasteCodes.includes(wc._id))
    },
    addWasteCode() {
      let alreadyAddedWasteCode = this.wasteCodesPerWorkingPoint.find(w => !w.wasteCode)
      if (!alreadyAddedWasteCode) {
        this.$store.dispatch("addEmptyWasteCode")
        // this.wasteCodesPerWorkingPoint.unshift({})
      }
    },
    updateWasteCode(item, updatingPartner) {
      if (updatingPartner) {
        item.partnerCar = null
        item.partnerDriver = null
      }
      this.$store.dispatch("updateWasteCodePerWorkingPoint", {
        id: item._id,
        model: item,
        cb: () => {
          // this.$store.dispatch('fetchWorkingPointsData', {});
        }
      })
    },
    listsByCategory(category) {
      return this.lists[category]
    },
    partnersByWorkingPoint(addedPartner, onlyTransportator) {
      let partners = []
      if (!addedPartner) {
        partners = this.partners.filter(p => p.isActive)
      } else {
        partners = this.partners.filter(p => (p.isActive || p._id == addedPartner._id))
      }
      if(onlyTransportator) {
        partners = partners.filter(p => {
          return p.types.map(t => t.code).includes('Transportator')
        })
      } else {
        // sa aiba cel putin un tip care sa nu fie transp
        partners = partners.filter(p => {
          return p.types.map(t => t.code).find(c => c != 'Transportator')
        })
      }
      return partners
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    openPartnerDialog(wasteCodePWP, fieldToAddPartner) {
      // this.currentWorkingPoint = workingPoint
      this.currentWasteCodePWP = wasteCodePWP
      this.fieldToAddPartner = fieldToAddPartner
      this.partnerDialog = true
    },
    closePartnerDialog() {
      this.partnerDialog = false
      this.fieldToAddPartner = null
      this.partnerToUpdate = null
      this.updateCarsAndDrivers = false
    },
    openPartnerDialogForUpdate(wasteCodePWP) {
      this.currentWasteCodePWP = wasteCodePWP
      this.partnerToUpdate = this.partnerById(wasteCodePWP.partnerTransportator)
      this.fieldToAddPartner = 'TRANSPORTATOR'
      this.updateCarsAndDrivers = true
      this.partnerDialog = true
    },
    // openCarDialog(wasteCodePWP) {
    //   this.currentPartner = wasteCodePWP.partnerTransportator
    //   this.currentWasteCodePWP = wasteCodePWP
    //   this.carDialog = true
    // },
    // closeCarDialog() {
    //   this.carDialog = false
    // },
    // openDriverDialog(wasteCodePWP) {
    //   this.currentPartner = wasteCodePWP.partnerTransportator
    //   this.currentWasteCodePWP = wasteCodePWP
    //   this.driverDialog = true
    // },
    // closeDriverDialog() {
    //   this.driverDialog = false
    // },
    closeCreateDialog() {
      this.createDialog = false
      if (this.workingPointFormOpen) {
        this.$router.push('/')
      }
    },
   
    refreshData() {
      this.$store.dispatch('fetchPartnersForWorkingPoint', {
        workingPointId: this.workingPoint._id
      });
      this.$store.dispatch("fetchWasteCodesPerWorkingPoint", {
        workingPointId: this.workingPoint._id,
      })
    },
    deleteWCPWP(wcPWP) {
      this.$store.dispatch('deleteWasteCodePerWorkingPoint', wcPWP._id);
    }
  },
  created() {
    this.$store.dispatch('fetchLists', {})
    this.refreshData()
  }
};
</script>
<style scoped>
 @media only screen and (max-width: 600px) {
    .stockWidth {
      width: 100% !important
    }
    .aliasWidth {
      width: 100% !important
    }
    .noDataButton {
      display: grid;
      font-size: 12px;
    }
  }
  .stockWidth {
    width: 50px
  }

  .aliasWidth {
    width: 140px
  }
  .buttonAligned {
    margin-top: 20px;
  }
  .cardActions {
    position: fixed; bottom: 0; width: 100%; background: white; justify-content: center
  }
</style>
