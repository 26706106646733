<template>
<v-layout row justify="center">
  <v-dialog v-model="dialog" persistent max-width="1000px" @keydown.esc="close()">
    <v-card>
      <v-card-title>
        <span class="headline">Adauga campuri default {{model ? `${model.code} (${model.name})` : '-'}}</span>
      </v-card-title>
      <v-card-text>
        <v-form class="w-full padded p-5" ref="form">
          <v-row>
            <div class="padded m-2 w-quarter">
              <v-text-field ref='focusMe' type="text" placeholder="Cod deseu *" v-model="model.code" label="Cod deseu *" :disabled="true" />
            </div>
            <div class="padded w-half m-2" >
              <v-text-field ref='focusMe' type="text" placeholder="Nume deseu *" v-model="model.name" label="Nume deseu *" :disabled="true" />
            </div>
          </v-row>
          <v-row>
            <div class="padded w-quarter m-2">
              <v-select  v-model="model.stareFizicaDefault" :items="listsByCategory(ListCategories.STARE_FIZICA)" item-text="code" item-value="_id" label="Stare fizica *" clearable return-object>
                <template slot="item" slot-scope="{ item }">
                  <p>
                    <b> {{ item.code }}</b>
                  </p>
                </template>
              </v-select>
            </div>
            <div class="padded w-quarter m-2">
              <v-select v-model="model.unitMeasureDefault" :items="listsByCategory(ListCategories.UNITATE_MASURA)" item-text="code" item-value="_id" label="Unitate de masura *" clearable return-object>
                <template slot="item" slot-scope="{ item }">
                  <p>
                    <b> {{ item.code }}</b> {{ `(${item.name})` }}
                  </p>
                </template>
              </v-select>
            </div>
            <div class="padded w-quarter m-2">
              <v-select v-model="model.evalTypeDefault" :items="evalTypes" item-text="code" item-value="_id"  label="Evaluare cantitate *" clearable>
                <template slot="item" slot-scope="{ item }">
                  <p>
                    <b> {{ item.code }}</b> {{ `(${item.name})` }}
                  </p>
                </template>
              </v-select>
            </div>
            <div class="padded w-quarter m-2">
              <v-select v-model="model.stockingTypeDefault" :items="listsByCategory(ListCategories.TIP_STOCARE)" item-text="code" item-value="_id"  label="Tip stocare *" clearable return-object>
                <template slot="item" slot-scope="{ item }">
                  <p>
                    <b> {{ item.code }}</b> {{ `(${item.name})` }}
                  </p>
                </template>
              </v-select>
            </div>
          </v-row>
          <v-row>
            <div class="padded w-quarter m-2">
              <v-select v-model="model.destinationDefault" :items="listsByCategory(ListCategories.DESTINATIE)" item-text="code" item-value="_id"  label="Destinatie *" clearable return-object>
                <template slot="item" slot-scope="{ item }">
                  <p>
                    <b> {{ item.code }}</b> {{ `(${item.name})` }}
                  </p>
                </template>
              </v-select>
            </div>
            <div class="padded w-quarter m-2">
              <v-select v-model="model.carTypeDefault" :items="listsByCategory(ListCategories.MIJLOC_TRANSPORT)" item-text="code" item-value="_id"  label="Tip transport *" clearable return-object>
                <template slot="item" slot-scope="{ item }">
                  <p>
                    <b> {{ item.code }}</b> {{ `(${item.name})` }}
                  </p>
                </template>
              </v-select>
            </div>
            <div class="padded m-2 w-quarter" >
            <v-select v-model="discardType" :items="['ELIMINARE', 'VALORIFICARE']" label="In scop de *" />
          </div>
            <div class="padded m-2 w-quarter" v-if="discardType == 'VALORIFICARE'">
              <v-select v-model="model.codValorificareDefault" :items="listsByCategory(ListCategories.CODURI_VALORIFICARE)" item-text="code" item-value="_id" label="Cod valorificare*" clearable return-object>
                <template slot="item" slot-scope="{ item }">
                  <p>
                    <b> {{ item.code }}</b> {{ `(${item.name})` }}
                  </p>
                </template>
              </v-select>
            </div>
            <div class="padded m-2 w-quarter" v-if="discardType == 'ELIMINARE'">
              <v-select v-model="model.codEliminareDefault" :items="listsByCategory(ListCategories.CODURI_ELIMINARE)" item-text="code" item-value="_id" label="Cod eliminare*" clearable return-object>
                <template slot="item" slot-scope="{ item }">
                  <p>
                    <b> {{ item.code }}</b> {{ `(${item.name})` }}
                  </p>
                </template>
              </v-select>
            </div>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="cardActions">
        <v-btn type="button" class="mainButtonYes" text @click="save()">Salveaza</v-btn>
        <v-btn type="button" class="mainButtonNo" color="red" @click="close()">Renunta</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-layout>
</template>
<script>
import {
  ListCategories
} from '../../common/ListCategories'
import AddButton from '../Buttons/AddButtonText.vue'
import moment from "moment-timezone";
export default {
  components: {
    AddButton
  },
  props: [
    "closeDialog",
    "wasteCode",
    "searchWasteCodes"
  ],
  data: () => ({
    dialog: true,
    inputRules: [v => !!v || "Campul este obligatoriu"],
    model: {},
    discardType: null,
    deleteDialog: false,
    createDialog: false,
    partnerDialog: false,
    carDialog: false,
    driverDialog: false,
    currentPartner: null,
    editDialog: false,
    modal: false,
    partnerField: null,
    evalTypes: [
      {
        _id: 'A', code: 'A', name: 'Cantarita'
      },
      {
        _id: 'B', code: 'B', name: 'Estimata'
      }
    ]
    // fieldToAddPartner: null,
  }),
  watch: {
  },
  methods: {
    close() {
      this.closeDialog()
    },
    listsByCategory(category) {
      return this.lists[category]
    },
    save() {
      // return
      if (this.$refs.form.validate()) {
        if (this.discardType == 'ELIMINARE') {
          delete this.model.codValorificareDefault
        } else if (this.discardType == 'VALORIFICARE') {
          delete this.model.codEliminareDefault
        }
        this.$store.dispatch('updateWasteCode', {
          model: this.model,
          cb: () => {
            this.searchWasteCodes()
          }
        });
        this.close()
      }
    },
    parseDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
  computed: {
    ListCategories() {
      return ListCategories
    },
    lists() {
      return this.$store.state.wasteCodes.lists
    },
  },
  created() {
    this.$store.dispatch('fetchLists', {})
    this.model = {
      ...this.wasteCode
    }
    // if (!this.updating) {
    //   delete this.model._id
    //   this.model.registerDate = moment().format("YYYY-MM-DD")
    //   this.model.wasteCodePerWorkingPoint = this.wasteCodePWP
    //   this.model.workingPoint = this.workingPoint
    // } else {
    //   this.model.registerDate = this.parseDate(this.model.registerDate)
    // }
    if (this.wasteCode.codValorificareDefault && !this.wasteCode.codEliminareDefault) {
      this.discardType = 'VALORIFICARE'
    }
    if (!this.wasteCode.codValorificareDefault && this.wasteCode.codEliminareDefault) {
      this.discardType = 'ELIMINARE'
    }
  }
};
</script>
<style scoped>
  @media only screen and (max-width: 600px) {
    .w-half, .w-third, .w-quarter, .w-75 {
      width: 100% !important
    }
    .noDataButton {
      display: grid;
      font-size: 12px;
    }
  }
  .w-third {
    width: 31%
  }
  .w-quarter {
    width: 23%;
  }
  .w-half {
    width: 50%;
  }
  .w-75 {
    width: 73% ;
  }
</style>
