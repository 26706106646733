<template>
<auth-layout>
  <ListsLayout v-if="isAdmin" />
</auth-layout>
</template>
<script>
import AuthLayout from '../Layouts/AuthLayout.vue';
import ListsLayout from '../Layouts/ListsLayout.vue';
export default {
  data() {
    return {};
  },
  components: {
    AuthLayout,
    ListsLayout,
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
  },
  watch: {},
  methods: {},
  created() {}
};
</script>
