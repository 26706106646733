import toastr from '../../toastr';
import axiosInstance from '../axiosWrapper';
export default {
  //===========================
  // STATE
  //===========================
  state: {
    wasteCodesPerWorkingPoint: [],
    initialStocks: {},
    initialAliases: {},
    activeWasteCodesPerWorkingPointForFilter: []
  },
  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_WASTE_CODES_PER_WORKING_POINT(state, data) {
      state.wasteCodesPerWorkingPoint = data.map(d => {
        state.initialStocks[d._id] = d.initialStock
        state.initialAliases[d._id] = d.alias
        let discardType = '';
        if(d.codValorificare) {
          discardType = 'VALORIFICARE'
        } else if(d.codEliminare) {
          discardType = 'ELIMINARE'
        }
        d.discardType = discardType;
        return d
      })
    },
    SET_ACTIVE_WASTE_CODES_PER_WORKING_POINT_FOR_FILTER(state, data) {
      state.activeWasteCodesPerWorkingPointForFilter = data.filter(wc => wc.isActive).map(wc => {
        wc.displayName = `${wc.alias ||wc.wasteCode.code} (${wc.wasteCode.name})`
        return wc
      })
    },
    UPDATE_WASTE_CODE_PWP(state, wasteCodePWP) {
      state.wasteCodesPerWorkingPoint.map(wcPWP => {
        if (wcPWP._id == wasteCodePWP._id) {
          let discardType = '';
          if(wasteCodePWP.codValorificare) {
            discardType = 'VALORIFICARE'
          } else if(wasteCodePWP.codEliminare) {
            discardType = 'ELIMINARE'
          }
          wcPWP = {...wasteCodePWP, discardType: discardType}
          state.initialStocks[wcPWP._id] = wcPWP.initialStock
          state.initialAliases[wcPWP._id] = wcPWP.alias
        }
      })
    },
    ADD_WASTE_CODE(state, wasteCodePWP) {
      state.wasteCodesPerWorkingPoint = state.wasteCodesPerWorkingPoint.filter(wc => wc._id)
      let discardType = '';
      if(wasteCodePWP.codValorificare) {
        discardType = 'VALORIFICARE'
      } else if(wasteCodePWP.codEliminare) {
        discardType = 'ELIMINARE'
      }
      wasteCodePWP.discardType = discardType
      state.wasteCodesPerWorkingPoint.unshift(wasteCodePWP)
      state.initialStocks[wasteCodePWP._id] = wasteCodePWP.initialStock
      state.initialAliases[wasteCodePWP._id] = wasteCodePWP.alias
      if(wasteCodePWP.isActive) {
        state.activeWasteCodesPerWorkingPointForFilter.unshift(
          {...wasteCodePWP, displayName: `${wasteCodePWP.alias ||wasteCodePWP.wasteCode.code} (${wasteCodePWP.wasteCode.name})`}
        )
      }
    },
    ADD_EMPTY_WASTE_CODE(state) {
      state.wasteCodesPerWorkingPoint.unshift({})
    },
    DELETE_WC_PWP(state, id) {
      state.wasteCodesPerWorkingPoint = state.wasteCodesPerWorkingPoint.filter(wp => wp._id != id)
      state.activeWasteCodesPerWorkingPointForFilter = state.activeWasteCodesPerWorkingPointForFilter.filter(wp => wp._id != id)
    },
    DELETE_EMPTY_WASTE_CODE(state) {
      state.wasteCodesPerWorkingPoint = state.wasteCodesPerWorkingPoint.filter(wc => wc._id)
    },
  },
  //===========================
  // ACTIONS
  //===========================
  actions: {
    async addEmptyWasteCode({commit}) {
      commit('ADD_EMPTY_WASTE_CODE');
    },
    async deleteEmptyWasteCode({commit}) {
      commit('DELETE_EMPTY_WASTE_CODE');
    },
    async updateWasteCodePerWorkingPoint({ commit }, formData) {
      let id = formData.id
      let model = formData.model
      let cb = formData.cb
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/wasteCodePerWorkingPoint/${id}`,
          model,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('UPDATE_WASTE_CODE_PWP', res.data);
          toastr.success("Campul a fost updatat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
    async createWasteCodePerWorkingPoint({ commit }, formData) {
      let workingPointId = formData.workingPointId
      let wasteCode = formData.wasteCode
      let cb = formData.cb
      try {
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/wasteCodePerWorkingPoint/${workingPointId}`,
          wasteCode,
          {
            withCredentials: true,
          },
        );
        if (res.status === 201) {
          commit('ADD_WASTE_CODE', res.data);
          toastr.success("Campul a fost adaugat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
    async fetchWasteCodesPerWorkingPoint({ commit }, options) {
      let workingPointId = options.workingPointId || ''
      let filters = JSON.stringify(options.filters)
      try {
        let url = `${process.env.VUE_APP_API_URL}/wasteCodePerWorkingPoint/forWorkingPoint/${workingPointId}?filters=${filters}`
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_WASTE_CODES_PER_WORKING_POINT', res.data);
          if(!options.filters || !Object.keys(options.filters).length) {
            commit('SET_ACTIVE_WASTE_CODES_PER_WORKING_POINT_FOR_FILTER', res.data);
          }
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
    async updateWasteCodeActive({ commit }, formData) {
      let id = formData.id
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/wasteCodePerWorkingPoint/switchActive/${id}`,
          {},
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          toastr.success("Campul a fost updatat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
    async deleteWasteCodePerWorkingPoint({ commit }, id) {
      let url = `${process.env.VUE_APP_API_URL}/wasteCodePerWorkingPoint/${id}`
      try {
        const res = await axiosInstance.delete(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('DELETE_WC_PWP', id);
          toastr.success("Campul a fost sters!")
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
};
