import toastr from '../../toastr';
import axiosInstance from '../axiosWrapper';
export default {
  //===========================
  // STATE
  //===========================
  state: {
    wasteCodes: [],
    wasteCodesTotal: 0,
    lists: {},
  },
  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_WASTE_CODES(state, data) {
      state.wasteCodes = data
    },
    SET_WASTE_CODES_TOTAL(state, data) {
      state.wasteCodesTotal = data
    },
    SET_LISTS(state, data) {
      state.lists = data
    },
  },
  //===========================
  // ACTIONS
  //===========================
  actions: {
    async fetchLists({ commit }, options) {
      try {
        let url = `${process.env.VUE_APP_API_URL}/lists/grouped`
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_LISTS', res.data);
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
    async updateWasteCode({ commit }, formData) {
      let model = formData.model
      let cb = formData.cb
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/wasteCodes/${model._id}`,
          model,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          toastr.success("Campul a fost updatat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
    async fetchWasteCodes({ commit }, options) {
      let page = options.page || 1
      let perPage = options.perPage || 25
      let search = options.search || ''
      let getEntries = options.getEntries || false
      let filter = options.filter
      // let c
      try {
        let url = `${process.env.VUE_APP_API_URL}/wasteCodes/?page=${page}&perPage=${perPage}&getEntries=${getEntries}`
        if (search && search.length) {
          url = `${url}&search=${search}`
        }
        if(filter) {
          url = `${url}&filter=${JSON.stringify(filter)}`
        }
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          if (getEntries) {
            commit('SET_WASTE_CODES', res.data.items);
            commit('SET_WASTE_CODES_TOTAL', res.data.total);
          } else {
            commit('SET_WASTE_CODES', res.data);
          }
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
};
