<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent transition="dialog-top-transition" max-width="600">
      <v-card>
        <v-toolbar
          color="accent"
          dark
          style="height: 20px !important"
        />
        <v-card-title>
          <span class="dialogText">Vrei sa salvezi {{ itemsChanged }} introduse?</span>
        </v-card-title>
        <v-card-actions class="cardActions">
          <v-btn
            class="mainButtonNo"
            @click="close()"
          >Nu</v-btn>
          <v-btn
            color="red"
            class="mainButtonYes"
            @click="save()"
          >Da</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
export default {
  props: [
    "onClose",
    "onConfirm",
    "itemsChanged"
  ],
  data: () => ({
    dialog: true,
  }),
  methods: {
    close() {
      this.onClose() 
    },
    save() {
      this.onConfirm()
      this.close()
    }
  },
  computed: {
  },
  created() {
  }
};
</script>