var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":"","justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close()}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Adauga campuri default "+_vm._s(_vm.model ? ((_vm.model.code) + " (" + (_vm.model.name) + ")") : '-'))])]),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"w-full padded p-5"},[_c('v-row',[_c('div',{staticClass:"padded m-2 w-quarter"},[_c('v-text-field',{ref:"focusMe",attrs:{"type":"text","placeholder":"Cod deseu *","label":"Cod deseu *","disabled":true},model:{value:(_vm.model.code),callback:function ($$v) {_vm.$set(_vm.model, "code", $$v)},expression:"model.code"}})],1),_c('div',{staticClass:"padded w-half m-2"},[_c('v-text-field',{ref:"focusMe",attrs:{"type":"text","placeholder":"Nume deseu *","label":"Nume deseu *","disabled":true},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1)]),_c('v-row',[_c('div',{staticClass:"padded w-quarter m-2"},[_c('v-select',{attrs:{"items":_vm.listsByCategory(_vm.ListCategories.STARE_FIZICA),"item-text":"code","item-value":"_id","label":"Stare fizica *","clearable":"","return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('b',[_vm._v(" "+_vm._s(item.code))])])]}}]),model:{value:(_vm.model.stareFizicaDefault),callback:function ($$v) {_vm.$set(_vm.model, "stareFizicaDefault", $$v)},expression:"model.stareFizicaDefault"}})],1),_c('div',{staticClass:"padded w-quarter m-2"},[_c('v-select',{attrs:{"items":_vm.listsByCategory(_vm.ListCategories.UNITATE_MASURA),"item-text":"code","item-value":"_id","label":"Unitate de masura *","clearable":"","return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('b',[_vm._v(" "+_vm._s(item.code))]),_vm._v(" "+_vm._s(("(" + (item.name) + ")"))+" ")])]}}]),model:{value:(_vm.model.unitMeasureDefault),callback:function ($$v) {_vm.$set(_vm.model, "unitMeasureDefault", $$v)},expression:"model.unitMeasureDefault"}})],1),_c('div',{staticClass:"padded w-quarter m-2"},[_c('v-select',{attrs:{"items":_vm.evalTypes,"item-text":"code","item-value":"_id","label":"Evaluare cantitate *","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('b',[_vm._v(" "+_vm._s(item.code))]),_vm._v(" "+_vm._s(("(" + (item.name) + ")"))+" ")])]}}]),model:{value:(_vm.model.evalTypeDefault),callback:function ($$v) {_vm.$set(_vm.model, "evalTypeDefault", $$v)},expression:"model.evalTypeDefault"}})],1),_c('div',{staticClass:"padded w-quarter m-2"},[_c('v-select',{attrs:{"items":_vm.listsByCategory(_vm.ListCategories.TIP_STOCARE),"item-text":"code","item-value":"_id","label":"Tip stocare *","clearable":"","return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('b',[_vm._v(" "+_vm._s(item.code))]),_vm._v(" "+_vm._s(("(" + (item.name) + ")"))+" ")])]}}]),model:{value:(_vm.model.stockingTypeDefault),callback:function ($$v) {_vm.$set(_vm.model, "stockingTypeDefault", $$v)},expression:"model.stockingTypeDefault"}})],1)]),_c('v-row',[_c('div',{staticClass:"padded w-quarter m-2"},[_c('v-select',{attrs:{"items":_vm.listsByCategory(_vm.ListCategories.DESTINATIE),"item-text":"code","item-value":"_id","label":"Destinatie *","clearable":"","return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('b',[_vm._v(" "+_vm._s(item.code))]),_vm._v(" "+_vm._s(("(" + (item.name) + ")"))+" ")])]}}]),model:{value:(_vm.model.destinationDefault),callback:function ($$v) {_vm.$set(_vm.model, "destinationDefault", $$v)},expression:"model.destinationDefault"}})],1),_c('div',{staticClass:"padded w-quarter m-2"},[_c('v-select',{attrs:{"items":_vm.listsByCategory(_vm.ListCategories.MIJLOC_TRANSPORT),"item-text":"code","item-value":"_id","label":"Tip transport *","clearable":"","return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('b',[_vm._v(" "+_vm._s(item.code))]),_vm._v(" "+_vm._s(("(" + (item.name) + ")"))+" ")])]}}]),model:{value:(_vm.model.carTypeDefault),callback:function ($$v) {_vm.$set(_vm.model, "carTypeDefault", $$v)},expression:"model.carTypeDefault"}})],1),_c('div',{staticClass:"padded m-2 w-quarter"},[_c('v-select',{attrs:{"items":['ELIMINARE', 'VALORIFICARE'],"label":"In scop de *"},model:{value:(_vm.discardType),callback:function ($$v) {_vm.discardType=$$v},expression:"discardType"}})],1),(_vm.discardType == 'VALORIFICARE')?_c('div',{staticClass:"padded m-2 w-quarter"},[_c('v-select',{attrs:{"items":_vm.listsByCategory(_vm.ListCategories.CODURI_VALORIFICARE),"item-text":"code","item-value":"_id","label":"Cod valorificare*","clearable":"","return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('b',[_vm._v(" "+_vm._s(item.code))]),_vm._v(" "+_vm._s(("(" + (item.name) + ")"))+" ")])]}}],null,false,144964611),model:{value:(_vm.model.codValorificareDefault),callback:function ($$v) {_vm.$set(_vm.model, "codValorificareDefault", $$v)},expression:"model.codValorificareDefault"}})],1):_vm._e(),(_vm.discardType == 'ELIMINARE')?_c('div',{staticClass:"padded m-2 w-quarter"},[_c('v-select',{attrs:{"items":_vm.listsByCategory(_vm.ListCategories.CODURI_ELIMINARE),"item-text":"code","item-value":"_id","label":"Cod eliminare*","clearable":"","return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('p',[_c('b',[_vm._v(" "+_vm._s(item.code))]),_vm._v(" "+_vm._s(("(" + (item.name) + ")"))+" ")])]}}],null,false,144964611),model:{value:(_vm.model.codEliminareDefault),callback:function ($$v) {_vm.$set(_vm.model, "codEliminareDefault", $$v)},expression:"model.codEliminareDefault"}})],1):_vm._e()])],1)],1),_c('v-card-actions',{staticClass:"cardActions"},[_c('v-btn',{staticClass:"mainButtonYes",attrs:{"type":"button","text":""},on:{"click":function($event){return _vm.save()}}},[_vm._v("Salveaza")]),_c('v-btn',{staticClass:"mainButtonNo",attrs:{"type":"button","color":"red"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Renunta")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }