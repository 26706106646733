import axios from 'axios';
function getImpersonatedUserId() {
  const userId = localStorage.getItem('userImpId')
  return userId || null
}
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  config => {
    const impersonatedId = getImpersonatedUserId();
    if (impersonatedId && config.url) {
      const url = new URL(config.url, axiosInstance.defaults.baseURL);
      url.searchParams.append('impUserId', impersonatedId);
      config.url = url.toString();
    }
    return config;
  },
  error => Promise.reject(error)
);
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;
    console.log("ERROR FROM INSTANCE", error)
    if (response && response.status !== 201) {
      // Handle error accordingly, e.g., show a notification
      // toastr.error(response.data.message);
      return Promise.reject(response.data.message);
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
