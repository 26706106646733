<template>
  <v-btn :loading="loading" @click="onClick" type="button" class="mainButton" color="accent">
    <slot></slot>
  </v-btn>
</template>
<script>
import ButtonBase from './ButtonBase.vue';
export default {
  components: {
    ButtonBase,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
      onClick: Function
    },
  },
};
</script>
