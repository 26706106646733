<template>
<div style="margin: 10px">
  <LayoutHeader title="Parteneri" :dontDistrurbFree="true"/>
  <div class="layoutBody">
    <AddButton v-if="!userDisabled && isManager" :onClick="() => {openPartnerDialog()}" caption="Adauga partener" />
    <v-data-table class="elevation-0" :headers="partnerHeaders" :items="partners">
      <template v-slot:[`item.types`]="props">
        <p class="tableData">
          <div class="flex">
            <v-combobox :disabled="userDisabled"  label="Tip partener" v-model="props.item.types" class="left" density="compact" multiple style="max-height: 33px;" underlined :items="listsByCategory(ListCategories.TIP_PARTENER)" item-text="name" item-value="_id" color="primary" @change="updatePartner(props.item)">
              <template v-slot:selection="data">
                <v-chip :key="data.item._id" v-bind="data.attrs" :input-value="data.selected" :disabled="data.disabled" @click:close="data.parent.selectItem(data.item)">
                  <!-- <v-avatar class="accent white--text" left v-text="data.item.name.slice(0, 1).toUpperCase()"></v-avatar> -->
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-combobox>
          </div>
        </p>
      </template>
      <template v-slot:[`item.workingPoint.name`]="props">
        <p class="tableData">
          <div class="flex" v-if="props.item.workingPoint">
            {{props.item.workingPoint.company.companyName + ' \\ ' + props.item.workingPoint.name}}
          </div>
        </p>
      </template>
      <template v-slot:[`item.partnerCars`]="props">
        <p class="tableData">
          <div class="flex">
            {{props.item.partnerCars.map(c => `${c.plate}`).join(',')}}
          </div>
        </p>
      </template>
      <template v-slot:[`item.partnerDrivers`]="props">
        <p class="tableData">
          <div class="flex">
            {{props.item.partnerDrivers.map(c => `${c.name}`).join(', ')}}
          </div>
        </p>
      </template>
      <template v-slot:[`item.isActive`]="props">
        <p class="tableData">
          <div class="flex">
            <v-checkbox class="" style="align-items: center;" :input-value="props.item.isActive" :disabled="userDisabled || !isManager || !props.item.canEdit"
              @click="updatePartnerActive(props.item)"></v-checkbox>
          </div>
        </p>
      </template>
      <template v-slot:[`item.actions`]="props" v-if="isManager">
        <p class="tableData">
          <div class="flex" v-if="props.item.canEdit" >
            <div class="flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="openPartnerDialog(props.item)" icon color="primary" v-bind="attrs" v-on="on" :disabled="userDisabled">
                    <v-icon>mdi-pen</v-icon>
                  </v-btn>
                </template>
                <span>Editeaza</span>
              </v-tooltip>
            </div>
            <div class="flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="openDeleteDialog(props.item)" icon color="red" v-bind="attrs" v-on="on" :disabled="userDisabled">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <span>Sterge</span>
              </v-tooltip>
            </div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="openPartnerDialog(props.item)" color="secondary" v-bind="attrs" v-on="on" :disabled="userDisabled">
                  <v-icon>mdi-car</v-icon>
                </v-btn>
              </template>
              <span>Adauga masina</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="openPartnerDialog(props.item)" color="accent" v-bind="attrs" v-on="on" :disabled="userDisabled">
                  <v-icon>mdi-account-tie</v-icon>
                </v-btn>
              </template>
              <span>Adauga sofer</span>
            </v-tooltip>
          </div>
        </p>
      </template>
    </v-data-table>
  </div>
  <DeleteDialog v-if="deleteDialog" :closeDialog="closeDeleteDialog" :deleteAction="deletePartner" itemName="partenerul" :item="currentPartner"/>
  <CreatePartner v-if="partnerDialog" :closeDialog="closePartnerDialog" :workingPoints="managerWorkingPoints" 
  :createCb="refreshData" :partnerToUpdate="partnerToUpdate" :updateCarsAndDrivers="false"/>
  <!-- <CreateCar v-if="carDialog" :closeDialog="closeCarDialog" :partner="currentPartner" :carTypes="listsByCategory(ListCategories.MIJLOC_TRANSPORT)" :wasteCodePWP="currentWasteCodePWP" :createCb="refreshData"/>
  <CreateDriver v-if="driverDialog" :closeDialog="closeDriverDialog" :partner="currentPartner" :wasteCodePWP="currentWasteCodePWP" :createCb="refreshData"/> -->
</div>
</template>
<script>
import moment from "moment-timezone";
import {
ListCategories
} from '../common/ListCategories';
import AddButton from '../components/Buttons/AddButton.vue';
// import CreateCar from '../components/Modals/Create/CreateCar.vue';
// import CreateDriver from '../components/Modals/Create/CreateDriver.vue';
import CreatePartner from '../components/Modals/Create/CreatePartner.vue';
import DeleteDialog from '../components/Modals/DeleteDialog.vue';
import SectionHeadline from '../components/Typography/SectionHeadline.vue';
import SubHeader from '../components/Typography/SubHeader.vue';
import LayoutHeader from '../components/Widgets/LayoutHeader.vue';
import AuthLayout from './AuthLayout.vue';
export default {
  components: {
    AuthLayout,
    SectionHeadline,
    SubHeader,
    CreatePartner,
    // CreateCar,
    // CreateDriver,
    AddButton,
    DeleteDialog,
    LayoutHeader
  },
  data() {
    return {
      deleteDialog: false,
      createDialog: false,
      partnerDialog: false,
      // carDialog: false,
      // driverDialog: false,
      currentPartner: null,
      editDialog: false,
      currentWorkingPoint: null,
      partnerToUpdate: null,
      search: '',
      options: {},
      expanded: [],
      currentWasteCodePWP: null,
      fieldToAddPartner: null,
      partnerHeaders: [
        {
          text: 'Actiuni',
          value: 'actions'
        },
        {
          text: 'Activ',
          value: 'isActive'
        },
        {
          text: 'Punct de lucru',
          value: 'workingPoint.name'
        },
        {
          text: 'Nume',
          value: 'name'
        },
        {
          text: 'Adresa',
          value: 'address'
        },
        {
          text: 'Oras',
          value: 'city'
        },
        {
          text: 'Judet',
          value: 'county'
        },
        {
          text: 'CUI',
          value: 'vatId'
        },
        {
          text: 'Reg Com',
          value: 'regCom'
        },
        {
          text: 'Tip partener',
          value: 'types'
        },
        // {
        //   text: 'Masini',
        //   value: 'partnerCars'
        // },
        // {
        //   text: 'Soferi',
        //   value: 'partnerDrivers'
        // },
      ],
    };
  },
  watch: {
  },
  computed: {
    ListCategories() {
      return ListCategories
    },
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
    isManager() {
      return this.$store.state.auth.isManager
    },
    user() {
      return this.$store.state.auth.user
    },
    userDisabled() {
      return this.$store.state.auth.userDisabled
    },
    companies() {
      return this.partnersData
    },
    partnersData() {
      return this.$store.state.partners.partnersData
    },
    partners() {
      return this.$store.state.partners.partners
    },
    lists() {
      return this.$store.state.wasteCodes.lists
    },
    canPerformOrganizationChanges() {
      return this.$store.state.auth.canPerformOrganizationChanges
    },
    workingPoints() {
      return this.$store.state.workingPoints.workingPoints.map(wp => {
        wp.displayName = `${wp.company.companyName} \\ ${wp.name}`
        return wp
      }).filter(wp => wp.isActive)
    },
    managerWorkingPoints() {
      if(this.canPerformOrganizationChanges) {
        // nu e gueest
        return this.workingPoints
      }
      if(this.user.permissions) {
        let managerPerm = this.user.permissions.find(p => p.role == 'MANAGER')
        if(managerPerm) {
          return this.workingPoints.filter(wp => managerPerm.workingPoints.includes(wp._id))
        }
      }
      return []
    },
  },
  methods: {
    openPartnerDialog(partnerToUpdate) {
      this.partnerToUpdate = partnerToUpdate
      this.partnerDialog = true
    },
    closePartnerDialog() {
      this.partnerDialog = false
      this.partnerToUpdate = null
    },
    updatePartnerActive(partner) {
      this.$store.dispatch("updatePartnerActive", {
        partnerId: partner._id,
      })
      partner.isActive = !partner.isActive
    },
    updatePartner(item) {
      this.$store.dispatch("updatePartner", {
        id: item._id,
        model: item,
        cb: () => {}
      })
    },
    deletePartner(partner) {
      this.$store.dispatch('deletePartnerById', partner._id);
    },
    listsByCategory(category) {
      return this.lists[category]
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    openDeleteDialog(partner) {
      this.currentPartner = partner
      this.deleteDialog = true
    },
    closeDeleteDialog() {
      this.currentPartner = null
      this.deleteDialog = false
    },
    // openCarDialog(partner) {
    //   this.currentPartner = partner
    //   this.carDialog = true
    // },
    // closeCarDialog() {
    //   this.carDialog = false
    // },
    // openDriverDialog(partner) {
    //   this.currentPartner = partner
    //   this.driverDialog = true
    // },
    // closeDriverDialog() {
    //   this.driverDialog = false
    // },
    refreshData() {
      this.$store.dispatch('fetchPartners')
    }
  },
  created() {
    this.$store.dispatch('fetchLists', {})
    this.$store.dispatch('fetchWorkingPoints')
    this.refreshData()
  }
};
</script>
