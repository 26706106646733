import axiosInstance from '../axiosWrapper';
export default {
  //===========================
  // STATE
  //===========================
  state: {
    statistics: {},
    thingsToDo: {},
    loading: false,
    startGuideWasteCodes: false,
    startGuideIntro: true,
    startGuidePartner: false,
    startGuideOutro: false
    // totalUsers: 0
  },
  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_STATISTICS(state, data) {
      state.statistics = data
    },
    SET_WC_ST(state, data) {
      state.startGuideWasteCodes = data
    },
    SET_PARTNER_GUIDE(state, data) {
      state.startGuidePartner = data
    },
    SET_GUIDE_OUTRO(state, data) {
      state.startGuideOutro = data
    },
    SET_LOADING(state, data) {
      state.loading = data
    },
    SET_THINGS_TO_DO(state, data) {
      state.thingsToDo = data
    },
    SET_INTRO(state, data) {
      state.startGuideIntro = data
    }
  },
  //===========================
  // ACTIONS
  //===========================
  actions: {
    async exitIntro({commit}) {
      commit('SET_INTRO', false);
    },
    async enterOutro({commit}) {
      commit('SET_GUIDE_OUTRO', true);
      commit('SET_WC_ST', false);
    },
    async exitOutro({commit}) {
      commit('SET_GUIDE_OUTRO', false);
    },
    async enterWasteCodeStartGuide({commit}) {
      commit('SET_WC_ST', true);
      commit('SET_PARTNER_GUIDE', false);
    },
    async enterPartnerStartGuide({commit}) {
      commit('SET_PARTNER_GUIDE', true);
    },
    async exitStartGuide({commit}) {
      commit('SET_WC_ST', false);
      commit('SET_PARTNER_GUIDE', false);
    },
		async fetchUserStatistics({ commit }, options) {
      let url = `${process.env.VUE_APP_API_URL}/workingPoints/statistics`
      try {
        // let url = `${process.env.VUE_APP_API_URL}/companies/data/${userId}`
        commit('SET_LOADING', true);
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        commit('SET_LOADING', false);
        if (res.status === 200) {
          commit('SET_STATISTICS', res.data);
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchThingsToDo({ commit }, options) {
      let url = `${process.env.VUE_APP_API_URL}/workingPoints/thingsToDo`
      try {
        // let url = `${process.env.VUE_APP_API_URL}/companies/data/${userId}`
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_THINGS_TO_DO', res.data);
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
};
