<template>
<!-- <base-layout :headline="headline"> -->
<!-- <template #header>
      <default-navbar></default-navbar>
    </template> -->
<div>
  <slot></slot>
</div>
<!-- </base-layout> -->
</template>
<script>
import DefaultNavbar from '../components/Navigation/DefaultNavbar.vue';
import Spinner from '../components/Widgets/Spinner/Spinner.vue';
import BaseLayout from './BaseLayout.vue';
export default {
  data() {
    return {
      loading: true,
    };
  },
  props: ['headline'],
  components: {
    BaseLayout,
    DefaultNavbar,
    Spinner
  },
  async beforeCreate() {
    await this.$store.dispatch('fetchUser');
    if (
      !this.$store.state.auth.loadingUser &&
      !this.$store.state.auth.isAuthenticated
    ) {
      // daca nu suntem pe reset password?
      if (this.$route.name == 'ForgotPassword' || this.$route.name == 'ResetPassword' || this.$route.name == 'AcceptInvite') {
        return
      }
      this.$router.push('/login');
      return;
    }
    if (
      !this.$store.state.auth.loadingUser &&
      this.$store.state.auth.isAuthenticated
    ) {
      this.$router.push('/');
    }
    this.loading = false;
  },
};
</script>
