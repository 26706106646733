<template>
<auth-layout>
  <PartnersLayout />
</auth-layout>
</template>
<script>
import AuthLayout from '../Layouts/AuthLayout.vue';
import PartnersLayout from '../Layouts/PartnersLayout.vue';
export default {
  data() {
    return {};
  },
  components: {
    AuthLayout,
    PartnersLayout
  },
  computed: {
   
  },
  watch: {
  },
  methods: {},
  created() {
  }
};
</script>
