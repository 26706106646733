<template>
  <div :class="['form-element', 'py-2', className]">
    <v-text-field
      class="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      :class="{ 'border-red-400': error }" :id="name" :type="type || `text`" :placeholder="placeholder"
      v-model="modelValue" @input="$emit('update:modelValue', $event.target.value)" />
    <div v-if="error" class="text-sm -mt-4 text-red-400">{{ error }}</div>
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    label: String,
    placeholder: String,
    modelValue: (String | Number),
    isRequired: Boolean,
    type: String,
    error: String,
    className: String
  },
  emits: ['update:modelValue'],
};
</script>
