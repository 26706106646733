<template>
<div style="margin: 10px">
  <section-headline>
    Liste
  </section-headline>
  <v-row class="text-left">
    <v-col cols="12">
      <v-data-table class="elevation-0" :headers="headers" :items="Object.keys(lists).map(l => {return {name: l}})" show-expand item-key="name" hide-default-footer>
        <template v-slot:expanded-item="{item, headers}">
          <td class="px-0 py-2" :colspan="headers.length">
            <v-data-table class="elevation-0 lightBlue" :headers="headers" :items="listsByCategory(item.name)" hide-default-footer>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</div>
</template>
<script>
import AuthLayout from './AuthLayout.vue';
import SectionHeadline from '../components/Typography/SectionHeadline.vue';
import SubHeader from '../components/Typography/SubHeader.vue';
export default {
  components: {
    AuthLayout,
    SectionHeadline,
    SubHeader,
  },
  data() {
    return {
      expanded: [],
      headersCat: [{
        text: 'Categorii',
        value: 'name'
      }, ],
      headers: [{
          text: 'Nume',
          value: 'name'
        },
        {
          text: 'Cod',
          value: 'code'
        },
      ],
    };
  },
  watch: {
  },
  computed: {
    lists() {
      return this.$store.state.wasteCodes.lists
    },
  },
  methods: {
    listsByCategory(category) {
      return this.lists[category]
    },
  },
  created() {
    this.$store.dispatch('fetchLists', {})
  }
};
</script>
