<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px" @keydown.esc="close()">
      <v-card>
        <v-card-title>
          <span class="headline">Adauga evidenta pentru <b>{{ wasteCodePWP ? `${wasteCodePWP.alias || wasteCodePWP.wasteCode.code}
            ` : '-' }}</b> {{ wasteCodePWP ? `(${wasteCodePWP.wasteCode.name})` : '-' }}</span>
        </v-card-title>
        <v-card-text>
          <v-form class="w-full padded pt-4 " ref="form">
            <v-row dense>
              
              <!-- <div class="padded m-2 w-quarter"> -->
              <v-col cols="12" md="3">
                <v-text-field ref='focusMe' type="text" placeholder="Cod deseu *" v-model="model.wasteCode.code"
                  label="Cod deseu *" :disabled="true" />
              <!-- </div> -->
              </v-col>
              <!-- <div class="padded w-75 m-2"> -->
              <v-col cols="12" md="9">
                <v-text-field ref='focusMe' type="text" placeholder="Nume deseu *" v-model="model.wasteCode.name"
                  label="Nume deseu *" :disabled="true" />
              <!-- </div> -->
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="3">
                <!-- <div class="padded w-quarter m-2"> -->
                <v-text-field :autofocus="simplifiedAvailable ? true : false" type="number" label="Cantitate" placeholder="Cantitate" v-model="model.quantity" required
                  :max="999999.99" oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;" :rules="[
                    v => !!v || `Completeaza valoarea`,
                    v => Number(v) >= 0 || `Numarul trebuie sa fie pozitiv`,
                    v => !v || /^[0-9]\d{0,9}(?:.\d{1,2})?$/.test(v) || `Numarul trebuie sa aiba cel mult 2 zecimale`
                  ]" />
              <!-- </div> -->
              </v-col>
              <v-col cols="12" md="3">
                <!-- <div class="padded w-quarter m-2"> -->
                <v-select v-model="model.unitMeasure" :items="listsByCategory(ListCategories.UNITATE_MASURA)"
                  item-text="code" item-value="_id" :rules="inputRules" label="Unitate de masura *" required
                  return-object>
                  <template slot="item" slot-scope="{ item }">
                    <p>
                      <b> {{ item.code }}</b> {{ `(${item.name})` }}
                    </p>
                  </template>
                </v-select>
              <!-- </div> -->
              </v-col>
              <v-col cols="12" md="3">
                <!-- <div class="padded w-half ma-0 ml-2"> -->
                <v-dialog max-width="300px" persistent v-model="modal">
                  <template v-slot:activator="{ on: { click } }">
                    <v-text-field v-on:click="click" v-model="model.registerDate" label="Data inregistrare"
                      required></v-text-field>
                  </template>
                  <v-date-picker v-model="model.registerDate" scrollable actions>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal = false">Cancel</v-btn>
                      <v-btn color="primary" @click="modal = false">OK</v-btn>
                    </v-card-actions>
                  </v-date-picker>
                </v-dialog>
              <!-- </div> -->
              </v-col>
            </v-row>
            <v-row dense v-if="!simplifiedAvailable || (simplifiedAvailable && !showSimplified)">
              <v-col cols="12" md="3">
                <!-- <div class="padded m-2 w-quarter"> -->
                <v-select v-model="discardType" :items="['ELIMINARE', 'VALORIFICARE']" :rules="inputRules"
                  label="In scop de *" />
                <!-- </div> -->
              </v-col>
              <v-col cols="12" md="3">
                <!-- <div class="padded m-2 w-quarter" v-if="discardType == 'VALORIFICARE'"> -->
                <v-select v-if="discardType == 'VALORIFICARE'" v-model="model.codValorificare" :items="listsByCategory(ListCategories.CODURI_VALORIFICARE)"
                  item-text="code" item-value="_id" :rules="inputRules" label="Cod valorificare*" required
                  return-object>
                  <template slot="item" slot-scope="{ item }">
                    <p>
                      <b> {{ item.code }}</b> {{ `(${item.name})` }}
                    </p>
                  </template>
                </v-select>
              <!-- </div> -->
              <!-- <div class="padded m-2 w-quarter" v-if="discardType == 'ELIMINARE'"> -->
                <v-select v-if="discardType == 'ELIMINARE'" v-model="model.codEliminare" :items="listsByCategory(ListCategories.CODURI_ELIMINARE)"
                  item-text="code" item-value="_id" :rules="inputRules" label="Cod eliminare*" required return-object>
                  <template slot="item" slot-scope="{ item }">
                    <p>
                      <b> {{ item.code }}</b> {{ `(${item.name})` }}
                    </p>
                  </template>
                </v-select>
              <!-- </div> -->
              </v-col>
              <v-col cols="12" md="3">
                <!-- <div class="padded w-quarter m-2"> -->
                <v-select v-model="model.stareFizica" :items="listsByCategory(ListCategories.STARE_FIZICA)"
                  item-text="code" item-value="_id" :rules="inputRules" label="Stare fizica *" required return-object>
                  <template slot="item" slot-scope="{ item }">
                    <p>
                      <b> {{ item.code }}</b>
                    </p>
                  </template>
                </v-select>
              <!-- </div> -->
              </v-col>
              <v-col cols="12" md="3">
                <!-- <div class="padded w-quarter m-2"> -->
                <v-select v-model="model.evalType" :items="evalTypes" item-text="code" item-value="_id"
                  :rules="inputRules" label="Evaluare cantitate *" required>
                  <template slot="item" slot-scope="{ item }">
                    <p>
                      <b> {{ item.code }}</b> {{ `(${item.name})` }}
                    </p>
                  </template>
                </v-select>
              <!-- </div> -->
              </v-col>
            </v-row>
            <v-row dense v-if="!simplifiedAvailable || (simplifiedAvailable && !showSimplified)">
              <v-col cols="12" md="3">
                <!-- <div class="padded w-quarter m-2"> -->
                <v-select v-model="model.stockingType" :items="listsByCategory(ListCategories.TIP_STOCARE)"
                  item-text="code" item-value="_id" :rules="inputRules" label="Tip stocare *" required return-object>
                  <template slot="item" slot-scope="{ item }">
                    <p>
                      <b> {{ item.code }}</b> {{ `(${item.name})` }}
                    </p>
                  </template>
                </v-select>
              <!-- </div> -->
              </v-col>
              <v-col cols="12" md="3">
                <!-- <div class="padded w-quarter m-2"> -->
                <v-select v-model="model.destination" :items="listsByCategory(ListCategories.DESTINATIE)"
                  item-text="code" item-value="_id" :rules="inputRules" label="Destinatie *" required return-object>
                  <template slot="item" slot-scope="{ item }">
                    <p>
                      <b> {{ item.code }}</b> {{ `(${item.name})` }}
                    </p>
                  </template>
                </v-select>
              <!-- </div> -->
              </v-col>
              <v-col cols="12" md="3">
                <!-- <div class="padded w-quarter m-2"> -->
                <v-select v-model="model.carType" :items="listsByCategory(ListCategories.MIJLOC_TRANSPORT)"
                  item-text="code" item-value="_id" :rules="inputRules" label="Tip transport *" required return-object>
                  <template slot="item" slot-scope="{ item }">
                    <p>
                      <b> {{ item.code }}</b> {{ `(${item.name})` }}
                    </p>
                  </template>
                </v-select>
              <!-- </div> -->
              </v-col>
              <v-col cols="12" md="3">
                <!-- <div class="padded w-quarter m-2"> -->
                <v-select v-model="model.partnerDestination" :items="getPartnersForFields()" label="Destinatar *"
                  item-text="name" item-value="_id" return-object>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title class="noDataButton">
                        Nu ai niciun partener adaugat pentru acest punct de lucru.
                        <AddButton :onClick="() => { openPartnerDialog('partnerDestination') }"
                          caption="Adauga partener" />
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:append-item v-if="getPartnersForFields().length">
                    <AddButton :onClick="() => { openPartnerDialog('partnerDestination') }"
                      caption="Adauga partener nou" />
                  </template>
                </v-select>
              <!-- </div> -->
              </v-col>
            </v-row>
            <v-row dense v-if="!simplifiedAvailable || (simplifiedAvailable && !showSimplified)">
              <v-col cols="12" md="3">
                <!-- <div class="padded w-third m-2"> -->
                <v-select @change="resetDriverAndCar()" v-model="model.partnerTransportator"
                  :items="getPartnersForFields(true)" label="Transportator" item-text="name" item-value="_id"
                  return-object>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title class="noDataButton">
                        Nu ai niciun partener adaugat pentru acest punct de lucru.
                        <AddButton :onClick="() => { openPartnerDialog('partnerTransportator') }"
                          caption="Adauga partener transportator" />
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:append-item v-if="partners.length">
                    <AddButton :onClick="() => { openPartnerDialog('partnerTransportator') }"
                      caption="Adauga partener transportator nou" />
                  </template>
                </v-select>
              <!-- </div> -->
              </v-col>
              <v-col cols="12" md="3">
                <!-- <div class="padded w-third m-2"> -->
                <v-select :disabled="!model.partnerTransportator" v-model="model.partnerCar" label="Mijloc de transport"
                  :items="partnerById(model.partnerTransportator) ? partnerById(model.partnerTransportator).partnerCars : []"
                  item-text="plate" item-value="_id" return-object>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title class="noDataButton">
                        Nu ai nicio masina adaugata pentru acest partener.
                        <AddButton :onClick="() => { openPartnerDialogForUpdate(partnerById(model.partnerTransportator)) }" caption="Adauga masina" />
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:append-item
                    v-if="partnerById(model.partnerTransportator) && partnerById(model.partnerTransportator).partnerCars.length">
                    <AddButton :onClick="() => { openPartnerDialogForUpdate(partnerById(model.partnerTransportator)) }" caption="Adauga masina noua" />
                  </template>
                </v-select>
              <!-- </div> -->
              </v-col>
              <v-col cols="12" md="3">
                <!-- <div class="padded w-third m-2"> -->
                <v-select :disabled="!model.partnerTransportator" v-model="model.partnerDriver" label="Sofer"
                  :items="partnerById(model.partnerTransportator) ? partnerById(model.partnerTransportator).partnerDrivers : []"
                  item-text="name" item-value="_id" return-object>
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title class="noDataButton">
                        Nu ai niciun sofer adaugat pentru acest partener.
                        <AddButton :onClick="() => { openPartnerDialogForUpdate(partnerById(model.partnerTransportator)) }" caption="Adauga sofer" />
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:append-item
                    v-if="partnerById(model.partnerTransportator) && partnerById(model.partnerTransportator).partnerDrivers.length">
                    <AddButton :onClick="() => { openPartnerDialogForUpdate(partnerById(model.partnerTransportator)) }" caption="Adauga sofer nou" />
                  </template>
                </v-select>
              <!-- </div> -->
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field type="text" placeholder="Observatii" v-model="model.comments" counter 
                  maxlength="100" label="Observatii" :rules="[v => !v || (v && v.length <= 100) || 'Max 100 caractere']" 
                  clearable
                  />
              </v-col>
            </v-row>
            <v-row v-if="simplifiedAvailable" justify="center" class="pb-2">
              <a v-if="showSimplified" @click="showSimplified = false">Vezi toate setarile</a>
              <a v-else @click="showSimplified = true">Ascunde toate setarile</a>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="cardActions">
          <v-btn type="button" class="mainButtonYes" text @click="save()">Adauga</v-btn>
          <v-btn type="button" class="mainButtonNo" color="red" @click="close()">Renunta</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CreatePartner v-if="partnerDialog" :closeDialog="closePartnerDialog" :workingPoint="workingPoint"
      :userName="userName" :email="email" :wasteCodePWP="wasteCodePWP" :createCb="getPartners"
      :partnerField="partnerField" :partnerToUpdate="partnerToUpdate" :updateCarsAndDrivers="updateCarsAndDrivers"/>
    <!-- <CreateCar v-if="carDialog" :closeDialog="closeCarDialog" :partner="partnerById(model.partnerTransportator)"
      :carTypes="listsByCategory(ListCategories.MIJLOC_TRANSPORT)" :wasteCodePWP="wasteCodePWP" :createCb="getPartners"
      partnerField="partnerCar" />
    <CreateDriver v-if="driverDialog" :closeDialog="closeDriverDialog"
      :partner="partnerById(model.partnerTransportator)" :wasteCodePWP="wasteCodePWP" :createCb="getPartners"
      partnerField="partnerDriver" /> -->
  </v-layout>
</template>
<script>
import moment from "moment-timezone";
import {
  ListCategories
} from '../../../common/ListCategories';
import AddButton from '../../Buttons/AddButtonText.vue';
// import CreateCar from './CreateCar.vue';
// import CreateDriver from './CreateDriver.vue';
import CreatePartner from './CreatePartner.vue';
export default {
  components: {
    CreatePartner,
    // CreateCar,
    // CreateDriver,
    AddButton
  },
  props: [
    "closeDialog",
    "carTypes",
    "wasteCodePWP",
    "partners",
    "workingPoint",
    "updating",
    "createCb"
  ],
  data: () => ({
    dialog: true,
    showSimplified: false,
    simplifiedAvailable: false,
    inputRules: [v => !!v || "Campul este obligatoriu"],
    model: {},
    discardType: null,
    deleteDialog: false,
    createDialog: false,
    partnerDialog: false,
    updateCarsAndDrivers: false,
    // carDialog: false,
    // driverDialog: false,
    currentPartner: null,
    editDialog: false,
    modal: false,
    partnerField: null,
    partnerToUpdate: null,
    evalTypes: [
      {
        _id: 'A', code: 'A', name: 'Cantarita'
      },
      {
        _id: 'B', code: 'B', name: 'Estimata'
      }
    ]
    // fieldToAddPartner: null,
  }),
  watch: {
  },
  methods: {
    partnerById(partner) {
      if (!partner) {
        return
      }
      if (partner._id) {
        partner = partner._id
      }
      return this.partners.find(p => {
        return p._id == partner
      })
    },
    getPartnersForFields(onlyTransportator) {
      let partners = this.partners
      if (onlyTransportator) {
        partners = partners.filter(p => {
          return p.types.map(t => t.code).includes('Transportator')
        })
      } else {
        // sa aiba cel putin un tip care sa nu fie transp
        partners = partners.filter(p => {
          return p.types.map(t => t.code).find(c => c != 'Transportator')
        })
      }
      return partners
    },
    resetDriverAndCar() {
      this.model.partnerCar = null
      this.model.partnerDriver = null
    },
    close() {
      this.closeDialog()
    },
    listsByCategory(category) {
      return this.lists[category]
    },
    save() {
      // return
      if(this.model.comments == null) {
        this.model.comments = '';
      }
      
      if (this.$refs.form.validate()) {
        if (this.discardType == 'ELIMINARE') {
          delete this.model.codValorificare
        } else if (this.discardType == 'VALORIFICARE') {
          delete this.model.codEliminare
        }
        if (this.model._id) {
          this.$store.dispatch('updateWasteRecord', {
            model: this.model,
            cb: () => {
              this.$store.dispatch("fetchWasteRecordsPerWorkingPoint", {
                workingPointId: this.workingPoint._id
              })
              if (this.createCb) {
                this.createCb()
              }
            }
          });
          // this.$store.dispatch('updateIngredient', {data: this.model, workingPointId: this.workingPointId, cb: () => {if(this.callback) {this.callback()}}});
        } else {
          this.$store.dispatch('createWasteRecord', {
            model: this.model,
            cb: () => {
              this.$store.dispatch("fetchWasteRecordsPerWorkingPoint", {
                workingPointId: this.workingPoint._id
              })
              if (this.createCb) {
                this.createCb()
              }
            }
          });
        }
        this.close()
      }
    },
    openPartnerDialog(partnerField) {
      this.partnerDialog = true
      this.partnerField = partnerField
    },
    closePartnerDialog() {
      this.partnerDialog = false
      this.partnerToUpdate = null
      this.partnerField = null
      this.updateCarsAndDrivers = false
    },
    openPartnerDialogForUpdate(partner) {
      this.partnerToUpdate = partner;
      this.partnerField = 'carsAndDrivers'
      this.updateCarsAndDrivers = true
      this.partnerDialog = true
    },
    // openCarDialog() {
    //   this.carDialog = true
    // },
    // closeCarDialog() {
    //   this.carDialog = false
    // },
    // openDriverDialog() {
    //   this.driverDialog = true
    // },
    // closeDriverDialog() {
    //   this.driverDialog = false
    // },
    parseDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getPartners(fieldToAdd) {
      this.$store.dispatch('fetchPartners', {
        cb: (data) => {
          if (fieldToAdd) {
            if(fieldToAdd == 'carsAndDrivers') {
              if(data[0].partnerCars && data[0].partnerCars.length) {
                this.model.partnerCar = data[0].partnerCars[data[0].partnerCars.length - 1]
              }
              if(data[0].partnerDrivers && data[0].partnerDrivers.length) {
                this.model.partnerDriver = data[0].partnerDrivers[data[0].partnerDrivers.length - 1]
              }
            } else {
              this.model[fieldToAdd] = data[0]
              if(fieldToAdd == 'partnerTransportator') {
                this.resetDriverAndCar();
                if(data[0].partnerCars && data[0].partnerCars.length) {
                  this.model.partnerCar = data[0].partnerCars[data[0].partnerCars.length - 1]
                }
                if(data[0].partnerDrivers && data[0].partnerDrivers.length) {
                  this.model.partnerDriver = data[0].partnerDrivers[data[0].partnerDrivers.length - 1]
                }
              }
              
            }
          }
        }
      });
      // if (fieldToAdd == 'partnerCar') {
      //   this.model.partnerCar = createData
      // } else if (fieldToAdd == 'partnerDriver') {
      //   this.model.partnerDriver = createData
      // }
    },
  },
  computed: {
    ListCategories() {
      return ListCategories
    },
    lists() {
      return this.$store.state.wasteCodes.lists
    },
  },
  created() {
    this.model = {
      ...this.wasteCodePWP
    }
    if (!this.updating) {
      delete this.model._id
      this.model.registerDate = moment().format("YYYY-MM-DD")
      this.model.wasteCodePerWorkingPoint = this.wasteCodePWP
      this.model.workingPoint = this.workingPoint
    } else {
      this.model.registerDate = this.parseDate(this.model.registerDate)
    }
    if(!this.model.hasOwnProperty('comments')) {
      this.model.comments = '';
    }
    if (this.wasteCodePWP.codValorificare && !this.wasteCodePWP.codEliminare) {
      this.discardType = 'VALORIFICARE'
    }
    if (!this.wasteCodePWP.codValorificare && this.wasteCodePWP.codEliminare) {
      this.discardType = 'ELIMINARE'
    }
    // check if simplified
    if((this.model.codEliminare || this.model.codValorificare)
      && this.model.unitMeasure && this.model.stareFizica &&
      this.model.stockingType && this.model.destination && 
      this.model.carType && this.model.partnerDestination &&
      this.model.partnerTransportator
    ) {
      this.showSimplified = true;
      this.simplifiedAvailable = true;
    }
  }
};
</script>
<style scoped>
@media only screen and (max-width: 600px) {
  .w-half,
  .w-third,
  .w-quarter,
  .w-75 {
    width: 100% !important
  }
  .noDataButton {
    display: grid;
    font-size: 12px;
  }
}
.w-third {
  width: 31%
}
.w-quarter {
  width: 23%;
}
.w-half {
  width: 50%;
}
.w-75 {
  width: 73%;
}
</style>
