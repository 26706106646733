<template>
<auth-layout>
  <WasteRecordsLayout />
</auth-layout>
</template>
<script>
import AuthLayout from '../Layouts/AuthLayout.vue';
import WasteRecordsLayout from '../Layouts/WasteRecordsLayout.vue'
export default {
  data() {
    return {};
  },
  components: {
    AuthLayout,
    WasteRecordsLayout
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
  created() {
  }
};
</script>
