<template>
  <guest-layout>
    <div class="w-half h-full flex items-center justify-center bg-gray-700">
      <card class="w-6/12 h-6/12" center="true">
        <span class="mb-4">
          <img
            class=""
            style="max-width: 150px"
            src="../../assets/rd-Artboard 2xxxhdpi.png"
            alt="Registrul Deseurilor"
          />
          <!-- <main-headline label="Login" class="mb-2"> </main-headline> -->
        </span>
        <span class="mb-6"
          >Nu ai inca un cont?
          <a href="https://registrul-deseurilor.ro/#buy" :color="'primary'">
            <span class="text-blue-700 underline" :color="'primary'"
              >Inregistreaza-te</span
            >
          </a></span
        >
        <v-divider style="border-block: white" />
        <!-- Register via email + password -->
        <form class="w-full" @submit.prevent="localLogin">
          <!-- Email -->
          <v-text-field
            class="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="text"
            :placeholder="formData.email.placeholder"
            v-model="formData.email.value"
            @change="validateEmail"
          />
          <div v-if="formData.email.error" class="text-sm -mt-4 text-red-400">
            {{ formData.email.error }}
          </div>
          <!-- Password -->
          <v-text-field
            class="shadow appearance-none border rounded w-full py-2 px-3 mb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="pass"
            type="password"
            :placeholder="formData.password.placeholder"
            v-model="formData.password.value"
            @change="validatePassword"
          />
          <div v-if="formData.password.error" class="text-sm -mt-4 text-red-400">
            {{ formData.password.error }}
          </div>
          <!-- Submit button -->
          <primary-button class="w-full" type="submit" :color="'primary'">
            <div class="w-full flex items-center justify-center">
              <span> Login </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </div>
          </primary-button>
          <!-- Show error message if login fails -->
          <div
            v-if="$store.state.auth.error && $store.state.auth.error.area === 'login'"
            class="errorMessage"
          >
            {{ $store.state.auth.error.message }}
          </div>
        </form>
        <!-- Forgot password link -->
        <span class="w-full flex justify-between mt-3">
          <router-link to="/forgotpassword">
            <span class="text-blue-700 underline" accent color="accent"
              >Ai uitat parola?</span
            >
          </router-link>
        </span>
      </card>
    </div>
  </guest-layout>
</template>
<script>
import { mapActions } from "vuex";
import GuestLayout from "../../Layouts/GuestLayout.vue";
import MainHeadline from "../../components/Typography/MainHeadline.vue";
import Card from "../../components/Widgets/Card.vue";
import PrimaryButton from "./../../components/Buttons/PrimaryButton.vue";
import InputField from "./../../components/Form/Input.vue";
import Divider from "./../../components/Widgets/Divider.vue";
export default {
  //===========================
  // DATA
  //===========================
  data() {
    return {
      formData: {
        email: {
          type: "text",
          name: "email",
          label: "Your email",
          placeholder: "Email",
          value: "",
          error: null,
          isRequired: true,
        },
        password: {
          type: "password",
          name: "password",
          label: "Your password (min. 10 chars)",
          placeholder: "Password",
          value: "",
          error: null,
          isRequired: true,
        },
      },
    };
  },
  //===========================
  // COMPONENTS
  //===========================
  components: {
    GuestLayout,
    Card,
    MainHeadline,
    InputField,
    PrimaryButton,
    Divider,
  },
  //===========================
  // METHODS
  //===========================
  methods: {
    /**
     * Log in via email and password.
     */
    ...mapActions("auth", ["login"]),
    async localLogin() {
      this._validateInputs();
      if (this.formData.email.error || this.formData.password.error) {
        return;
      }
      // Trigger action from auth store.
      this.$store.dispatch("login", {
        email: this.formData.email.value,
        password: this.formData.password.value,
      });
      // this.login({
      //   email: this.formData.email.value,
      //   password: this.formData.password.value,
      // })
    },
    /**
     * Validate the form input fields.
     */
    _validateInputs() {
      this.validateEmail();
      this.validatePassword();
    },
    /**
     * Validate the email field
     */
    validateEmail() {
      const isEmail = String(this.formData.email.value)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      if (!isEmail) {
        return (this.formData.email.error = "Please enter a valid email address.");
      }
      return (this.formData.email.error = null);
    },
    /**
     * Validate the password field
     */
    validatePassword() {
      if (this.formData.password.value.length < 10) {
        return (this.formData.password.error =
          "Please provide a password with at least 10 characters.");
      }
      this.formData.password.error = null;
    },
  },
};
</script>
<style>
.w-half {
  max-width: 47% !important;
  margin: 50px auto;
}
@media only screen and (max-width: 600px) {
  .w-half {
    max-width: 100% !important;
    margin: 50px auto;
  }
}
</style>
