<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent transition="dialog-top-transition" max-width="600">
      <v-card v-if="anpmEmail || loadingEmail">
        <v-toolbar color="accent" dark style="height: 20px !important" />
        <v-card-title v-if="anpmEmail">
          <span class="dialogText">Esti sigur ca vrei sa trimiti pe email raportarea evidentei deseurilor catre
            <span class="bold"> APM {{ anpmEmail.county }} ({{ anpmEmail.email }})</span>
            ?</span>
        </v-card-title>
        <v-card-title v-else>
          <v-progress-circular indeterminate color="primary" v-if="loadingEmail"></v-progress-circular>
        </v-card-title>
        <v-card-actions class="justify-end">
          <v-btn class="mainButtonNo" @click="close()">Nu</v-btn>
          <v-btn class="mainButtonYes" @click="confirm()">Da</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title>
          <span class="dialogText"> Judetul punctului de lucru nu este completat corect</span>
        </v-card-title>
        <v-card-actions class="justify-end">
          <v-btn class="mainButtonNo" @click="close()">Inchide</v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog v-model="confirmDialog" persistent transition="dialog-top-transition" max-width="600">
        <v-card>
          <v-toolbar color="accent" dark style="height: 20px !important" />
          <v-card-title>
            <span class="dialogText">Esti sigur ca ai completat toate datele referitoare la deseurile generate pentru
              anul
              <span class="bold"> {{ year }}</span>
              ?</span>
          </v-card-title>
          <v-card-actions class="justify-start">
            <v-btn @click="exportReport()" class="text-gray-500 primary">
              <span>Descarca si verifica raportul</span>
            </v-btn>
            <v-divider style="border-block: white; max-width: 150px;" />
            <v-btn class="mainButtonNo" @click="close()">Nu</v-btn>
            <v-btn class="mainButtonYes" @click="save()">Da</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
  </v-layout>
</template>
<script>
export default {
  props: [
    "county",
    "workingPoint",
    "closeDialog",
    "sendMailAction",
    "year",
    "exportReport"
  ],
  data: () => ({
    dialog: true,
    confirmDialog: false
  }),
  methods: {
    close() {
      this.closeDialog()
      this.confirmDialog = false
    },
    confirm() {
      this.confirmDialog = true
    },
    save() {
      this.sendMailAction(this.item)
      this.close()
    }
  },
  computed: {
    anpmEmail() {
      return this.$store.state.wasteRecords.anpmEmail
    },
    loadingEmail() {
      return this.$store.state.wasteRecords.loadingEmail
    },
  },
  created() {
  }
};
</script>