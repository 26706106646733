<template>
  <div style="margin: 10px" v-if="canSeeUsers">
    <LayoutHeader title="Utilizatori" :dontDistrurbFree="true" />
    <div class="layoutBody">
      <AddButton v-if="canAddUser" :onClick="() => { openUserDialog() }" caption="Adauga" />
      <v-data-table :items="users" :headers="userHeaders" :search="search" :footer-props="{
      itemsPerPageOptions: [25, 50, 100],
    }">

        <template v-slot:[`item.isActive`]="props">
          <p class="tableData">
          <div class="flex">
            <v-checkbox class="" style="align-items: center;" :input-value="props.item.isActive"
              :disabled="props.item.isDefault || userDisabled"
              @click="updateWorkingPointActive(props.item)"></v-checkbox>
          </div>
          </p>
        </template>
        <template v-slot:[`item.isDefault`]="props">
          <p class="tableData">
          <div class="flex">
            <v-checkbox class="" style="align-items: center;" :input-value="props.item.isDefault"
              :disabled="props.item.isDefault || userDisabled" @click="makeDefault(props.item)"></v-checkbox>
          </div>
          </p>
        </template>
        <template v-slot:[`item.createdAt`]="props">
          {{ parseDate(props.item.createdAt) }}
        </template>

        <template v-slot:[`item.status`]="props">
          {{ props.item.user ? 'Invitatie trimisa' : (props.item.isActive ? 'Activ' : 'Inactiv') }}
        </template>

        <template v-slot:[`item.role`]="props">
          <v-chip label size="small" class="font-weight-bold"
            :color="props.item.role == 'OWNER' ? 'primary' : 'secondary'">
            {{ props.item.roleName }}
          </v-chip>
        </template>

        <template v-slot:[`item.workingPoints`]="props">
          <div>{{ getWorkingPoints(props.item) }}</div>
        </template>

        <template v-slot:[`item.actions`]="props">

          <p class="tableData">
          <div class="flex" v-if="!props.item.isSelf">
            <div class="flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="openUpdateDialog(props.item)" icon color="primary" v-bind="attrs" v-on="on"
                    :disabled="userDisabled">
                    <v-icon>mdi-pen</v-icon>
                  </v-btn>
                </template>
                <span>Editeaza</span>
              </v-tooltip>
            </div>
            <!-- <div class="flex">
              <v-tooltip top v-if="props.item.createdBy && props.item.isActive">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="openDeleteDialog(props.item)" icon color="secondary" v-bind="attrs" v-on="on"
                    :disabled="userDisabled">
                    <v-icon>mdi-account-remove</v-icon>
                  </v-btn>
                </template>
                <span>Dezactiveaza</span>
              </v-tooltip>
              <v-tooltip top v-else-if="props.item.createdBy && !props.item.isActive">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="openDeleteDialog(props.item)" icon color="secondary" v-bind="attrs" v-on="on"
                    :disabled="userDisabled">
                    <v-icon>mdi-account-plus</v-icon>
                  </v-btn>
                </template>
                <span>Activeaza</span>
              </v-tooltip>
            </div>
            <div class="flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="openDeleteDialog(props.item)" icon color="red" v-bind="attrs" v-on="on"
                    :disabled="userDisabled">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                <span>Sterge</span>
              </v-tooltip>
            </div> -->
          </div>
          </p>
        </template>
      </v-data-table>
    </div>
    <DeleteDialog v-if="deleteDialog" :closeDialog="closeDeleteDialog" :deleteAction="deleteUser"
      itemName="utilizatorul" :item="currentUser" />
    <CreateUser v-if="createDialog" :closeDialog="closeCreateDialog" :workingPoints="workingPoints" :createCb="getData" />
    <UpdateUser v-if="updateDialog" :closeDialog="closeUpdateDialog" :workingPoints="workingPoints" :createCb="getData" :userId="currentUser._id" />
  </div>
</template>
<script>
import moment from "moment-timezone";
import AddButton from '../components/Buttons/AddButton.vue';
import CreateUser from '../components/Modals/Create/CreateUser.vue';
import UpdateUser from '../components/Modals/Create/UpdateUser.vue';

import DeleteDialog from '../components/Modals/DeleteDialog.vue';
import SectionHeadline from '../components/Typography/SectionHeadline.vue';
import LayoutHeader from '../components/Widgets/LayoutHeader.vue';
export default {
  components: {
    LayoutHeader,
    SectionHeadline,
    AddButton,
    CreateUser,
    DeleteDialog,
    UpdateUser
  },
  data() {
    return {
      search: '',
      createDialog: false,
      wasteCodesDialog: false,
      currentUser: null,
      updateDialog: false,
      deleteDialog: false,
      userHeaders: [
        {
          text: "Actiuni",
          value: 'actions'
        },
        {
          text: "Email",
          value: 'email'
        },
        {
          text: "Nume",
          value: 'name'
        },

        {
          text: "Rol",
          value: 'role'
        },

        {
          text: "Status",
          value: 'status'
        },
        {
          text: "Puncte de lucru",
          value: 'workingPoints'
        },

      ]
    };
  },
  watch: {
  },
  computed: {
    canSeeUsers() {
      return this.$store.state.auth.canSeeUsers
    },
    workingPoints() {
      return this.$store.state.workingPoints.workingPoints
    },
    users() {
      return this.$store.state.users.users
    },
    userDisabled() {
      return this.$store.state.auth.userDisabled
    },

    canAddUser() {
      return !this.userDisabled
    },
  },
  methods: {
    getWorkingPoints(user) {
      const workingPoints = user.permissions.map(p => p.workingPoints).reduce((acc, workingPoints) => acc.concat(workingPoints), []);
      return workingPoints.map(wp => `${wp.company ? wp.company.companyName : ''} \\ ${wp.name}`)
        .filter((v, i, a) => a.indexOf(v) == i).join(', ')
    },
    openUpdateDialog(user) {
      // this.$router.push(`/users-edit/${user._id}`);
      this.currentUser = {...user}
      this.updateDialog = true
    },
    closeUpdateDialog() {
      this.updateDialog = false
      this.currentUser = null
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    updateWorkingPointActive(workingPoint) {
      this.$store.dispatch("updateWorkingPointActive", {
        workingPointId: workingPoint._id,
      })
      workingPoint.isActive = !workingPoint.isActive
    },
    deleteUser(user) {
      this.$store.dispatch('deleteUser', {id: user._id, cb: () => {
        this.getData()
      }
    })
      
    },
    openDeleteDialog(user) {
      this.currentUser = {...user}
      this.deleteDialog = true
    },
    closeDeleteDialog() {
      this.currentUser = null
      this.deleteDialog = false
    },

    openUserDialog() {
      this.createDialog = true
    },
    closeCreateDialog() {
      this.createDialog = false
    },
    getData() {
      this.$store.dispatch('fetchUsersForUser', {});
    }

  },
  created() {
    this.$store.dispatch('fetchWorkingPoints');
    this.getData()
  }
};
</script>
