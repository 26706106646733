import Vue from "vue";
import Vuex from "vuex";
import AdminStore from './modules/admin';
import AuthStore from './modules/auth';
import CompaniesStore from './modules/companies';
import DashboardStore from './modules/dashboard';
import PartnersStore from './modules/partners';
import UsersStore from './modules/users';
import WasteCodesStore from './modules/wasteCodes';
import WasteCodesPerWorkingPointStore from "./modules/wasteCodesPerWorkingPoint";
import WasteRecordsStore from './modules/wasteRecords';
import WorkingPointsStore from './modules/workingPoints';
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    auth: AuthStore,
    workingPoints: WorkingPointsStore,
    partners: PartnersStore,
    wasteCodes: WasteCodesStore,
    wasteCodesPerWorkingPoint: WasteCodesPerWorkingPointStore,
    wasteRecords: WasteRecordsStore,
    admin: AdminStore,
    companies: CompaniesStore,
    dashboard: DashboardStore,
    users: UsersStore
  }
})
