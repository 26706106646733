<template>
<auth-layout :headline="'Profil ' + model.name">
  <v-form class="w-full padded p-5" ref="form1">
    <h3 class="font-semibold text-xl text-gray-800 leading-tight">
      Date generale
    </h3>
    <div v-if="isRecorder">
      <v-row>
        <v-col cols="4">
          <v-subheader>Email</v-subheader>
        </v-col>
        <v-col cols="8">
          <v-text-field type="text" placeholder="Email *" v-model="model.email" required disabled />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-subheader>Nume</v-subheader>
        </v-col>
        <v-col cols="8">
          <v-text-field type="text" placeholder="Nume *" v-model="model.name" required disabled />
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="4">
          <v-subheader>Email</v-subheader>
        </v-col>
        <v-col cols="8">
          <v-text-field type="text" placeholder="Email *" v-model="model.email" required disabled :rules="[v => !!v || `Completeaza email-ul`]" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-subheader>Nume</v-subheader>
        </v-col>
        <v-col cols="8">
          <v-text-field type="text" placeholder="Nume client *" v-model="model.name" required :rules="[v => !!v || `Completeaza numele`]" />
        </v-col>
      </v-row>
    </div>
    <v-btn v-if="!isRecorder" type="button" class="mainButtonYes" text @click="save()">Salveaza</v-btn>
  </v-form>
  <v-form class="w-full padded p-5 mt-5" ref="form2">
    <h3 class="font-semibold text-xl text-gray-800 leading-tight">
      Schimba parola
    </h3>
    <div>
      <v-row>
        <v-col cols="4">
          <v-subheader>Parola actuala</v-subheader>
        </v-col>
        <v-col cols="8">
          <v-text-field placeholder="Parola actuala *" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" v-model="changePasswordModel.oldPassword" required :rules="[v => !!v || `Completeaza parola`]" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-subheader>Parola noua</v-subheader>
        </v-col>
        <v-col cols="8">
          <v-text-field placeholder="Parola noua *" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :type="show2 ? 'text' : 'password'" @click:append="show2 = !show2" v-model="changePasswordModel.newPassword" required @change="validatePassword" :rules="[v => !!v || `Completeaza parola`]" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-subheader>Repeta parola noua</v-subheader>
        </v-col>
        <v-col cols="8">
          <v-text-field placeholder="Repeta parola noua *" :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'" :type="show3 ? 'text' : 'password'" @click:append="show3 = !show3" v-model="changePasswordModel.newPassword2" required @change="validatePassword" :rules="[v => !!v || `Completeaza parola`]" />
          <v-subheader style="color: red; height: 20px" v-if="passwordError && passwordError.length">{{
              passwordError
            }}</v-subheader>
        </v-col>
      </v-row>
    </div>
    <v-btn type="button" class="mainButtonYes" text @click="changePassword()">Schimba parola</v-btn>
  </v-form>
</auth-layout>
</template>
<script>
import PrimaryButton from '../components/Buttons/PrimaryButton.vue';
import AuthLayout from '../Layouts/AuthLayout.vue';
export default {
  data() {
    return {
      headline: 'Dashboard',
      model: {},
      changePasswordModel: {},
      passwordError: '',
      show1: false,
      show2: false,
      show3: false,
    };
  },
  watch: {
    user() {
      this.model = this.user
      this.changePasswordModel = {
        _id: this.user._id,
        oldPassword: '',
        newPassword: '',
        newPassword2: ''
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    isRecorder() {
      return this.$store.state.auth.user && this.$store.state.auth.user.role === 'DATA_RECORDER'
    },
  },
  components: {
    AuthLayout,
    PrimaryButton
  },
  methods: {
    save() {
      if (!this.$refs.form1.validate()) {
        return
      }
      this.$store.dispatch('updateUser', {
        model: this.model,
        cb: () => {}
      });
    },
    validatePassword() {
      if (this.changePasswordModel.newPassword != this.changePasswordModel.newPassword2) {
        return (this.passwordError =
          'Parolele nu coincid');
      }
      if (!this.changePasswordModel.newPassword || this.changePasswordModel.newPassword.length < 10) {
        return (this.passwordError =
          'Parola trebuie sa contina cel putin 10 caractere');
      }
      this.passwordError = ''
    },
    changePassword() {
      this.validatePassword()
      if (this.passwordError && this.passwordError.length) {
        return
      }
      if (!this.$refs.form2.validate()) {
        return
      }
      this.$store.dispatch('changePassword', this.changePasswordModel);
      this.show1 = false
      this.show2 = false
      this.show3 = false
      this.changePasswordModel = {
        _id: this.user._id,
        oldPassword: null,
        newPassword: null,
        newPassword2: null
      }
    }
  },
  created() {}
};
</script>
