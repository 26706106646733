<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1500px" max-height="1000px">
      <v-card v-if="startGuideIntro">
        <v-row class="mt-2 mr-2">
          <v-spacer />
          <v-btn @click="closeDialog()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-card-title style="display: grid; justify-content: center;" class="mt-3">
          <span style="justify-content: center;display: flex; font-weight: bold;" class="headline mt-3">BINE AI
            VENIT!</span>
          <span style="justify-content: center;display: flex; font-weight: bold;" class="headline mt-3">E MOMENTUL SA ITI
            SETAM CONTUL</span>
          <span style="justify-content: center;display: flex;" class="headline mt-3">Pentru ca apoi sa fie totul foarte
            simplu</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-timeline align-top dense class="padded  w-75  m-2" style="margin-top: 30px;" color="accent">
              <v-timeline-item :hide-dot="step != 1" fill-dot color="#f48fb1">
                <div style="display: flex; align-items: center;">
                  <div style="margin-right: 10px"> <v-img :width="40" :src="require('@/assets/workingPoint.svg')"
                      class="step-icon" /></div>
                  <div class="headline">Cream punctul de lucru</div>
                </div>
              </v-timeline-item>
              <v-timeline-item :hide-dot="step != 2" fill-dot color="#89c0ef">
                <div style="display: flex; align-items: center;">
                  <div style="margin-right: 10px"><v-img :width="40" :src="require('@/assets/partners.svg')"
                      class="step-icon" /></div>
                  <div class="headline">Setam partenerii catre care predai deseurile</div>
                </div>
              </v-timeline-item>
              <v-timeline-item :hide-dot="step != 3" fill-dot color="#9fa8da">
                <div style="display: flex; align-items: center;">
                  <div style="margin-right: 10px"><v-img :width="40" :src="require('@/assets/wasteCodes.svg')"
                      class="step-icon" /></div>
                  <div class="headline">Setam tipurile de deseuri pe care le generezi</div>
                </div>
              </v-timeline-item>
            </v-timeline>
            <div class="padded  w-quarter  m-2" style="margin-top: -30px;">
              <v-img :width="150" :src="require('@/assets/mascot.png')" class="mascot-image" />
            </div>
          </v-row>
        </v-card-text>
        <v-card-actions class="cardActions">
          <v-spacer style="max-width: 62%;" />
          <v-btn type="button" class="mainButtonYes" style="width: 200px" text @click="start()">Sa incepem</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="startGuideOutro">
        <v-row class="mt-2 mr-2">
          <v-spacer />
          <v-btn @click="closeDialog()" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-card-title style="display: grid;" class="mt-3 justify-center">
          <span class="headline mt-3 font-weight-bold flex justify-center">FELICITARI!</span>
          <span class="headline mt-3 flex justify-center">Acum poti adauga evidenta deseurilor generate de tine</span>
        </v-card-title>
        <v-card-text>
          <v-row class="justify-center mt-10">
            <div class="padded  m-2" >
              <v-img :width="150" :src="require('@/assets/mascot.png')" class="mascot-image" />
            </div>
          </v-row>
        </v-card-text>
        <v-card-actions class="cardActions">
          <v-spacer style="max-width: 62%;" />
          <router-link :to="`/wasteRecords`">
            <v-btn type="button" class="mainButtonYes" style="width: 300px" text @click="finish()">Mergi la evidenta
            deseurilor</v-btn>
          </router-link>
        </v-card-actions>
      </v-card>
      <StartGuideWorkingPoint v-if="step == 1 && !startGuideIntro" :closeDialog="closeDialog" :createCb="refreshData" />
      <StartGuidePartner v-if="(step == 2 || startGuidePartner) && !startGuideIntro" :closeDialog="closeDialog"
        :createCb="refreshData" />
      <StartGuideWasteCodes v-if="(step == 3 || startGuideWasteCodes) && !startGuideIntro" :closeDialog="closeDialog"
        :workingPoint="workingPoints[0]" />
    </v-dialog>
  </v-layout>
</template>
<script>
import moment from "moment-timezone";
import {
ListCategories
} from '../../../common/ListCategories';
import StartGuidePartner from "./StartGuidePartner.vue";
import StartGuideWasteCodes from "./StartGuideWasteCodes.vue";
import StartGuideWorkingPoint from "./StartGuideWorkingPoint.vue";
export default {
  components: {
    StartGuideWorkingPoint,
    StartGuidePartner,
    StartGuideWasteCodes
  },
  props: [
    "closeDialog",
    "wasteCodePWP",
    "forField",
    "createCb",
    "partnerToUpdate",
    "partnerField",
    "step",
    "refreshData"
  ],
  data: () => ({
    // step: 0,
    dialog: true,
    // intro: true,
    model: {},
    modal1: false,
    modal2: false,
    modal3: false,
    modal4: false
  }),
  watch: {
    // workingPoint() {
    //   this.model.workingPoint = this.workingPoint
    // },
    // wasteCodePWP() {
    //   this.model.wasteCodePerWorkingPoint = this.wasteCodePWP
    // }
  },
  methods: {
    stepCompleted() {
      return 'black'
    },
    finish() {
      this.$store.dispatch("exitOutro")
    },
    start() {
      this.$store.dispatch("exitIntro")
    },
    close() {
      this.closeDialog()
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.model._id) {
          this.$store.dispatch("updatePartner", {
            id: this.model._id,
            model: this.model,
            cb: () => { }
          })
        } else {
          this.$store.dispatch('createPartner', {
            model: this.model,
            addToWC: this.forField,
            cb: () => {
              this.createCb(this.partnerField)
            }
          });
        }
        this.close()
      }
    },
    parseDate(date) {
      if (!date) {
        return
      }
      return moment(date).format("YYYY-MM-DD");
    },
  },
  computed: {
    startGuideIntro() {
      return this.$store.state.dashboard.startGuideIntro
    },
    startGuideOutro() {
      return this.$store.state.dashboard.startGuideOutro
    },
    startGuideWasteCodes() {
      return this.$store.state.dashboard.startGuideWasteCodes
    },
    startGuidePartner() {
      return this.$store.state.dashboard.startGuidePartner
    },
    workingPoints() {
      return this.$store.state.workingPoints.workingPoints
    },
    ListCategories() {
      return ListCategories
    },
    lists() {
      return this.$store.state.wasteCodes.lists
    },
    partnerTypes() {
      return this.lists[ListCategories.TIP_PARTENER]
    },
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
    userFree() {
      return this.$store.state.auth.userFree
    },
  },
  created() {
  }
};
</script>
<style scoped>
::v-deep .v-timeline-item__dot {
  width: 15px;
  height: 15px
}
/* .partner-blue-dot .v-timeline-item__dot {
    background-color: var(--partnerBlue) !important;
} */
::v-deep .v-timeline-item__divider {
  align-content: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 600px) {
  .w-half,
  .w-third,
  .w-quarter,
  .w-75 {
    width: 100% !important
  }
  .noDataButton {
    display: grid;
    font-size: 12px;
  }
}
.w-third {
  width: 31%
}
.w-quarter {
  width: 23%;
}
.w-half {
  width: 50%;
}
.w-75 {
  width: 73%;
}
.w-66 {
  width: 65%;
}
.mascot-image {
  width: 100%;
  height: auto;
}
@media (min-width: 600px) {
  .mascot-image {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .mascot-image {
    width: 25%;
  }
}
.step-icon {
  width: 100%;
  height: auto;
}
@media (min-width: 600px) {
  .step-icon {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .step-icon {
    width: 25%;
  }
  .v-sheet.v-stepper:not(.v-sheet--outlined) {
    box-shadow: none;
  }
}
</style>
