import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import AcceptInvite from '../views/Auth/AcceptInvite.vue';
import ForgotPassword from '../views/Auth/ForgotPassword.vue';
import Login from '../views/Auth/Login.vue';
import Register from '../views/Auth/Register.vue';
import ResetPassword from '../views/Auth/ResetPassword.vue';
import Dashboard from '../views/Dashboard.vue';
import DashboardCompanies from '../views/DashboardCompanies.vue';
import Lists from '../views/DashboardLists.vue';
import DashboardOrganization from '../views/DashboardOrganization.vue';
import DashboardPartners from '../views/DashboardPartners.vue';
import DashboardReports from '../views/DashboardReports.vue';
import DashboarsUsers from '../views/DashboardUsers.vue';

import WasteCodes from '../views/DashboardWasteCodes.vue';
import DashboardWasteRecords from '../views/DashboardWasteRecords.vue';
import DashboardWorkingPoints from '../views/DashboardWorkingPoints.vue';
import Profile from '../views/Profile.vue';
import DashboardWasteCodesPerWorkingPoint from "../views/DashboardWasteCodesPerWorkingPoint.vue";
Vue.use(VueRouter)
const routes = [
  {
    path: '/organization',
    name: 'DashboardOrganization',
    component: DashboardOrganization,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/companies',
    name: 'DashboardCompanies',
    component: DashboardCompanies,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/users',
    name: 'DashboarsUsers',
    component: DashboarsUsers,
    meta: {
      requiresAuth: true,
    }
  },

  {
    path: '/workingPoints',
    name: 'DashboardWorkingPoints',
    component: DashboardWorkingPoints,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/wasteRecords',
    name: 'DashboardWasteRecords',
    component: DashboardWasteRecords,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/partners',
    name: 'DashboardPartners',
    component: DashboardPartners,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/wasteCodesPerWorkingPoint',
    name: 'DashboardWasteCodesPerWorkingPoint',
    component: DashboardWasteCodesPerWorkingPoint,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/reports',
    name: 'DashboardReports',
    component: DashboardReports,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/acceptInvite',
    name: 'AcceptInvite',
    component: AcceptInvite,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/wasteCodes',
    name: 'wasteCodes',
    component: WasteCodes,
  },
  {
    path: '/lists',
    name: 'lists',
    component: Lists,
  },
  {
    path: '/:userId',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/*',
    component: Dashboard
  }
];
const router = new VueRouter({
  routes: routes,
  base: process.env.BASE_URL,
  mode: 'history'
})
router.beforeEach((to, from, next) => {
  const requiresAdmin = to.matched.some((x) => x.meta.requiresAdmin);
  if (requiresAdmin) {
    if (store.state.auth.user && store.state.auth.user.role == "ADMIN") {
      next()
    } else {
      next({ name: 'Login' })
    }
  } else {
    next()
  }
})
export default router;
