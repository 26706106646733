<template>
  <div style="margin: 10px; min-height: 100vh;">
    <LayoutHeader title="Setari deseuri" :dontDistrurbFree="true"/>
    <div class="ml-0 ml-md-3" v-if="showContent">
      <v-row dense v-if="workingPoints && workingPoints.length">
        <v-col cols="12">
        <v-select v-model="currentWorkingPoint" :items="workingPoints" item-text="displayName"
        label="Alege punctul de lucru" item-value="_id" return-object @input="changeContext()" />
      </v-col>
      </v-row>
      <v-row dense v-else>
        <p>
          Nu exista niciun punct de lucru creat. Mergi la <span class="font-weight-bold">Organizatie -> Puncte de lucru</span> si adauga unul.
        </p>
      </v-row>
      <WasteCodesPerWorkingPointTable v-if="workingPoints && workingPoints.length" :workingPoint="currentWorkingPoint" 
        :hasAddButton="canAddCode && canEdit ? true : false" :showEditMode="!userDisabled && canEdit ? true : false"
        :showFilters="true" />
    </div>
  </div>
</template>
<script>
import LayoutHeader from '../components/Widgets/LayoutHeader.vue';
import WasteCodesPerWorkingPointTable from '../components/Form/WasteCodesPerWorkingPointTable.vue';
export default {
  data() {
    return {
      currentWorkingPoint: null,
      // activeWasteCodesPerWorkingPoint: [],
      showContent: false
    };
  },
  components: {
    LayoutHeader,
    WasteCodesPerWorkingPointTable
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    userDisabled() {
      return this.$store.state.auth.userDisabled
    },
    userFree() {
      return this.$store.state.auth.userFree
    },
    userAdvanced() {
      return this.$store.state.auth.userAdvanced
    },
    canAddCode() {
      if (this.userAdvanced) {
        return true
      }
      if (this.userFree) {
        // return !this.wasteCodesPerWorkingPoint.length
        //TRIAL HAS ALL FEATURES ENABLED
        return true
      }
      return false
    },
    canEdit() {
      if(!this.user.permissions || !this.user.permissions.length) {
        return true
      }
      return this.user.permissions.some(permission => {
        return permission.workingPoints.includes(this.workingPoint._id) &&
          permission.role == 'MANAGER'
      })
    },
    wasteCodesPerWorkingPoint() {
      return this.$store.state.wasteCodesPerWorkingPoint.wasteCodesPerWorkingPoint
    },
    firstWorkingPoint() {
      let wp = this.$store.state.workingPoints.firstWorkingPoint
      if (wp) {
        wp.displayName = `${wp.company.companyName} \\ ${wp.name}`
      }
      return wp
    },
    canPerformOrganizationChanges() {
      return this.$store.state.auth.canPerformOrganizationChanges
    },
    workingPoints() {
      
      return this.$store.state.workingPoints.workingPoints.map(wp => {
        wp.displayName = `${wp.company.companyName} \\ ${wp.name}`
        return wp
      }).filter(wp => wp.isActive)
    },
    managerWorkingPoints() {
      if(this.canPerformOrganizationChanges) {
        // nu e gueest
        return this.workingPoints
      }
      if(this.user.permissions) {
        let managerPerm = this.user.permissions.find(p => p.role == 'MANAGER')
        if(managerPerm) {
          return this.workingPoints.filter(wp => managerPerm.workingPoints.includes(wp._id))
        }
      }
      return []
    },
    partners() {
      return this.$store.state.partners.partnersForWorkingPoint
    },
  },
  watch: {
    firstWorkingPoint() {
      if (this.firstWorkingPoint) {
        this.currentWorkingPoint = this.firstWorkingPoint
        this.changeContext()
      }
      this.showContent = true;
    }
  },
  methods: {
    changeContext() {
      
      if (!this.currentWorkingPoint) {
        return
      }
      this.$store.dispatch("fetchWasteCodesPerWorkingPoint", {
        workingPointId: this.currentWorkingPoint._id,
      })
      this.$store.dispatch('fetchPartners', {
        workingPointId: this.currentWorkingPoint._id
      });
    },
    
  },
  created() {
    this.$store.dispatch('fetchWorkingPoints');
  }
};
</script>